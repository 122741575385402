<template>
  <div>
    <b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>
    <div class="columns is-mobile">
      <div class="column">
        <a @click="$router.go(-1)" class="button is-text">
          <b-icon type="is-secondary-color" icon="chevron-left"></b-icon>
          <span>&nbsp; página anterior</span>
        </a>
      </div>
    </div>


    <div class="steps" id="stepsDemo">
      <div class="step-item is-active is-primary-color">
        <div class="step-marker"></div>
        <div class="step-details">
          <p class="step-title">Local de recolha</p>
        </div>
      </div>
      <div v-if="user.type=='user'" class="step-item">
        <div class="step-marker"></div>
        <div class="step-details">
          <p class="step-title">Pagamento</p>
        </div>
      </div>
      <div class="step-item">
        <div class="step-marker"></div>
        <div class="step-details">
          <p class="step-title">Rever encomenda</p>
        </div>
      </div>

      <div class="steps-content">
        <div class="step-content has-text-centered is-active">
          <!-- LOCALIZAÇÃO -->
          <div class="columns is-mobile">
            <div class="column is-8-desktop is-offset-2-desktop is-10-touch is-offset-1-touch">
              <!-- Card -->
              <ChoosePlace @placeselected="changePlace" :showMap="showMap" />
              <br>
              <b-button class="" @click="showMap = !showMap">Mostrar mapa</b-button>
              <!-- Pesquisa -->
              <!-- MAPA -->
            </div>
          </div>

          <!-- FIM LOCALIZAÇÃO -->
        </div>

        <div v-if="user.type == 'user'" class="step-content has-text-centered">
          <Payment @paymentvalues="setPaymentValues" @paymenttype="setPaymentType"/>
        </div>
        <div class="step-content has-text-centered">
          <div class="columns">
            <div class="column is-8-desktop is-offset-2-desktop is-10-touch is-offset-1-touch">
              <b-table :data="isEmpty ? [] : itemsCart" :mobile-cards="true">
                
                  <b-table-column field="id" label width="220" centered v-slot="props">
                    <img :src="props.row.product.img" />
                  </b-table-column>
                  <b-table-column field="first_name" label=" " centered v-slot="props">{{ props.row.product.description }}
                  </b-table-column>

                  <b-table-column field="first_name" label="Género" centered v-slot="props">{{ props.row.product.genre }}
                  </b-table-column>

                  <b-table-column field="last_name" label="Tamanho" centered v-slot="props">{{ props.row.product.size }}
                  </b-table-column>
                  <b-table-column field="date" label="Cor" centered v-slot="props">{{props.row.product.color }}</b-table-column>
                  <b-table-column label="Unidade" centered>
                    <!-- //{{ props.row.gender }} -->
                    1
                  </b-table-column>
                  <b-table-column label="Valor" centered v-slot="props">{{ props.row.points }} pontos</b-table-column>
                  <b-table-column label="   " centered>
                    <!-- <a class="button is-text">Atualizar</a>
                    <br />
                    <a class="button is-text">Cancelar</a> -->
                  </b-table-column>
                  
                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="emoticon-sad" size="is-large"></b-icon>
                      </p>
                      <p>Adicione Artigos</p>
                    </div>
                  </section>
                </template>
              </b-table>
            </div>
            <!-- <div class="column is-1"></div> -->
          </div>
          <div class="columns">
            <div class="column is-8-desktop is-offset-2-desktop is-10-touch is-offset-1-touch">
              <h1 class="has-text-weight-semibold">Local de recolha</h1>
              <div class="columns">
                <div class="column is-10 is-offset-1">
                  <div v-if="place">
                    <b>Nome: </b> {{ place.name }} <br>
                    <b>Morada: </b> {{ place.address }} <br>
                    <b>Código postal: </b> {{ place.zip_code }} <br>
                    <b>Coordenadas: </b> {{ place.coordinates.lat }} | {{ place.coordinates.lng }} <br>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column is-8-desktop is-offset-2-desktop is-10-touch is-offset-1-touch">
              <h1 class="has-text-weight-semibold">Pagamento</h1>
              <div class="columns">
                <div class="column is-10 is-offset-1">
                  <h1 v-if="user.type!='admin' && user.type!='glad' " class="has-text-weight-semibold">Pagamento por
                    pontos</h1>
                  <div v-if="payment_values">
                    <p><b>Pontos na sua conta:</b> {{payment_values.total_points}}</p>
                    <p><b>Valor dos artigos:</b> {{payment_values.value}}</p>
                    <p><b>Pontos totais da sua conta:</b> {{payment_values.new_total_points}}</p>
                    <p style="color:red;" v-if="payment_values && payment_values.missed > 0"><b>Pontos em falta:</b>
                      {{payment_values.missed}}</p>
                  </div>

                  <!-- <h1 v-if="user.type!='admin' && user.type!='glad'"
                    class="has-text-weight-semibold">Efetuar pagamento na instituição</h1> -->
                  <h1 v-if="user.type=='admin' || user.type=='glad' " class="has-text-weight-semibold">Não necessita de
                    efetuar pagamento</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8-desktop is-offset-2-desktop is-10-touch is-offset-1-touch">
              <label class="checkbox">
                <input type="checkbox" v-model="terms" />
                Li e aceito as condições de compra e entendo a informação acerca da utilização dos meus dados pessoais
                explicada na
                <a>Política da Privacidade</a>
              </label>
            </div>
            <button @click.prevent="confirm()" :disabled="isDisabled" class="button is-primary-color">Efetuar compra</button>

          </div>
        </div>
      </div>
      <div class="steps-actions">
        <div class="steps-action">
          <b-button data-nav="previous" class="is-secondary-color">Anterior</b-button>
          <!-- <a data-nav="previous" class="button is-light">Anterior</a> -->
        </div>
        <div class="steps-action">
          <b-button data-nav="next" class="is-primary-color">Seguinte</b-button>
          <!-- <a data-nav="next" class="button is-light">Seguinte</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import StepsWizard from "bulma-extensions/bulma-steps/dist/js/bulma-steps.js";

  //API
  import {
    postOrder
  } from "./../api/order/order";
  import {
    getPlaces
  } from './../api/places/places';
  //API
  import {
    get_user_info
  } from "./../api/user/user";
  //MIXINS
  import {
    map
  } from "../mixins/modules/map";
  import {
    order
  } from "../mixins/modules/order";
  import {
    cart
  } from "../mixins/modules/cart";

  //COMPONENTS
  // import MapView from "@/components/Map/MapView.vue";
  // import Places from "@/components/order/places.vue";
  import Payment from "@/components/order/payment.vue";
  import ChoosePlace from '../components/product/ChoosePlace';

  export default {
    name: "Process",
    components: {
      // MapView,
      // Places,
      Payment,
      ChoosePlace
    },
    mixins: [map, order, cart],
    data() {
      return {
        showMap: false,
        isEmpty: false,
        place: null,
        places: [],
        terms: false,
        itemsCart: [],
        isLoading: false,
        payment_values: null,
        payment_type: null
      };
    },
    created() {
      this.itemsCart = this.getCart(this.getAppSelected._id).products
      this.user = this.getUserInfo;
    },
    computed: {
      isDisabled: function () {
        return !this.terms;
      }
    },
    mounted() {
      let steps = new StepsWizard(document.getElementById("stepsDemo"));
    },
    methods: {
      changePlace(_p) {
        this.place = _p;
      },
      setPaymentValues(_m) {
        this.payment_values = _m;
      },
      setPaymentType(_m) {
        this.payment_type = _m;
      },
      confirm: function () {

        if (this.payment_values && this.payment_values.missed > 0)
          return this.$buefy.toast.open({
            message: "Não tem pontos suficientes para efetuar a encomenda!",
            type: "is-danger",
            position: "is-bottom"
          });

        if (!this.place)
          return this.$buefy.toast.open({
            message: "Não pode efetuar a encomenda sem indicar um local de recolha!",
            type: "is-danger",
            position: "is-bottom"
          });

        if (!this.terms)
          return this.$buefy.toast.open({
            message: "Não pode efetuar a encomenda sem aceitar os termos!",
            type: "is-danger",
            position: "is-bottom"
          });



        this.isLoading = true;
        let self = this;
        let products = [];
        this.itemsCart.forEach(element => {
          products.push({
            product: element._id,
            quantity: 1
          });
        });

        let shopping = {
          products: products,
          payment_type: this.payment_type ? this.payment_type : 'free' /*this.getPaymentType*/ ,
          accept_conditions: this.terms,
          place: this.place._id,
          institution: this.getAppSelected._id != "app" ? this.getAppSelected._id : null
        };

        postOrder(shopping)
          .then(response => {
            self.isLoading = false;

            //SELF
            this.$buefy.toast.open({
              message: "Compra efetuada com sucesso!",
              type: "is-success",
              position: "is-bottom"
            });

            self.resetCart(self.getAppSelected._id);
            self.$router.push({name: 'SharedProducts'});
          })
          .catch(error => {
            console.log(error)
            self.isLoading = false;
            //SELF
            this.$buefy.toast.open({
              message: "Ocorreu um erro!",
              type: "is-danger",
              position: "is-bottom"
            });

            // self.resetCartInstitution();
            // self.resetCartApp();
            // self.$router.push("/app");

          });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '../assets/sass/main';




  @media screen and (min-height: 1026px) {
    .steps-actions {
      /* position: absolute;
      bottom: 200px; */
      width: 100%;
    }

  }

  /* .step-marker {
    border-color: $primary-color !important;
    color: $primary-color !important;
  } */

  #stepsDemo {
    margin-bottom: 30px;
  }
</style>