export const cart = {
    computed: {

        getPaymentType() {
            return this.$store.getters['cart/getPaymentType']
        },
        getAllCarts() {
            
            return this.$store.getters['cart/getItemsCart']
        }
    },
    methods: {
        changePaymentType: function (type) {
            this.$store.commit('cart/changePaymentType', type)
        },
        getCart(app_id) {
            let cart = this.$store.getters['cart/getItemsCart'].find(cart => cart.app_id == app_id);
            if (cart) return cart;

            return {
                app_id,
                products: []
            }
        },
        isInCart(app_id, product_id) {
            let cart = this.$store.getters['cart/getItemsCart'].find(cart => cart.app_id == app_id);
            if (!cart) return false;

            let found = cart.products.filter(e => e._id == product_id)[0];

            if (found) return true;
            return false;
        },
        addToCart: function (app_id, product) {
            this.$store.commit('cart/addToCart', {
                app_id,
                product
            })
        },
        removeFromCart: function (app_id, product) {
            this.$store.commit('cart/removeFromCart', {
                app_id,
                product
            })
        },
        resetCart: function (app_id) {
            this.$store.commit('cart/resetCart', app_id)
        },
        resetAllCarts: function () {
            this.$store.commit('cart/resetAllCarts')
        }

    }
}