import Filters from '../../config/Filters'
import Districts from '../../config/Districts.json';
import Municipalities from '../../config/Municipality.json';

export const filters = {
    computed: {
        getStatusFilters() {
            let filters = []
            for (let i of Filters["productsStatus"])
                filters.push({
                    name: i.text,
                    path: "app?status=" + i.value
                })
            return filters;
        },
        getNavigationFilters() {
            let filters = [];

            for (let g of Filters.genres) {
                let A = {
                    id: JSON.stringify({
                        genre: g.text
                    }),
                    label: g.text,
                    children: []
                }

                if (g.value != "Criança") {
                    for (let t of Filters[g.child]) {

                        let B = {
                            id: JSON.stringify({
                                genre: g.text,
                                type: t.text
                            }),
                            label: t.text,
                            children: []
                        }

                        if (typeof t.child == "object") {
                            for (let q of t.child) {
                                for (let s of Filters[q]) {
                                    B.children.push({
                                        id: JSON.stringify({
                                            genre: g.text,
                                            type: t.text,
                                            size: s.text
                                        }),
                                        label: s.text,
                                    })
                                }

                            }

                            A.children.push(B);

                        } else {
                            for (let s of Filters[t.child]) {
                                B.children.push({
                                    id: JSON.stringify({
                                        genre: g.text,
                                        type: t.text,
                                        size: s.text
                                    }),
                                    label: s.text,
                                })
                            }
                            A.children.push(B);
                        }


                    }
                } else {

                    for (let c of Filters[g.child]) {
                        let C = {
                            id: JSON.stringify({
                                genre: g.text,
                                genreChild: c.text
                            }),
                            label: c.text,
                            children: []
                        }
                        for (let t of Filters[c.child]) {
                            let B = {
                                id: JSON.stringify({
                                    genre: g.text,
                                    genreChild: c.text,
                                    type: t.text
                                }),
                                label: t.text,
                                children: []
                            }
                            if (typeof t.child == "object") {
                                for (let _t of t.child) {
                                    for (let s of Filters[_t]) {
                                        B.children.push({
                                            id: JSON.stringify({
                                                genre: g.text,
                                                genreChild: c.text,
                                                type: t.text,
                                                size: s.text
                                            }),
                                            label: s.text
                                        })
                                    }
                                }

                            } else {
                                for (let s of Filters[t.child]) {
                                    B.children.push({
                                        id: JSON.stringify({
                                            genre: g.text,
                                            genreChild: c.text,
                                            type: t.text,
                                            size: s.text
                                        }),
                                        label: s.text,
                                    })
                                }
                            }


                            C.children.push(B);
                        }
                        A.children.push(C);
                    }

                }

                filters.push(A)

            }
            return filters;

        },
        getLocationFilters() {
            let filters = [];

            for (let d of Districts) {
                let A = {
                    label: d.district_name,
                    id: JSON.stringify({
                        district: d.district_name
                    }),
                    // path: !hasFilter ? "app?district=" + d.district_name : "app&district=" + d.district_name,
                    children: []
                }

                for (let m of Municipalities) {
                    if (m.district_code == d.district_code)
                        A.children.push({
                            label: m.municipality_name,
                            id: JSON.stringify({
                                district: d.district_name,
                                municipality: m.municipality_name
                            }),
                            // path: "&municipality=" + m.municipality_name
                        })
                }

                filters.push(A);

            }

            return filters;

        },
    },
    methods: {
        getFilterByName(name) {
            return Filters[name];
        },
        // getFiltersByArgs(genre, genreChild, type) {

        //     let filters = {
        //         genre: [],
        //         genreChild: [],
        //         type: [],
        //         size: [],
        //     };

        //     filters.genre = this.getFilterByName("genres");
        //     filters.genreChild = this.getFilterByName("genreChild");

        //     if (genre) {
        //         if (genre == "Criança") {
        //             if (genreChild) {
        //                 let _genreChild = filters.genreChild.find(e => e.value == genreChild);
        //                 if (!_genreChild) return filters;
        //                 filters.type = this.getFilterByName(_genreChild.child);
        //                 if(!filters.type.find(e=> e.value == type)) {

        //                 }
        //             }
        //         } else {

        //         }
        //     }

        //     return filters;
        // }

    }
}