<template>
  <div>
    <div class="columns">
      <div class="column">
        <a @click="goToHome()" class="button is-text">
          <b-icon type="is-secondary-color" icon="chevron-left"></b-icon>
          <span>&nbsp; página anterior</span>
        </a>
      </div>
    </div>
    <div style="padding: 0px 30px 0px 30px;">
      <div class="columns">
        <div class="column is-2"></div>
        <div class="column">
          <h1 class="title is-1">Artigos</h1>
        </div>
      </div>
      <!-- header of table -->

      <div class="columns">
        <div class="column is-2"></div>
        <div class="column">
          <b-table :data="isEmpty ? [] : itemsCart" :mobile-cards="true">
            <b-table-column field="id" label width="220" centered v-slot="props">
              <ImgZoom :imgsrc="props.row.product.imgs[0]"/>
            </b-table-column>
            <b-table-column field="first_name" label="Descrição" centered v-slot="props">{{ props.row.product.description }}
            </b-table-column>

            <b-table-column field="first_name" label="Género" centered v-slot="props">{{ props.row.product.genre }}
            </b-table-column>

            <b-table-column field="last_name" label="Tamanho" centered v-slot="props">{{ props.row.product.size }}
            </b-table-column>
            <b-table-column field="date" label="Cor" centered v-slot="props">{{props.row.product.color }}
            </b-table-column>
            <b-table-column label="Quantidade" centered v-slot="props">
              {{props.row.quantity}}
            </b-table-column>
            <b-table-column v-if="getAppSelected._id != 'app'" label="Valor" centered v-slot="props">
              {{ getUserInfo.shareholder? props.row.price.regularized: props.row.price.not_regularized }}
            </b-table-column>
            <b-table-column v-else label="Valor" centered v-slot="props">{{ props.row.points}}</b-table-column>
            <b-table-column label="       " centered v-slot="props">
              <b-button class="is-text is-danger" @click="removeProduct(props.row._id)">
                Remover
              </b-button>
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large"></b-icon>
                  </p>
                  <p>Adicione Artigos</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
        <!-- <div class="column is-1"></div> -->
      </div>
      <div class="columns">
        <div class="column is-3 is-offset-9">
          <h1 class="title is-4">
            Total:
            <span class="title is-3 has-text-secondary-color">{{calculateTotal(itemsCart)}} pontos</span>
          </h1>
        </div>
      </div>
      <div class="columns">
        <div class="column is-11 is-offset-1">
          <hr class="cart hr" />
        </div>
      </div>
      <div class="columns">
        <div class="column is-1 is-offset-9">
          <b-button :disabled="itemsCart.length == 0" @click="goToProcess()" class="is-primary-color is-large">
            Processar pedido
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  //MIXINS
  import {
    cart
  } from "../mixins/modules/cart";

  import ImgZoom from '../components/Shared/ImgZoom'

  export default {
    name: "Cart",
    mixins: [cart],
    components: {ImgZoom},
    created() {
      this.itemsCart = this.getCart(this.getAppSelected._id).products
      this.isEmpty = this.itemsCart.length > 0 ? false : true;
    },
    data() {
      return {
        isEmpty: false,
        itemsCart: [],
      };
    },
    methods: {
      calculateTotal(array) {
        let total = 0;
        if (this.getAppSelected._id != "app") {
          for (const i in array) {
            array[i];
            if (this.getUserInfo.shareholder) total += array[i].price.regularized;
            else total += array[i].price.not_regularized;
          }
        } else {
          for (const i in array) {
            array[i];
            total += array[i].points;
          }
        }

        return total;
      },
      goToHome() {
        this.$router.push({name: 'SharedProducts'});
      },
      goToProcess() {
        this.$router.push("/order");
      },
      removeProduct(product_id) {
        this.removeFromCart(this.getAppSelected._id, product_id);
        this.itemsCart = this.getCart(this.getAppSelected._id).products
      }
    }
  };
</script>