export default [
    {
        label: "Preto",
        color: "#000000"
    },
    {
        label: "Cinza",
        color: "#666666"
    },
    {
        label: "Bordeaux",
        color: "#660000"
    },
    {
        label: "Vermelho",
        color: "#CC0000"
    },
    {
        label: "Laranja",
        color: "#FF6600"
    },
    {
        label: "Amarelo",
        color: "#FFFF00"
    },
    {
        label: "Verde",
        color: "#339933"
    },
    {
        label: "Branco",
        color: "#FFFFFF",
        showBorder: true
    },
    {
        label: "Bege",
        color: "#FFCC99"
    },
    {
        label: "Camel",
        color: "#CC9933"
    },
    {
        label: "Castanho",
        color: "#663300"
    },
    {
        label: "Roxo",
        color: "#663366"
    },
    {
        label: "Rosa",
        color: "#FF0099"
    },
    {
        label: "Azul",
        color: "#0099FF"
    },
    {
        label: "Ganga",
        color: "Ganga"
    },
    {
        label: "Multicor",
        color: "Multicor"
    },
]