<template>
	<div>
		<div class="home">
			<section
				id="homeD"
				class="hero one is-fullheight-with-navbar is-hidden-mobile"
				style="background-color: white; position: relative;"
			>
				<img
					class="homeimage"
					src="../../src/assets/img/10.jpg"
					style="bottom: 0; right: 0; position: absolute"
				/>
				<div class="hero-body">
					<div class="container">
						<div class="columns">
							<div class="column is-half is-10-touch is-offset-1-touch">
								<img
									style="
                    max-width: 90%;
                    display: block;
                    margin: auto;
                    width: 90%;
                  "
									src="./../assets/logo/logo.svg"
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section
				id="homeM"
				class="hero one is-fullheight-with-navbar is-hidden-tablet"
				style="background-color: white"
			>
				<img
					style="
            max-width: 90%;
            display: block;
            margin: auto;
            width: 80%;
            margin-top: 20px;
          "
					src="./../assets/logo/logo.svg"
				/>
				<img
					style="width: 100%; display: block; margin: auto"
					class="homeimage"
					src="../../src/assets/img/10b.jpg"
				/>
			</section>
			<section class="hero about two is-fullheight">
				<div class="columns io-section">
					<div class="column">
						<div id="about" class="is-full">
							<h1
								class="title about column is-half is-offset-one-quarter is-10-mobile is-offset-1-mobile"
							>Sobre</h1>
						</div>
						<div class="column is-half is-offset-one-quarter is-10-mobile is-offset-1-mobile">
							<p class="is-size-6" v-if="carrouselPage == 0">
								O consumo excessivo da sociedade moderna é um problema crítico
								para todos os seres humanos e para os recursos do planeta. A
								Indústria da Moda (vestuário, têxteis-lar, calçado e acessórios)
								é das mais poluentes e altamente consumidora de recursos
								naturais.
								<br />Mas é possível dar uma segunda vida a muitos destes artigos de
								moda. E TU PODES AJUDAR O PLANETA COM AS TUAS AÇÕES!
							</p>
							<p class="is-size-6" v-if="carrouselPage == 1">
								Os recursos do planeta não são infinitos e o Homem consome todos
								os anos mais do que “um planeta terra”. As suas ações consomem
								recursos e resultam na produção de lixo em enormes
								quantidades.
								<br />Os Millennials (geração Y) e os Zoomers (geração Z) serão os
								próximos a sofrer com a irresponsabilidade das gerações
								anteriores mais consumidoras dos países mais desenvolvidos.
							</p>
							<p class="is-size-6" v-if="carrouselPage == 2">
								A sustentabilidade está suportada em três dimensões: a
								ambiental, a social e a económica. É necessário garantir o
								equilíbrio entre todas elas e os objetivos da ONU presentes na
								Agenda para o Desenvolvimento Sustentável 2030 são bem
								claros.
								<br />O solução TO-BE-GREEN foca-se no objetivo 11 “Cidades e
								Comunidades Sustentáveis” e no objetivo 12 “Produção e Consumo
								Responsáveis”.
							</p>
						</div>
					</div>
					<div class="column">
						<carousel v-on:pageChange="pageChange" class="about img" :scrollPerPage="true" :perPage="1">
							<slide v-for="(item, i) in carousels.about" :key="i">
								<img :src="item" alt />
							</slide>
						</carousel>
					</div>
				</div>
			</section>
			<section class="hero three is-fullheight">
				<div class="columns io-section">
					<div id="services" class="column">
						<h1 class="title services">Serviços</h1>
					</div>
				</div>
				<div class="container">
					<div class="columns">
						<div class="column is-1-desktop"></div>

						<!-- CARD 1 -->
						<div
							v-if="!cards.anyOpen || cards.delivery.open"
							class="column is-10-touch is-offset-1-touch"
						>
							<div class="card home">
								<div v-if="!cards.delivery.open" class="card-image">
									<figure class="image is-4by3">
										<img src="./../assets/img/6.png" alt="Placeholder image" />
									</figure>
								</div>
								<div class="card-content">
									<button v-if="cards.delivery.open" @click="openCard('delivery', false)" class="delete"></button>
									<p class="has-text-primary-color sub-title">Entregas</p>

									<!-- <h4>Entregas</h4> -->
									<p v-if="!cards.delivery.open">
										A roupa que tu e a tua família já não usam podem fazer parte
										de um processo sustentável. Faz o upload das fotos dessas
										peças na app e entrega-as no espaço TO-BE-GREEN do teu
										concelho (LOJA SOCIAL OU OUTRO).
									</p>
									<p class="is-size-6" v-if="cards.delivery.open">
										A “hierarquia de resíduos” defendida pela União Europeia
										começa pela prevenção, passando pela reutilização,
										seguindo-se a reciclagem, depois a valorização energética e,
										em último caso, a deposição em aterro. Também a To-Be-Green
										está organizada dessa forma. Hoje existe tecnologia e
										conhecimento que podem ser utilizados para ajudar o Planeta.
										Mas é preciso que os consumidores estejam envolvidos neste
										processo desde o início.
										<br />
										<br />Para entregares a roupa que já não usas e que
										pretendes descartar para partilha, basta fazeres o seguinte:
										Regista-te no site www.to-be-green.pt e faz o download da
										app na PlayStore ou AppleStore.
										<br />
										<br />Depois tira fotos das peças que vais entregar e
										identifica-as. Coloca-as no saco TO-BE-GREEN e entrega no
										local que te é sugerido pela app (LOJA SOCIAL ou outro).
										Receberás uma mensagem a confirmar as tuas entregas. E
										ganhas pontos que podes usar para adquirir outros artigos de
										moda na opção “Partilha” ou de parceiros da TO-BE-GREEN. As
										peças serão avaliadas pela equipa TO- BE-GREEN e em caso de
										venda receberás 2/3 desse valor da venda. O outro 1/3 será
										entregue a uma IPSS do teu concelho para ajudar quem mais
										precisa.
										<br />
										<br />TODOS GANHAM COM A TO-BE-GREEN. E dizemos-te qual o
										destino das tuas entregas: venda em segunda mão, upcycling,
										partilha, doação a pessoas carenciadas da tua região,
										reciclagem de matéria-prima ou valorização energética. COM
										TOTAL TRANSPARÊNCIA.
										<br />
									</p>
									<div class="b" v-if="!cards.delivery.open">
										<b-button class="is-primary-color knowMore" @click="openCard('delivery', true)">
											<b>Saber Mais</b>
										</b-button>
									</div>
									<div v-else class="b">
										<b-button class="is-primary-color" @click="openRegister">Aderir</b-button>
									</div>
								</div>
							</div>
						</div>
						<!-- CARD 2 -->
						<div v-if="!cards.anyOpen || cards.share.open" class="column is-10-touch is-offset-1-touch">
							<div class="card home">
								<div v-if="!cards.share.open" class="card-image">
									<figure class="image is-4by3">
										<img src="./../assets/img/16.jpg" alt="Placeholder image" />
									</figure>
								</div>
								<div class="card-content">
									<button v-if="cards.share.open" @click="openCard('share', false)" class="delete"></button>
									<p class="has-text-primary-color sub-title">Partilhas</p>
									<p v-if="!cards.share.open">
										As peças entregues na app TO-BE-GREEN podem ter uma segunda
										vida. Vê os artigos na partilha e em upcycling na tua
										região. Depois é só escolheres.
									</p>
									<p class="is-size-6" v-if="cards.share.open">
										Esta é a forma de dar nova vida a peças de vestuário usadas.
										Podes selecionar entre um conjunto de excelentes opções de
										artigos de moda as peças mais “green” e que nem tinhas
										reparado na tua loja e marca preferidas. Basta usares os
										teus pontos TO-BE-GREEN. Esta é uma opção SUSTENTÁVEL de
										consumo de moda.
										<br />Também podes optar por peças em “upcycling”,
										redesenhadas por novos designers capazes de pensarem
										criativamente propostas ÚNICAS de moda, combinando diversos
										materiais, usados e novas matérias-primas, revertendo esse
										valor de aquisição para os próprios designers.
										<br />
										<br />Os serviços de ação social das autarquias (câmaras e
										juntas) e as IPSS’s podem selecionar as peças de roupa para
										as famílias mais carenciadas. COM DIGNIDADE E QUALIDADE.
										PORQUE MERECEM.
										<br />
										<br />Também podes trocar ou vender os teus uniformes ou
										vestuário institucional desde que a tua organização pertença
										à COMUNIDADE TO-BE-GREEN.
										<br />
									</p>
									<div class="b" v-if="!cards.share.open">
										<b-button class="is-primary-color knowMore" @click="openCard('share', true)">
											<b>Saber Mais</b>
										</b-button>
									</div>
									<div v-else class="b">
										<b-button class="is-primary-color" @click="openRegister">Aderir</b-button>
									</div>
								</div>
							</div>
						</div>
						<!-- CARD 3 -->
						<div v-if="!cards.anyOpen || cards.store.open" class="column is-10-touch is-offset-1-touch">
							<div class="card home">
								<div v-if="!cards.store.open" class="card-image">
									<figure class="image is-4by3">
										<img src="./../assets/img/21.jpg" alt="Placeholder image" />
									</figure>
								</div>
								<div class="card-content">
									<button v-if="cards.store.open" @click="openCard('store', false)" class="delete"></button>
									<p class="has-text-primary-color sub-title">Lojas Sociais TO-BE-GREEN</p>

									<p v-if="!cards.store.open">
										As Lojas Sociais TO-BE-GREEN (TBG) reúnem todas as entregas
										de roupa feitas pelos utilizadores da app em cada município,
										podendo ser doadas às famílias mais carenciadas ou
										adquiridas pela população nesses espaços.
									</p>

									<p class="is-size-6" v-if="cards.store.open">
										Será uma oportunidade de vender e comprar roupa em segunda
										mão. Dois terços do valor da venda serão para o utilizador
										que fez a entrega e um terço reverterá para uma IPSS de cada
										concelho.
										<br />A TO-BE-GREEN não receberá nenhum valor por este serviço,
										servindo apenas como Loja Social que pretende valorizar e
										comercializar as roupas que têm valor comercial em mercado
										de roupa usada. Todos ganham com esta solução!
										<br />A valorização financeira das entregas feitas, com 66% para o
										utilizador que entregou as peças e 33% para uma IPSS a
										indicar pelo município será mais um apoio para aqueles que
										mais necessitam em cada território.
										<br />Todas as entregas serão tratadas e avaliadas pela equipa
										TO-BE-GREEN garantindo um período de isolamento das entregas
										para anular a propagação da pandemia através das roupas
										partilhadas.
										<br />
									</p>
									<div class="b" v-if="!cards.store.open">
										<b-button class="is-primary-color knowMore" @click="openCard('store', true)">
											<b>Saber Mais</b>
										</b-button>
									</div>
									<div v-else class="b">
										<b-button class="is-primary-color" @click="openRegister">Aderir</b-button>
									</div>
								</div>
							</div>
						</div>

						<!-- CARD 4 -->
						<div v-if="!cards.anyOpen || cards.covid.open" class="column is-10-touch is-offset-1-touch">
							<div class="card home">
								<div v-if="!cards.covid.open" class="card-image">
									<figure class="image is-4by3">
										<img src="./../assets/img/22.jpg" alt="Placeholder image" />
									</figure>
								</div>
								<div class="card-content">
									<button v-if="cards.covid.open" @click="openCard('covid', false)" class="delete"></button>
									<p class="has-text-primary-color sub-title">COVID FREE</p>

									<p v-if="!cards.covid.open">
										As roupas entregues nas Lojas Sociais TBG terão um processo
										absolutamente seguro do ponto de vista sanitário. Serão
										COVID free. Também as máscaras sociais descartadas serão aí
										recolhidas e retiradas dos lixos domésticos para posterior
										processamento de reciclagem.
									</p>
									<p class="is-size-6" v-if="cards.covid.open">
										O mundo vive uma situação dramática de pandemia global. A
										propagação do vírus tem de ser parada de todas as formas. As
										roupas entregues nas Lojas Sociais TO-BE-GREEN terão um
										processo absolutamente seguro do ponto de vista sanitário.
										Serão COVID FREE. Haverá uma “GREEN QUARANTINE” das peças
										entregues de pelo menos 14 dias (as evidências científicas
										dizem que 5 dias seriam suficientes, mas todo o cuidado é
										importante), sendo depois avaliadas e higienizadas
										(tratamento UV ou similar) para só depois poderem ser
										partilhadas.
										<br />
										<br />Também as máscaras sociais descartadas após o seu uso serão
										recolhidas nos espaços TO-BE- GREEN, com um fluxo de
										processamento autónomo e seguro, sendo assim retiradas dos
										lixos domésticos para posterior processamento de reciclagem.
										Serão milhões de máscaras têxteis de uso geral comunitário
										que terão tratamento sustentável. As máscaras descartadas
										não podem ser “o novo lixo” deixado ao acaso e por tratar.
										<br />
									</p>
									<div class="b" v-if="!cards.covid.open">
										<b-button class="is-primary-color knowMore" @click="openCard('covid', true)">
											<b>Saber Mais</b>
										</b-button>
									</div>
									<div v-else class="b">
										<b-button class="is-primary-color" @click="openRegister">Aderir</b-button>
									</div>
								</div>
							</div>
						</div>

						<!-- CARD 5 -->
						<div
							v-if="!cards.anyOpen || cards.institution.open"
							class="column is-10-touch is-offset-1-touch"
						>
							<div class="card home">
								<div v-if="!cards.institution.open" class="card-image">
									<figure class="image is-4by3">
										<img src="./../assets/img/12.jpg" alt="Placeholder image" />
									</figure>
								</div>
								<div class="card-content">
									<button
										v-if="cards.institution.open"
										@click="openCard('institution', false)"
										class="delete"
									></button>
									<p class="has-text-primary-color sub-title">Comunidade TO-BE-GREEN</p>

									<p v-if="!cards.institution.open">
										Todos são chamados a participar no desafio do
										Desenvolvimento Sustentável. As autarquias, as empresas, as
										escolas, as IPSS’s e demais organizações podem ser atores
										deste processo. É só registar-se.
									</p>
									<p class="is-size-6" v-if="cards.institution.open">
										As instituições devem estar envolvidas neste processo de
										Sustentabilidade da Cadeia de Valor da Moda.
										<br />As autarquias devem ser os principais interlocutores
										neste processo. Num processo “win-win” em que ganham os seus
										munícipes, ganha o município e ganha o PLANETA TERRA.
										<br />
										<br />As escolas e universidades devem ser o palco central
										de um processo de mudança tão necessário quanto urgente.
										<br />
										<br />A app e plataforma TO-BE-GREEN pode ser a resposta
										central para os Millennials e os Zoomers (geração Y e Z)
										estarem a defender o seu futuro, focada no descarte de
										artigos de vestuário. PORQUE NÃO EXISTE PLANETA B!
										<br />
									</p>
									<div class="b" v-if="!cards.institution.open">
										<b-button class="is-primary-color knowMore" @click="openCard('institution', true)">
											<b>Saber Mais</b>
										</b-button>
									</div>
									<div v-else class="b">
										<b-button class="is-primary-color" @click="openRegister">Aderir</b-button>
									</div>
								</div>
							</div>
						</div>
						<div class="column is-1-desktop"></div>
					</div>
				</div>
			</section>
			<section class="hero two">
				<div class="columns io-section">
					<div class="column">
						<div id="partners" class="column is-full">
							<h1 class="title partners">Parceiros</h1>
						</div>

						<div>
							<carousel
								:autoplay="true"
								:autoplayTimeout="3000"
								class="partners img"
								:loop="true"
								:scrollPerPage="true"
								:perPageCustom="[[1024, 3]]"
								:perPage="1"
							>
								<slide v-for="(partner, i) in carousels.partners" :key="i">
									<a :href="partner.url" target="_blank">
										<img class="partners" :src="partner.img" />
									</a>
								</slide>
							</carousel>
						</div>
					</div>
				</div>
			</section>

			<!--  -->
		</div>
	</div>
</template>

<script>
	import { Carousel, Slide } from "vue-carousel";

	export default {
		name: "ComercialPage",
		components: {
			Carousel,
			Slide,
		},
		data() {
			return {
				carrouselPage: 0,
				cards: {
					delivery: {
						open: false,
					},
					share: {
						open: false,
					},
					institution: {
						open: false,
					},
					store: {
						open: false,
					},
					covid: {
						open: false,
					},
					anyOpen: false,
				},
				carousels: {
					about: [
						require("../assets/img/19.jpg"),
						require("../assets/img/18.jpg"),
						require("../assets/img/20.png"),
					],
					partners: [
						{
							url: "http://www.cm-viseu.pt/",
							img: require("./../assets/img/partners/Logo_CM_Viseu.jpg"),
						},
						{
							url: "http://www.cm-covilha.pt/",
							img: require("./../assets/img/partners/6.png"),
						},
						{
							img: require("./../assets/img/partners/guimaraes_branco.png"),
							url: "https://www.cm-guimaraes.pt/",
						},
						{
							img: require("./../assets/img/partners/setup_branco.png"),
							url: "https://setupguimaraes.com/",
						},
						{
							img: require("./../assets/img/partners/2.png"),
							url: "https://iotech.pt",
						},
						{
							img: require("./../assets/img/partners/1.png"),
							url: "http://www.colegiodaviasacra.pt/",
						},
						{
							img: require("./../assets/img/partners/5.png"),
							url: "https://www.esam.pt/",
						},
					],
				},
			};
		},
		methods: {
			openRegister() {
				this.$router.push("/register");
			},
			pageChange(i) {
				this.carrouselPage = i;
			},
			openCard(value, boolean) {
				document.getElementById("services").scrollIntoView(true);
				this.cards.anyOpen = boolean;
				this.cards[value].open = boolean;
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../assets/sass/main";

	#homeD,
	#homeM,
	#services,
	#about,
	#partners {
		padding-top: 40px;
		margin-top: -40px;
	}

	@media screen and (max-width: 1023px) {
		.title {
			font-size: 4rem !important;
			margin-left: 1rem !important;
			margin-top: 2rem !important;
		}
	}

	@media screen and (max-width: 425px) {
		.homeimage {
			width: 100%;
		}
	}

	.icon {
		margin-left: 5px;
	}

	.title.partners {
		// margin-top: unset;
	}

	.title {
		margin-left: 13rem;
		margin-top: 10rem;
		font-size: 6rem;
	}

	.sub-title {
		font-size: 1.5rem;
		font-weight: 700;
		margin-bottom: 1.5rem;
	}

	.partners.img {
		margin-top: 20%;
		margin-bottom: 2%;
	}

	.partners.img {
		margin-bottom: 2%;
		margin-top: 5%;
		position: relative;
	}

	.VueCarousel-slide img.partners {
		width: 100%;
		height: 100%;
		max-height: 134px;
		display: block;
		-o-object-fit: scale-down;
		object-fit: scale-down;
	}

	.knowMore {
		bottom: 0;
		position: inherit;
		margin-top: 2rem;
	}

	section.one {
		padding-bottom: 30px;

		.hero-body {
			/* position: fixed; */
			width: 100%;
			z-index: 1;
			margin: 0 auto;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		}
	}

	section.two {
		background-color: #4bacb8;
		z-index: 29;
		position: relative;
		padding-bottom: 30px;

		p {
			color: $white;
		}
	}

	section.three {
		background-color: #b0d189;
		z-index: 29;
		position: relative;
	}

	.footer {
		img {
			position: relative;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 90%;
		}

		overflow-wrap: break-word;
		background-color: #e4e4e4;
		position: relative;
		z-index: 29;
		padding: 0rem 1.5rem 0.5rem;

		img {
			max-width: 28%;
		}
	}
</style>