import {get, post, put, remove} from "./../../utils/http"


export function getInstitutions() {
    let uri = '/institutions'
    return get(uri)
}

export function getInstitutionByID(id) {
    let uri = '/institutions/' + id;
    return get(uri)
}

export function createInstitution(data) {
    let uri = '/institutions'
    return post(uri, data)
}

export function updateInstitution(id, data) {
    let uri = '/institutions/' + id
    return put(uri, data)
}

export function removeInstitution(id) {
    let uri = '/institutions/' + id
    return remove(uri)
}