var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal",class:{ 'is-active': _vm.modalOpen }},[_c('div',{staticClass:"modal-background"}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Completar registo")])]),_c('section',{staticClass:"modal-card-body",staticStyle:{"background":"white"}},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Género")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Género","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"name":"origin","expanded":"","placeholder":"Selecione o seu género"},model:{value:(_vm.form.genre),callback:function ($$v) {_vm.$set(_vm.form, "genre", $$v)},expression:"form.genre"}},_vm._l((_vm.genres),function(genre){return _c('option',{key:genre.genre_id,domProps:{"value":genre}},[_vm._v(_vm._s(genre.genre))])}),0),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Contacto Telefónico")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Contacto Telefónico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.contact),expression:"form.contact"}],staticClass:"input",attrs:{"type":"number","placeholder":""},domProps:{"value":(_vm.form.contact)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "contact", $event.target.value)}}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("País")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"País","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"name":"origin","expanded":"","placeholder":"Selecione o país"},on:{"input":function($event){return _vm.loadDistricts()}},model:{value:(_vm.country_selected),callback:function ($$v) {_vm.country_selected=$$v},expression:"country_selected"}},_vm._l((_vm.countries),function(country){return _c('option',{key:country.country_id,attrs:{"selected":""},domProps:{"value":country}},[_vm._v(_vm._s(country.country))])}),0),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Distrito")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Distrito","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"disabled":!_vm.country_selected,"name":"origin","expanded":"","placeholder":"Selecione o distrito"},on:{"input":function($event){return _vm.loadMunicipalities()}},model:{value:(_vm.district_selected),callback:function ($$v) {_vm.district_selected=$$v},expression:"district_selected"}},_vm._l((_vm.districts),function(district){return _c('option',{key:district.district_code,domProps:{"value":district}},[_vm._v(_vm._s(district.district_name))])}),0),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Município")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Município","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"disabled":!_vm.district_selected,"name":"origin","expanded":"","placeholder":"Selecione o município"},model:{value:(_vm.municipality_selected),callback:function ($$v) {_vm.municipality_selected=$$v},expression:"municipality_selected"}},_vm._l((_vm.municipalities),function(municipality){return _c('option',{key:municipality.municipality_code,domProps:{"value":municipality}},[_vm._v(_vm._s(municipality.municipality_name))])}),0),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Morada")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Morada","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.location.address),expression:"form.location.address"}],staticClass:"input",attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.form.location.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.location, "address", $event.target.value)}}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Nº Cliente Serviços Água/Resíduos")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.water_id),expression:"form.water_id"}],staticClass:"input",attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.form.water_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "water_id", $event.target.value)}}})])]),_c('hr'),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('b-checkbox',{attrs:{"type":'is-black'},model:{value:(_vm.form.communication),callback:function ($$v) {_vm.$set(_vm.form, "communication", $$v)},expression:"form.communication"}},[_vm._v("Pretendo receber newsletters e outro tipo de contactos da ToBeGreen.")])],1)])]),_c('footer',{staticClass:"modal-card-foot"},[_c('div',{staticStyle:{"width":"100%"}},[_c('b-button',{staticClass:"is-secondary-color is-medium is-fullwidth",attrs:{"loading":_vm.isLoading},on:{"click":function($event){return handleSubmit(_vm.updateUser)}}},[_vm._v("Guardar")]),_c('p',[_vm._v("ou")]),_c('a',{on:{"click":_vm.logout}},[_vm._v("Terminar sessão")])],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }