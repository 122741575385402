<template>
	<div class="modal">
		<ValidationObserver v-slot="{}">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">Adicionar artigo</p>
					<button @click="setAddProductOpen(false)" class="delete" aria-label="close"></button>
				</header>
				<section class="modal-card-body" style="background: white;">
					<div class="card-content">
						<div v-if="step == 'fields'">
							<ProductInfo v-model="product" />
						</div>
						<div v-else>
							<p>Escolher local de entrega:</p>
							<br />
							<ChoosePlace @placeselected="changePlace" />
						</div>
					</div>
				</section>
				<footer class="modal-card-foot">
					<b-button
						v-if="step == 'fields'"
						@click="step = 'place'"
						class="is-primary-color is-medium is-fullwidth"
					>Próximo</b-button>
					<b-button
						v-if="step == 'place'"
						@click="step = 'fields'"
						class="is-secondary-color is-medium is-fullwidth"
						:loading="isLoading"
					>Anterior</b-button>
					<b-button
						v-if="step == 'place'"
						@click="add()"
						:disabled="!product.place"
						class="is-primary-color is-medium is-fullwidth"
						:loading="isLoading"
					>Adicionar</b-button>
				</footer>
			</div>
		</ValidationObserver>
	</div>
</template>

<script>
	//API
	import { addProduct } from "../../api/products/products";

	import ChoosePlace from "./ChoosePlace";
	import ProductInfo from "./ProductInfo";

	export default {
		name: "AddProduct",
		mixins: [],
		components: {
			ChoosePlace,
			ProductInfo,
		},
		props: {},
		data() {
			return {
				step: "fields",
				isLoading: false,
				product: {
					product: {
						genre: null,
						genreChild: null,
						description: null,
						size: null,
						color: "",
						imgs: [],
						type: null,
						weight: null,
					},
					prices: {
						regularized: null,
						not_regularized: null,
					},
					location: {
						address: null,
						municipality: null,
						district: null,
						country: null,
					},
					quantity: null,
					points: null,
					price: null,
					place: null,
				},
			};
		},
		methods: {
			changePlace(m) {
				this.product.place = m._id;
			},
			add() {
				this.isLoading = true;

				let data = {
					products: [this.product],
				};

				data.place = this.product.place;

				if (this.getAppSelected._id != "app") {
					if (this.getAppSelectedType == "institution")
						data.institution = this.getAppSelected._id;
					// if (this.getAppSelectedType == "place")
					//     data.place = this.getAppSelected._id;
				}

				addProduct(data)
					.then((resp) => {
						this.isLoading = false;
						this.$emit("addproduct", resp.data.body);
						this.$buefy.toast.open({
							message: "Artigo adicionado!",
							type: "is-success",
							position: "is-bottom",
						});
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;
						this.$buefy.toast.open({
							message: "Erro a adicionar o artigo!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../../assets/sass/main";

	.blank {
		border-color: transparent;
	}

	.modal {
		text-align: center !important;
	}
</style>