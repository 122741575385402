<template>
	<div style="padding-bottom: 10vh;">
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>

		<h5 class="title is-5 has-text-centered">Encomendas</h5>

		<div class="columns is-multiline is-mobile is-centered">
			<b-table
				class="column is-10 is-offset-1"
				:data="isEmpty ? [] : orders"
				:paginated="true"
				:per-page="10"
				default-sort="id"
				:current-page.sync="currentPage"
				@page-change="changePage"
				:mobile-cards="true"
				detailed
			>
				<b-table-column label="ID" centered v-slot="props">
					<a @click="$router.push('/order/' + props.row._id)">{{props.row.id}}</a>
				</b-table-column>

				<b-table-column
					label="Data"
					centered
					v-slot="props"
				>{{ moment(props.row.order_date).format('L')}} {{moment(props.row.order_date).format('LT')}}</b-table-column>

				<b-table-column
					v-if="getAppSelectedType == 'partner'"
					label="Instituição"
					centered
					v-slot="props"
				>{{ props.row.institution ? props.row.institution.name : "Aplicação" }}</b-table-column>

				<b-table-column
					v-if="getAppSelectedType == 'partner'"
					label="Local"
					centered
					v-slot="props"
				>{{ props.row.place ? props.row.place.name : "Local não encontrado!" }}</b-table-column>

				<b-table-column label="Estado" centered v-slot="props">{{ orderStatus[props.row.status] }}</b-table-column>

				<b-table-column
					label="Tipo de pagamento"
					centered
					v-slot="props"
				>{{ paymentTypes[props.row.payment_type] }}</b-table-column>

				<b-table-column centered v-slot="props">
					<b-button type="is-primary-color" @click="openOrder(props.row._id)">
						<b-icon size="is-small" icon="eye"></b-icon>
					</b-button>
				</b-table-column>

				<b-table-column field="delivery" label centered v-slot="props">
					<b-button
						class="is-primary-color"
						:disabled="props.row.status !='waiting'"
						@click="change_status_shopping(props.row._id)"
					>Entregar</b-button>
				</b-table-column>

				<template slot="detail" slot-scope="props">
					<article v-for="(item) in props.row.products" v-bind:key="item._id" class="media">
						<figure class="media-left">
							<p class="image is-64x64">
								<ImgZoom :imgsrc="item.product.product.imgs[0]" />
							</p>
						</figure>
						<div class="media-content">
							<div class="content">
								<div v-if="item.product">
									<p>
										<strong>ID:</strong>
										{{ item.product.id}}
									</p>
									<p>
										<strong>Género:</strong>
										{{ item.product.product.genre}}
										{{item.product.product.genreChild ? '- ' + item.product.product.genreChild : ''}}
									</p>
									<p>
										<strong>Tipo</strong>
										{{ item.product.product.type}}
									</p>
									<p>
										<strong>Tamanho:</strong>
										{{ item.product.product.size}}
									</p>
									<p>
										<strong>Cor:</strong>
										{{ item.product.product.color}}
									</p>
									<p>
										<strong>Descrição:</strong>
										{{ item.product.product.description}}
									</p>
								</div>
								<p v-else>
									<strong>Não foi possível obter os dados do artigo !</strong>
									<br />
								</p>
							</div>
						</div>
					</article>
				</template>
			</b-table>
			<div v-if="isEmpty">
				<section class="section">
					<div class="content has-text-grey has-text-centered">
						<p>Não existem encomendas!</p>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>




<script>
	//API
	import {
		getOrders,
		putOrders,
		getByMunicipality,
	} from "./../../api/order/order";

	import PaymentTypes from "../../config/PaymentTypes";
	import OrdersStatus from "../../config/OrdersStatus";

	import ImgZoom from "../Shared/ImgZoom";

	const queryString = require("query-string");

	export default {
		name: "Orders",
		mixins: [],
		components: { ImgZoom },
		props: {
			currentTab: String,
		},
		created() {
			this.getOrders();
			this.currentPage = this.$route.query.page
				? parseInt(this.$route.query.page)
				: 1;
		},
		data() {
			return {
				isEmpty: false,
				currentPage: 1,
				orders: [],
				isLoading: false,
				modalOpen: false,
				orderStatus: OrdersStatus,
				paymentTypes: PaymentTypes,
			};
		},
		methods: {
			getOrders() {
				this.$route.name == "OrdersProducts" ? (this.isLoading = true) : "";
				let query = {};

				let func = getOrders;

				if (this.getAppSelected._id != "app") {
					if (this.getAppSelectedType == "institution")
						query.institution = this.getAppSelected._id;
					if (this.getAppSelectedType == "place")
						query.place = this.getAppSelected._id;
					if (this.getAppSelectedType == "partner") {
						func = getByMunicipality;
						query.partner = this.getAppSelected._id;
					}
				}

				func("?" + queryString.stringify(query))
					.then((result) => {
						this.orders = result.data.body;
						if (this.orders.length <= 0) this.isEmpty = true;
						this.isLoading = false;
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
					});
			},
			openOrder(_id) {
				this.$router.push("/order/" + _id);
			},
			change_status_shopping(shopping_id) {
				this.isLoading = true;
				putOrders(shopping_id, {
					status: "delivered",
				})
					.then((response) => {
						for (let i in this.orders) {
							if (this.orders[i]._id == response.data.body._id) {
								this.$set(this.orders, i, response.data.body);
							}
						}
						this.isLoading = false;
						this.$buefy.toast.open({
							message: "Atualizado com sucesso!",
							type: "is-success",
							position: "is-bottom",
						});
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;
						this.$buefy.toast.open({
							message: "Não foi possível atualizar!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
			changePage(a) {
				this.scrollToTop();
				
				if (this.$route.query != undefined) {
					let q = JSON.parse(JSON.stringify(this.$route.query));
					q.page = a;
					this.$router.push({ query: q });
				} else {
					this.$router.push({ query: { page: a } });
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../../assets/sass/main";

	img.pending {
		max-height: 100px;
		max-width: 100px;
		height: auto;
		width: auto;
		margin: auto;
	}

	.table th {
		color: $primary-color !important;
	}
</style>