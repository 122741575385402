import {
    get,
    post,
    remove,
    put
} from "./../../utils/http"



const prefix = '/products'

export function getAllProducts(query) {
    let uri;
    uri = `${prefix}${query}`
    return get(uri)
}

export function getByMunicipality(query) {
    let uri;
    uri = `${prefix}/municipality${query}`
    return get(uri)
}

export function getProduct(id) {
    let uri = `${prefix}/${id}`
    return get(uri)
}

export function disableProduct(id) {
    let uri = `${prefix}/${id}`
    return remove(uri)
}


export function addProduct(data) {
    let uri = `${prefix}`
    return post(uri, data)
}

export function getPendingProducts(institutionSelected) {
    let uri;
    if (institutionSelected)
        uri = `${prefix}/pending`
    else
        uri = `${prefix}/pending`
    return get(uri)
}

export function getDeliveredProducts(institutionSelected) {
    let uri;
    if (institutionSelected)
        uri = `${prefix}/delivered?institution=${institutionSelected}`
    else
        uri = `${prefix}/delivered`
    return get(uri)
}


export function postApproveProducts(data) {
    let uri = `${prefix}/approve`
    return post(uri, data)
}

export function postRejectProducts(data) {
    let uri = `${prefix}/approve`
    return remove(uri, data)
}

export function getInstitutionProductsDelivery(data) {
    let uri = `/institutionproducts?institution=${data}`
    return get(uri)
}

export function addFileProducts(data) {
    let uri = `${prefix}/file`;
    return post(uri, data)
}

export function editProduct(id, data) {
    let uri = `${prefix}/` + id;
    return put(uri, data)
}