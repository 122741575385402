<template>
	<div style="padding-bottom: 10vh;">
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>

		<h5 class="title is-5 has-text-centered">Peças a aguardar aprovação</h5>

		<div class="columns is-multiline is-mobile is-centered">
			<b-table
				v-if="getPendingProducts.length > 0"
				:data="getPendingProducts.length == 0 ? [] : getPendingProducts"
				:checked-rows.sync="checkedRows"
				:paginated="true"
				@page-change="scrollToTop()"
				:per-page="perPage"
				:current-page.sync="currentPage"
				checkable
				:mobile-cards="true"
			>
				<b-table-column field="img" label width="150" centered v-slot="props">
					<ImgZoom :imgsrc="props.row.product.imgs[0]" />
				</b-table-column>

				<b-table-column field="id" label="ID" width="100" centered v-slot="props">{{props.row.id}}</b-table-column>

				<b-table-column
					field="description"
					label="Descrição"
					centered
					v-slot="props"
				>{{ props.row.product.description }}</b-table-column>

				<b-table-column
					v-if="getAppSelectedType == 'partner'"
					label="Instituição"
					centered
					v-slot="props"
				>{{ props.row.institution ? props.row.institution.name : "Aplicação" }}</b-table-column>

				<b-table-column
					v-if="getAppSelectedType == 'partner'"
					label="Local"
					centered
					v-slot="props"
				>{{ props.row.place ? props.row.place.name : "Local não encontrado!" }}</b-table-column>

				<b-table-column
					field="genre"
					label="Género"
					centered
					v-slot="props"
				>{{ props.row.product.genre }}</b-table-column>

				<b-table-column
					field="user"
					label="Utilizador"
					centered
					v-slot="props"
				>{{ props.row.user && props.row.user.info ? props.row.user.info.name : '' }}</b-table-column>

				<b-table-column
					field="size"
					label="Tamanho"
					centered
					v-slot="props"
				>{{ props.row.product.size }}</b-table-column>

				<b-table-column field="color" label="Cor" centered v-slot="props">{{props.row.product.color }}</b-table-column>

				<b-table-column
					field="points"
					width="60"
					label="Pontos"
					centered
					v-slot="props"
				>{{ props.row.points }}</b-table-column>

				<b-table-column label="Unidades" centered v-slot="props">{{props.row.quantity}}</b-table-column>

				<b-table-column label="Editar" centered v-slot="props">
					<b-button type="is-secondary-color" @click="modal(JSON.parse(JSON.stringify(props.row)))">
						<b-icon icon="border-color" size="is-small"></b-icon>
					</b-button>
				</b-table-column>
			</b-table>
			<div v-else>
				<section class="section">
					<div class="content has-text-grey has-text-centered">
						<p>Não existem artigos pendentes!</p>
					</div>
				</section>
			</div>
		</div>
		<div style="margin-top:2rem" class="columns is-multiline is-mobile is-centered">
			<div class="column is-8"></div>
			<div class="column" style="text-align: right;">
				<b-button @click="approveProducts()" class="is-primary-color">Aprovar Selecionados</b-button>
			</div>
			<div class="column" style="text-align: right;">
				<b-button @click="rejectProducts()" class="is-danger">Reciclar Selecionados</b-button>
			</div>
		</div>
		<EditProduct
			v-on:productupdate="productUpdate"
			:product_edit="product_edit"
			v-if="isEditProductOpen"
			:class="{ 'is-active': isEditProductOpen }"
		/>
	</div>
</template>




<script>
	//API
	import {
		getAllProducts,
		postApproveProducts,
		getByMunicipality,
		postRejectProducts,
	} from "./../../api/products/products";
	import EditProduct from "../product/EditProduct";

	import ImgZoom from "../Shared/ImgZoom";

	import { product } from "../../mixins/modules/product";

	const queryString = require("query-string");

	export default {
		name: "Pending",
		mixins: [product],
		components: {
			EditProduct,
			ImgZoom,
		},
		props: {
			user: Object,
			currentTab: String,
		},
		created() {
			this.pendingProducts();
			this.currentPage = this.$route.query.page
				? parseInt(this.$route.query.page)
				: 1;
		},
		data() {
			return {
				checkedRows: [],
				currentPage: 1,
				perPage: 10,
				institutionID: null,
				isLoading: false,
				product_edit: null,
			};
		},
		methods: {
			pendingProducts() {
				this.$route.name == "PendingProducts"
					? (this.isLoading = true)
					: "";
				let query = {
					status: "pending",
				};

				let func = getAllProducts;

				if (this.getAppSelected._id != "app") {
					if (this.getAppSelectedType == "institution")
						query.institution = this.getAppSelected._id;
					if (this.getAppSelectedType == "place")
						query.place = this.getAppSelected._id;
					if (this.getAppSelectedType == "partner") {
						query.partner = this.getAppSelected._id;
						func = getByMunicipality;
					}
				}

				func("?" + queryString.stringify(query))
					.then((result) => {
						this.setPendingProducts(result.data.body);
						this.isLoading = false;
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
					});
			},
			modal(_product) {
				this.product_edit = _product;
				this.setEditProductOpen(true);
			},
			rejectProducts() {
				if (this.checkedRows.length <= 0)
					return this.$buefy.toast.open({
						message: "Selecione um artigo primeiro!",
						type: "is-danger",
						position: "is-bottom",
					});

				this.isLoading = true;

				let data = [];
				this.checkedRows.forEach((element) => {
					data.push(element._id);
				});
				let self = this;

				postRejectProducts({
					products: data,
				})
					.then((result) => {
						this.isLoading = false;
						this.removeFromPending();
						this.$buefy.toast.open({
							message: "Artigos reprovados com sucesso!",
							type: "is-success",
							position: "is-bottom",
						});
						this.$emit("return");
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
						this.$buefy.toast.open({
							message: "Ocorreu um erro!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
			approveProducts() {
				if (this.checkedRows.length <= 0)
					return this.$buefy.toast.open({
						message: "Selecione um artigo primeiro!",
						type: "is-danger",
						position: "is-bottom",
					});

				this.isLoading = true;

				let data = [];
				this.checkedRows.forEach((element) => {
					data.push(element._id);
				});
				let self = this;

				postApproveProducts({
					products: data,
				})
					.then((response) => {
						this.isLoading = false;
						this.removeFromPending();
						this.$buefy.toast.open({
							message: "Artigos aprovados com sucesso!",
							type: "is-success",
							position: "is-bottom",
						});
						this.$emit("return");
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
						this.$buefy.toast.open({
							message: "Ocorreu um erro!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
			removeFromPending() {
				this.checkedRows.forEach((element) => {
					this.removePendingProduct(element._id);
				});
				this.checkedRows = [];
				this.$emit("return");
			},
			productUpdate(_product) {
				this.updatePendingProduct(_product);
				this.$emit("return");
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../../assets/sass/main";

	img.pending {
		max-height: 100px;
		max-width: 100px;
		height: auto;
		width: auto;
		margin: auto;
	}

	.table th {
		color: $primary-color !important;
	}
</style>