const state = {
    isMobile: window.innerWidth <= 1023,
    width: window.innerWidth
}

const getters = {
    isMobile(state, getters) {
        return state.isMobile;
    }
}

const mutations = {
    updateWidth(state, width) {
        state.isMobile = width <= 1023;
        state.width = width;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}