<template>
  <div class="column is-3-desktop is-offset-8-desktop is-10-touch is-offset-1-touch">
    <ValidationObserver v-slot="{ handleSubmit }">
      <div class="card">
        <div class="card-content">
          <h5 class="title is-5 has-text-primary-color">Recuperar password</h5>
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                <input v-model="form.email" class="input" type="text" placeholder />
                <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
              </ValidationProvider>
            </div>
          </div>
          <b-button :loading="isLoading" @click="handleSubmit(recover)" class="is-secondary-color is-medium is-fullwidth">Recuperar
          </b-button>
          <hr>
          <h6 class="subtitle is-6">Tem a sua password?</h6>
          <b-button @click="login()" class="is-outlined is-primary-color is-medium is-fullwidth">Login</b-button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
  import {
    recover_password_email
  } from "@/api/user/user";

  export default {
    data() {
      return {
        isLoading: false,
        form: {
          email: ""
        }
      };
    },
    components: {},
    mixins: [],
    methods: {
      recover() {
        this.isLoading = true;

        recover_password_email(this.form.email)
          .then(response => {
            if (response.status == 200) {
              this.isLoading = false;
              this.$buefy.toast.open({
                message: "Foi enviado um email de confirmação!",
                type: "is-success",
                position: "is-bottom"
              });
              this.$router.push("login");
            } else {
              this.isLoading = false;
              this.$buefy.toast.open({
                message: "Erro a recuperar a password!",
                type: "is-danger",
                position: "is-bottom"
              });
            }
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
            this.$buefy.toast.open({
              message: "Erro a recuperar a password!",
              type: "is-danger",
              position: "is-bottom"
            });
          });

      },
      login() {
        this.$router.push("login");
      }
    }
  };
</script>