<template>
    <div class="login">
        <section class="hero is-fullheight">
            <b-loading :active.sync="isLoading"></b-loading>

        </section>
    </div>
</template>

<script>

    import {
        getPartnerByID
    } from '../api/partners/partners.js';

    import {
        associatePartner
    } from '../api/user/user.js';

    export default {
        name: "InvitePartner",
        components: {},
        mixins: [],
        data() {
            return {
                isLoading: false,
                partner: null
            };
        },
        methods: {
            findPartner(callback) {
                getPartnerByID(this.$route.params.id)
                    .then(res => {
                        this.partner = res.data.body;
                        return callback();
                    })
                    .catch(error => {
                        console.log(error)
                        this.$buefy.toast.open({
                            message: "Ocorreu um erro!",
                            type: "is-danger",
                            position: "is-bottom"
                        });
                        return this.$router.push("/login");
                    })
            },
            confirmInvite() {
                let _this = this;
                this.$buefy.dialog.confirm({
                    title: 'Convite para munícipio parceiro',
                    message: `Usou um link de convite de um munícipio parceiro, pretende aceitar?<br><b>${this.partner.name}</b>`,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    type: 'is-primary-color',
                    onConfirm: () => {
                        associatePartner(this.getUserInfo._id, this.$route.params.id)
                            .then(res => {
                                _this.$buefy.toast.open({
                                    message: "Convite aceite com sucesso!",
                                    type: "is-success",
                                    position: "is-bottom"
                                });
                                _this.$router.push({name: 'SharedProducts'});
                            })
                            .catch(error => {
                                console.log(error)
                                _this.$buefy.toast.open({
                                    message: "Erro a aceitar o convite!",
                                    type: "is-danger",
                                    position: "is-bottom"
                                });
                            })
                    },
                    onCancel() {
                        return _this.$router.push({name: 'SharedProducts'});
                    }
                })
            }
        },
        created() {
            this.isLoading = true;
            this.findPartner(() => {
                this.isLoading = false;
                if (!this.getUserToken)
                    return this.$router.push("/login?p=" + this.$route.params.id + '&n=' + this.partner.name);

                this.confirmInvite();
            });



        }
    };
</script>

<style lang="css">

</style>