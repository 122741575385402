import Districts from '../../config/Districts';
import Municipalities from '../../config/Municipality';
import Countries from '../../config/Countries';

export const locations = {
    computed: {
        getDistricts() {
            return Districts;
        },
        getMunicipalities() {
            return Municipalities;
        },
        getCountries() {
            return Countries;
        }
    },
    methods: {
        getDistrictsOfCountry(country_id) {
            return Districts.filter(d => d.country_id == country_id);
        },
        getMunicipalitiesOfDistrict(district_code) {
            return Municipalities.filter(m => m.district_code == district_code);
        },
        getCountryByName(country) {
            return Countries.filter(d => d.country == country)[0];
        },
        getDistrictByName(district_name) {
            return Districts.filter(d => d.district_name == district_name)[0];
        },
        getMunicipalityByName(municipality_name) {
            return Municipalities.filter(m => m.municipality_name == municipality_name)[0];
        }
    }
}