export const navbar = {
    computed: {
        isBurgerOpen() {
            return this.$store.getters['navbar/getIsBurgerOpen']
        },
        isFilterOpen() {
            return this.$store.getters['navbar/getIsFilterOpen']
        },
        isProfileOpen() {
            return this.$store.getters['navbar/getIsProfileOpen']
        },
        isCartOpen() {
            return this.$store.getters['navbar/getIsCartOpen']
        },
        isNotificationsOpen() {
            return this.$store.getters['navbar/getIsNotificationsOpen']
        },
        isSidebarOpen() {
            return this.$store.getters['navbar/getIsSidebarOpen']
        },
        isEditProductOpen() {
            return this.$store.getters['navbar/getIsEditProductOpen']
        },
        isAddProductOpen() {
            return this.$store.getters['navbar/getIsAddProductOpen']
        }
    },
    methods: {
        toggleBurguerOpen: function () {
            this.$store.commit('navbar/toggleBurgerOpen')
            this.setCartOpen(false)
            this.setProfileOpen(false);
            this.setNotificationsOpen(false);
        },
        toggleFilterOpen: function () {
            this.$store.commit('navbar/toggleFilterOpen')
        },
        toggleProfileOpen: function () {
            this.$store.commit('navbar/toggleProfileOpen')
            this.setNotificationsOpen(false)
            this.setCartOpen(false);
            this.setBurguerOpen(false);
        },
        toggleCartOpen: function () {
            this.$store.commit('navbar/toggleCartOpen')
            this.setNotificationsOpen(false)
            this.setProfileOpen(false);
            this.setBurguerOpen(false);
        },
        toggleSidebarOpen() {
            this.$store.commit('navbar/toggleSidebarOpen')
        },
        toggleNotificationsOpen() {
            this.$store.commit('navbar/toggleNotificationsOpen')
            this.setCartOpen(false)
            this.setProfileOpen(false);
            this.setBurguerOpen(false);
        },
        setBurguerOpen(open) {
            this.$store.commit('navbar/setBurguerOpen', open)
            if (open) {
                this.setCartOpen(false)
                this.setProfileOpen(false);
                this.setNotificationsOpen(false);
            }
        },
        setFilterOpen(open) {
            this.$store.commit('navbar/setFilterOpen', open)
        },
        setCartOpen(open) {
            this.$store.commit('navbar/setCartOpen', open)
            if (open) {
                this.setNotificationsOpen(false)
                this.setProfileOpen(false);
                this.setBurguerOpen(false);
            }
        },
        setProfileOpen(open) {
            this.$store.commit('navbar/setProfileOpen', open)
            if (open) {
                this.setNotificationsOpen(false)
                this.setCartOpen(false);
                this.setBurguerOpen(false);
            }
        },
        setNotificationsOpen(open) {
            this.$store.commit('navbar/setNotificationsOpen', open)
            if (open) {
                this.setCartOpen(false)
                this.setProfileOpen(false);
                this.setBurguerOpen(false);
            }
        },
        setSidebarOpen(open) {
            this.$store.commit('navbar/setSidebarOpen', open)
        },
        setEditProductOpen(open) {
            this.$store.commit('navbar/setEditProductOpen', open)
        },
        setAddProductOpen(open) {
            this.$store.commit('navbar/setAddProductOpen', open)
        }
    }
}