<template>
	<div class="column is-8 is-offset-2">
		<h1 class="title is-4">Política de Privacidade</h1>
		<p style="margin-bottom: 13px;">Este website recolhe alguns Dados Pessoais dos utilizadores.</p>
		<p>
			<b>Proprietário e Controlador de Dados</b>
		</p>
		<p
			style="margin-bottom: 13px;"
		>Os domínios presentes nesta política são propriedade de GREENADN Lda, empresa sediada em Travessa da Rua da Igreja, Nº 186, 2ºDrt, Creixomil, 4835-060 Guimarães , com NIPC 515463965, registado na Conservatória de Viseu, com capital social de 4000€.</p>
		<p style="margin-bottom: 13px;">
			<b>E-mail de contato do Proprietário:</b> geral@to-be-green.pt
		</p>

		<BulmaAccordion dropdown icon="plus-minus">
			<!-- The wrapper component for all the items -->
			<BulmaAccordionItem>
				<h4 slot="title">
					<b>Tipos de Dados coletados</b>
				</h4>
				<div slot="content">
					<p>Entre os tipos de Dados Pessoais que este website coleta, por si mesmo ou através de terceiros, existem: Cookie e Dados de uso.</p>
					<p>Detalhes completos sobre cada tipo de Dados Pessoais coletados são fornecidos nas seções dedicadas desta política de privacidade ou por textos explicativos específicos exibidos antes da coleta de Dados.</p>
					<p>Os Dados Pessoais poderão ser fornecidos livremente pelo utilizador, ou, no caso dos Dados de Utilização, coletados automaticamente ao se utilizar este website.</p>
					<p>A menos que especificado diferentemente todos os Dados solicitados por este website são obrigatórios e a falta de fornecimento destes dados poderá impossibilitar este website de fornecer os seus serviços. Nos casos em que este website afirmar especificamente que alguns dados não forem obrigatórios, os utilizadores ficam livres para deixarem de comunicar esses mesmo dados sem nenhuma consequência para a disponibilidade ou o funcionamento do Serviço.</p>
					<p>Os utilizadores que tiverem dúvidas a respeito de quais Dados Pessoais são obrigatórios estão convidados a entrar em contato com o Proprietário.</p>
					<p>Quaisquer usos de cookies – ou de outras ferramentas de rastreamento – por este website ou pelos proprietários de serviços terceiros utilizados por este website serão para a finalidade de fornecer os serviços solicitados pelo utilizador, além das demais finalidades descritas no presente documento e na Política de Cookies.</p>
					<p>Os utilizadores ficam responsáveis por quaisquer dados Pessoais de terceiros que forem obtidos, publicados ou compartilhados através deste serviço (este website) e confirmam que possuem a autorização dos terceiros para fornecerem os Dados para o Proprietário.</p>
				</div>
			</BulmaAccordionItem>
			<!-- add as many of these items as you need - fill them with content via the slots -->
			<BulmaAccordionItem>
				<h4 slot="title">
					<b>Método de processamento</b>
				</h4>
				<div slot="content">
					<p>O Proprietário tomará as medidas de segurança adequadas para impedir o acesso não autorizado, divulgação, alteração ou destruição não autorizada dos Dados.</p>
					<p>O processamento dos Dados é realizado utilizando computadores e /ou ferramentas de TI habilitadas, seguindo procedimentos organizacionais e meios estritamente relacionados com os fins indicados. Além do Proprietário, em alguns casos, os Dados podem ser acessados por certos tipos de pessoas encarregadas, envolvidas com a operação deste Serviço (este website) (administração, vendas, marketing, administração legal do sistema) ou pessoas externas (como fornecedores terceirizados de serviços técnicos, carteiros, provedores de alojamento, empresas de TI, agências de comunicação) nomeadas, quando necessário, como Processadores de Dados por parte do Proprietário. A lista atualizada destas partes pode ser solicitada ao Proprietário a qualquer momento.</p>
				</div>
			</BulmaAccordionItem>
			<BulmaAccordionItem>
				<h4 slot="title">
					<strong>Base jurídica para o processamento</strong>
				</h4>
				<div slot="content">
					<p>O Proprietário poderá processar os Dados Pessoais relacionados ao utilizador se uma das hipóteses a seguir se aplicar:</p>
					<ul type="disc">
						<li>Os utilizadores tenham dado a sua anuência para uma ou mais finalidades específicas; Observação: De acordo com algumas legislações o Proprietário poderá ter a permissão para processar os Dados Pessoais ATÉ QUE O utilizador faça objeção a isto (“opt-out”), sem ter que se basear em anuência ou em quaisquer outras bases jurídicas a seguir. Isto contudo não se aplica sempre que o processamento de Dados Pessoais estiver sujeito à legislação europeia de proteção de dados;</li>
						<li>o fornecimento dos Dados for necessário para o cumprimento de um contrato com o utilizador e/ou quaisquer obrigações pré-contratuais do mesmo;</li>
						<li>o processamento for necessário para o cumprimento de uma obrigação jurídica à qual o Proprietário estiver sujeito;</li>
						<li>o processamento estiver relacionado a uma tarefa que for executada no interesse público ou no exercício de uma autorização oficial na qual o Proprietário estiver investido;</li>
						<li>o processamento for necessário para a finalidade de interesses legítimos perseguidos pelo Proprietário ou por um terceiro;</li>
					</ul>
					<p>Em qualquer caso, o Proprietário colaborará de bom grado para esclarecer qual a base jurídica que se aplica ao processamento, e em especial se o fornecimento de Dados for um requisito obrigatório por força de lei ou contratual, ou uma exigência necessária para celebrar um contrato.</p>
				</div>
			</BulmaAccordionItem>

			<BulmaAccordionItem>
				<h4 slot="title">
					<strong>Lugar</strong>
				</h4>
				<div slot="content">
					<p>Os dados são processados nas sedes de operação dos Proprietários, e em quaisquer outros lugares onde as partes envolvidas com o processamento estiverem localizadas.</p>
					<p>Dependendo da localização do utilizador as transferências de dados poderão envolver a transferência dos Dados do utilizador para outro país que não seja o seu. Para descobrirem mais sobre o local de processamento de tais Dados transferidos os utilizadores poderão verificar a seção contendo os detalhes sobre o processamento de Dados Pessoais.</p>
					<p>Os utilizadores também possuem o direito de serem informados sobre a base jurídica das transferências de Dados para países de fora da União Europeia ou para quaisquer organizações internacionais regidas pelo direito internacional público ou formadas por dois ou mais países, tal como a ONU, e sobre as medidas de segurança tomadas pelo Proprietário para proteger os seus Dados.</p>
					<p>Se ocorrerem quaisquer tais transferências os utilizadores poderão descobrir mais a respeito verificando as seções pertinentes deste documento ou perguntando ao Proprietário utilizando as informações fornecidas na seção de contatos.</p>
				</div>
			</BulmaAccordionItem>
			<BulmaAccordionItem>
				<h4 slot="title">
					<strong>Período de conservação</strong>
				</h4>
				<div slot="content">
					<p>Os Dados Pessoais serão processados e armazenados pelo tempo que for necessário para as finalidades para as quais forem coletados.</p>
					<p>Portanto:</p>
					<ul type="disc">
						<li>Os Dados Pessoais coletados para as finalidades relacionadas com a execução de um contrato entre o Proprietário e o utilizador serão conservados até que tal contrato tenha sido completamente cumprido.</li>
						<li>Os Dados Pessoais coletados para as finalidades relacionadas com os legítimos interesses do Proprietário serão conservados pelo tempo que for necessário para cumprir tais finalidades. Os utilizadores poderão obter informações específicas sobre os interesses legítimos perseguidos pelo Proprietário dentro das seções pertinentes deste documento ou entrando em contato com o Proprietário.</li>
					</ul>
					<p>O Proprietário poderá ter a permissão de conservar os Dados Pessoais por um prazo maior sempre que o utilizador tiver dado a sua autorização para tal processamento, enquanto tal autorização não tiver sido retirada. Além disso, o Proprietário poderá ficar obrigado a conservar os Dados Pessoais por um prazo maior em todas as ocasiões em que estiver obrigado a fazê-lo para o cumprimento de uma obrigação jurídica ou em cumprimento de um mandado de uma autoridade.</p>
					<p>Assim que o prazo de conservação vencer os Dados Pessoais serão apagados. Desta forma o direito de acessar, o direito de apagar, o direito de corrigir e o direito à portabilidade dos dados não poderão ter o seu cumprimento exigido após o vencimento do prazo de conservação.</p>
				</div>
			</BulmaAccordionItem>
			<BulmaAccordionItem>
				<h4 slot="title">
					<strong>Finalidades de processamento</strong>
				</h4>
				<div slot="content">
					<p>Os Dados relativos ao utilizador são coletados para permitir que o Proprietário forneça os seus Serviços, bem como para os seguintes propósitos: Estatísticas, Remarketing.</p>
					<p>Os utilizadores poderão obter informações adicionais detalhadas sobre tais finalidades do processamento e sobre os Dados Pessoais específicos utilizados para cada finalidade nas seções respectivas deste documento.</p>
				</div>
			</BulmaAccordionItem>
			<BulmaAccordionItem>
				<h4 slot="title">
					<strong>Informações detalhadas sobre o processamento de Dados Pessoais</strong>
				</h4>
				<div slot="content">
					<p>Os Dados Pessoais são recolhidos para os seguintes fins e utilizando os seguintes serviços:</p>
					<ul type="disc">
						<li>
							<strong>Estatísticas</strong>
						</li>
						<li>Os serviços contidos nesta seção habilitam o Proprietário a monitorar e analisar o tráfego da web e podem ser usados para rastrear o comportamento do utilizador.</li>
						<li>
							<strong>WordPress Stat (Automattic Inc.)</strong>
						</li>
						<li>WordPress é um serviço de análise fornecido pela Automattic Inc.</li>
						<li>Dados Pessoais coletados: Cookie e Dados de uso.</li>
						<li>
							Lugar de processamento: EUA –&nbsp;
							<a
								href="https://automattic.com/privacy/"
							>Política de Privacidade</a>.
						</li>
						<li>
							<strong>Google Analytics (Google Inc.)</strong>
						</li>
						<li>O Google Analytics é um serviço de análise da Internet fornecido pela Google Inc. (“Google”). O Google utiliza os dados coletados para acompanhar e examinar o uso deste serviço (este website) para preparar relatórios sobre atividades e compartilhá-los com outros serviços do Google.</li>
						<li>O Google pode usar os dados coletados para contextualizar e personalizar os anúncios de sua própria rede de publicidade.</li>
						<li>Dados Pessoais coletados: Cookie e Dados de uso.</li>
						<li>
							Lugar de processamento: EUA –&nbsp;
							<a
								href="https://www.google.com/intl/pt/policies/privacy/"
							>Política de Privacidade</a> –&nbsp;
							<a href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt Out</a>. Participante do Privacy Shield.
						</li>
						<li>
							<strong>Google Adwords (Google Inc.)</strong>
						</li>
						<li>O Google Adwords é um serviço de análise da Internet fornecido pela Google Inc. (“Google”). O Google utiliza os dados coletados para acompanhar e examinar o uso deste serviço (este website) para preparar relatórios sobre atividades e compartilhá-los com outros serviços do Google.</li>
						<li>O Google pode usar os dados coletados para contextualizar e personalizar os anúncios de sua própria rede de publicidade.</li>
						<li>Dados Pessoais coletados: Cookie e Dados de uso.</li>
						<li>
							Lugar de processamento: EUA –&nbsp;
							<a
								href="https://www.google.com/intl/pt/policies/privacy/"
							>Política de Privacidade</a> –&nbsp;
							<a href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt Out</a>. Participante do Privacy Shield.
						</li>
						<li>
							<strong>Mailchimp</strong>
						</li>
						<li>O Mailchimp é um aplicativo de envio massivo de newsletters.</li>
						<li>Este aplicativo apenas recolhe os dados submetidos por cada utilizador no formulário disponível na homepage e páginas onde o formulário está disponível.</li>
						<li>Ao preencher o nosso formulário concorda com o envio de informações sobre a TO-BE-GREEN para o email inscrito, sendo que poderá reiterar esta autorização a qualquer momento.</li>
					</ul>
				</div>
			</BulmaAccordionItem>
		</BulmaAccordion>
	</div>
</template>

<script>
	import { BulmaAccordion, BulmaAccordionItem } from "vue-bulma-accordion";

	export default {
		name: "PrivacyPolicy",
		components: { BulmaAccordion, BulmaAccordionItem },
		created() {},
	};
</script>

<style scoped>
	.card {
		border-radius: unset;
	}
	.column {
		margin-top: 5vh;
		margin-bottom: 5vh;
	}

	ul {
		list-style: outside;
		padding-left: 30px;
	}

	p {
		margin-bottom: 10px;
	}
</style>