<template>
	<div class="column is-4-desktop is-offset-7-desktop is-10-touch is-offset-1-touch">
		<div class="card">
			<ValidationObserver v-slot="{ handleSubmit }">
				<div class="card-content">
					<h5 class="title is-5 has-text-primary-color">Registar</h5>
					<div class="field">
						<label class="label">Nome</label>
						<div class="control">
							<ValidationProvider name="Nome" rules="required" v-slot="{ errors }">
								<input v-model="form.name" class="input" type="text" />
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</div>
					<div class="field">
						<label class="label">Email</label>
						<div class="control">
							<ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
								<input v-model="form.email" class="input" type="text" />
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</div>

					<div class="field">
						<label class="label">Password</label>
						<div class="control">
							<ValidationProvider
								name="Password"
								rules="required|password:@confirm_password"
								v-slot="{ errors }"
							>
								<b-input type="password" password-reveal v-model="form.password" />
								<!-- <input v-model="form.password" class="input" type="password" /> -->
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</div>

					<div class="field">
						<label class="label">Confirmação da Password</label>
						<div class="control">
							<ValidationProvider
								name="Confirmação da Password"
								vid="confirm_password"
								rules="required"
								v-slot="{ errors }"
							>
								<!-- <input v-model="form.confirm_password" class="input" type="password" /> -->
								<b-input type="password" password-reveal v-model="form.confirm_password" />
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</div>

					<div class="field">
						<label class="label">Data de Nascimento</label>
						<div class="control">
							<ValidationProvider name="Data de Nascimento" rules="required" v-slot="{ errors }">
								<b-datepicker
									v-model="form.birthday"
									:show-week-number="false"
									:max-date="maxDate"
									placeholder="Clica para selecionar"
									required
								></b-datepicker>
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</div>

					<div v-if="getAge < 18 && form.birthday" class="field">
						<label class="label">Email do tutor legal</label>
						<div class="control">
							<ValidationProvider name="Email do tutor legal" rules="required|email" v-slot="{ errors }">
								<input v-model="form.guardian_email" class="input" type="text" />
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</div>

					<br />
					<div class="field">
						<label class="label">Termos, Condições e Políticas</label>
						<div class="control">
							<ValidationProvider
								name="Termos, Condições e Políticas"
								:rules="{ required: { allowFalse: false } }"
								v-slot="{ errors }"
							>
								<b-checkbox v-model="form.acceptsTerms" :value="true"></b-checkbox>Ao clicar em "Registar", concorda com nossos
								<b>Termos</b>. Saiba como guardamos e usamos os seus dados na nossa
								<a @click.prevent="openPolicy('PrivacyPolicy')">Política de Privacidade</a>, como usamos cookies e tecnologias semelhantes na nossa
								<a @click.prevent="openPolicy('CookiesPolicy')">Política de Cookies</a> e os seus <a @click.prevent="openPolicy('MyRights')">Direitos</a>
								<br />
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</div>

					<b-button
						:loading="isLoading"
						class="is-primary-color is-medium is-fullwidth"
						@click="handleSubmit(register)"
					>Registar</b-button>
					<hr />
					<h6 class="subtitle is-6">Já é membro?</h6>
					<b-button class="is-outlined is-primary-color is-medium is-fullwidth" @click="login()">Login</b-button>
				</div>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
	import { register } from "@/api/user/user";

	export default {
		data() {
			return {
				isLoading: false,
				fullPage: true,
				form: {
					name: "",
					email: "",
					password: "",
					confirm_password: "",
					acceptsTerms: false,
					birthday: null,
					guardian_email: null,
				},
				maxDate: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					new Date().getDate()
				),
			};
		},
		components: {},
		mixins: [],
		methods: {
			register() {
				this.isLoading = true;
				if (this.$route.query.i)
					this.form.institution = this.$route.query.i;

				register(this.form)
					.then((response) => {
						if (response.data.http == 201) {
							this.isLoading = false;

							this.$buefy.toast.open({
								message:
									"Registo efetuado com sucesso. Verifique o seu email.",
								type: "is-success",
								position: "is-bottom",
							});

							this.$router.push("login");
						} else {
							this.isLoading = false;
							this.$buefy.toast.open({
								message: "Erro a registar!",
								type: "is-danger",
								position: "is-bottom",
							});
						}

						this.isLoading = false;
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;
						this.$buefy.toast.open({
							message:
								"Dados de registo inválidos. Verifique se já tem conta criada.",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
			login() {
				if (this.$route.query.i)
					return this.$router.push(
						"/login?i=" +
							this.$route.query.i +
							"&n=" +
							this.$route.query.n
					);
				if (this.$route.query.p)
					return this.$router.push(
						"/login?p=" +
							this.$route.query.p +
							"&n=" +
							this.$route.query.n
					);
				return this.$router.push("/login");
			},
			openPolicy(name) {
				let routeData = this.$router.resolve({
					name,
				});
				window.open(routeData.href, "_blank");
			},
		},
		computed: {
			getAge() {
				return this.moment().diff(this.form.birthday, "years", false);
			},
		},
	};
</script>

<style lang="scss" scoped>
	a {
		text-decoration: underline !important;
	}
</style>