<template>
	<div class="modal" :class="{ 'is-active': modalOpen }">
		<div class="modal-background"></div>
		<ValidationObserver v-slot="{ handleSubmit }">
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">Completar registo</p>
				</header>
				<section class="modal-card-body" style="background: white;">
					<div class="field">
						<label class="label">Género</label>
						<div class="control">
							<ValidationProvider name="Género" rules="required" v-slot="{ errors }">
								<b-select v-model="form.genre" name="origin" expanded placeholder="Selecione o seu género">
									<option v-for="genre in genres" :value="genre" :key="genre.genre_id">{{ genre.genre }}</option>
								</b-select>
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</div>


					<div class="field">
						<label class="label">Contacto Telefónico</label>
						<div class="control">
							<ValidationProvider name="Contacto Telefónico" rules="required" v-slot="{ errors }">
								<input v-model="form.contact" class="input" type="number" placeholder />
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</div>

					<div class="field">
						<label class="label">País</label>
						<div class="control">
							<ValidationProvider name="País" rules="required" v-slot="{ errors }">
								<b-select
									v-model="country_selected"
									name="origin"
									expanded
									placeholder="Selecione o país"
									@input="loadDistricts()"
								>
									<option
										v-for="country in countries"
										:value="country"
										:key="country.country_id"
										selected
									>{{ country.country }}</option>
								</b-select>
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</div>

					<div class="field">
						<label class="label">Distrito</label>
						<div class="control">
							<ValidationProvider name="Distrito" rules="required" v-slot="{ errors }">
								<b-select
									:disabled="!country_selected"
									v-model="district_selected"
									name="origin"
									expanded
									placeholder="Selecione o distrito"
									@input="loadMunicipalities()"
								>
									<option
										v-for="district in districts"
										:value="district"
										:key="district.district_code"
									>{{ district.district_name }}</option>
								</b-select>
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</div>

					<div class="field">
						<label class="label">Município</label>
						<div class="control">
							<ValidationProvider name="Município" rules="required" v-slot="{ errors }">
								<b-select
									:disabled="!district_selected"
									v-model="municipality_selected"
									name="origin"
									expanded
									placeholder="Selecione o município"
								>
									<option
										v-for="municipality in municipalities"
										:value="municipality"
										:key="municipality.municipality_code"
									>{{ municipality.municipality_name }}</option>
								</b-select>
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</div>

					<div class="field">
						<label class="label">Morada</label>
						<div class="control">
							<ValidationProvider name="Morada" rules="required" v-slot="{ errors }">
								<input v-model="form.location.address" class="input" type="text" placeholder />
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</div>

					<div class="field">
						<label class="label">Nº Cliente Serviços Água/Resíduos</label>
						<div class="control">
							<input v-model="form.water_id" class="input" type="text" placeholder />
						</div>
					</div>
					<hr />
					<div class="field">
						<div class="control">
							<b-checkbox
								v-model="form.communication"
								:type="'is-black'"
							>Pretendo receber newsletters e outro tipo de contactos da ToBeGreen.</b-checkbox>
						</div>
					</div>
				</section>
				<footer class="modal-card-foot">
					<div style="width: 100%;">
						<b-button
							:loading="isLoading"
							@click="handleSubmit(updateUser)"
							class="is-secondary-color is-medium is-fullwidth"
						>Guardar</b-button>
						<p>ou</p>
						<a @click="logout">Terminar sessão</a>
					</div>
				</footer>
			</div>
		</ValidationObserver>
	</div>
</template>

<script>
	import Genres from "../../config/Genres";
	import { locations } from "../../mixins/modules/locations";

	import { complete_Data } from "../../api/user/user.js";

	export default {
		name: "FormCompleteRegister",
		mixins: [locations],
		created() {
			this.genres = Genres;
			this.countries = this.getCountries;

			if (this.getUserInfo.info.location.country) {
				this.country_selected = this.getCountryByName(
					this.getUserInfo.info.location.country
				);
				this.loadDistricts();
				if (this.getUserInfo.info.location.district) {
					this.district_selected = this.getDistrictByName(
						this.getUserInfo.info.location.district
					);
					this.loadMunicipalities();
					if (this.getUserInfo.info.location.municipality)
						this.municipality_selected = this.getMunicipalityByName(
							this.getUserInfo.info.location.municipality
						);
				}
			}
		},
		props: {
			modalOpen: Boolean,
		},
		data() {
			return {
				form: {
					location: {},
				},
				isLoading: false,
				districts: [],
				municipalities: [],
				countries: [],
				country_selected: null,
				district_selected: null,
				municipality_selected: null,
				genres: []
			};
		},
		methods: {
			loadDistricts() {
				this.districts = this.getDistrictsOfCountry(
					this.country_selected.country_id
				);
			},
			loadMunicipalities() {
				this.municipalities = this.getMunicipalitiesOfDistrict(
					this.district_selected.district_code
				);
			},
			logout() {
				this.resetStores();
				this.$router.push("/");
			},
			updateUser() {
				this.isLoading = true;
				let data = {
					water_id: this.form.water_id,
					location: {
						municipality: this.municipality_selected.municipality_name,
						address: this.form.location.address,
						district: this.district_selected.district_name,
						country: this.country_selected.country,
					},
					genre: this.form.genre.genre_id,
					communication: !this.form.communication ? false : true,
					contact: this.form.contact,
					birthday: this.form.birthday,
				};

				complete_Data(data)
					.then((response) => {
						this.isLoading = false;
						this.addUser(response.data.body);
						this.addUserID(response.data.body._id);
						this.$buefy.toast.open({
							message: "Registo completado com sucesso!",
							type: "is-success",
							position: "is-bottom",
						});
						this.$router.go();
					})
					.catch(() => {
						this.isLoading = false;
						this.$buefy.toast.open({
							message:
								"Verifique a validade das informações fornecidas. Se o problema persistir contacte o suporte.",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
		},
	};
</script>