import {
    get,
    post,
    put
} from "../../utils/http"

const prefix = '/orders'

export function postOrder(data) {
    let uri = `${prefix}`
    return post(uri, data)
}

export function getOrders(query) {

    let uri = `${prefix}${query || ''}`;

    return get(uri)
}
export function putOrders(id, data) {

    let uri;
    if (id)
        uri = `${prefix}/${id}/status`


    return put(uri, data)
}

export function getOrderById(id) {
    
    let uri = `${prefix}/${id}`;

    return get(uri)

}

export function getByMunicipality(query) {
    let uri;
    uri = `${prefix}/municipality${query}`
    return get(uri)
}