<template>
	<footer class="footer">
		<div class="columns is-mobile is-multiline is-centered">
			<div class="column is-half-touch">
				<img style="max-width: 70%" class src="./../../assets/logo/logo.svg" />
			</div>
			<div class="column is-half-touch">
				<a
					@click.prevent="$router.push({name: 'ComercialPage'})"
					class="is-primary-color has-text-weight-bold"
				>Página comercial</a>
				<br />
				<a
					@click.prevent="$router.push({name: 'ComercialPage', hash:'#about'})"
					class="is-primary-color"
				>Sobre</a>
				<br />
				<a
					@click.prevent="$router.push({name: 'ComercialPage', hash:'#services'})"
					class="is-primary-color"
				>Serviços</a>
				<br />
				<a
					@click.prevent="$router.push({name: 'ComercialPage', hash:'#partners'})"
					class="is-primary-color"
				>Parceiros</a>
			</div>
			<div class="column is-half-touch">
				<p class="is-primary-color has-text-weight-bold">Contactos</p>
				<p class="is-primary-color">Telf: +351 918204280</p>
				<p class="is-primary-color">
					Email: greenadn.geral@gmail.com
					<br />geral@to-be-green.com
				</p>
			</div>
			<div class="column is-half-touch">
				<p class="is-primary-color has-text-weight-bold">Termos</p>
				<a @click.prevent="openPolicy('PrivacyPolicy')">Política de Privacidade</a>
				<br>
				<a @click.prevent="openPolicy('CookiesPolicy')">Política de Cookies</a>
				<br>
				<a @click.prevent="openPolicy('MyRights')">Os meus direitos</a>
				<p class="is-primary-color">
					© {{ new Date().getFullYear() }} ToBeGreen. All rights
					reserved
				</p>
			</div>
			<div class="column is-half-touch">
				<p class="is-primary-color has-text-weight-bold">Redes Sociais</p>

				<span class="icon has-text-facebook">
					<b-icon icon="facebook"></b-icon>
				</span>

				<span class="icon has-text-instagram">
					<b-icon icon="instagram"></b-icon>
				</span>

				<span class="icon has-text-youtube">
					<b-icon icon="youtube"></b-icon>
				</span>

				<span class="icon has-text-pinterest">
					<b-icon icon="pinterest"></b-icon>
				</span>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: "Footer",
		methods: {
			openPolicy(name) {
				let routeData = this.$router.resolve({
					name,
				});
				window.open(routeData.href, "_blank");
			},
		},
	};
</script>

<style>
	footer .column {
		text-align: center;
	}
</style>