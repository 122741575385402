<template>
	<div>
		<b-loading :active.sync="isLoading" :is-full-page="fullPage"></b-loading>

		<div class="column is-3-desktop is-offset-8-desktop is-10-touch is-offset-1-touch">
			<div class="card">
				<div class="card-content login">
					<h5 class="title is-5 has-text-primary-color">Login</h5>
					<div class="field">
						<label class="label">Email</label>
						<div class="control">
							<input
								@keyup.enter="login"
								v-model="form.email"
								class="input"
								type="text"
								placeholder="Email"
							/>
						</div>
					</div>

					<div class="field">
						<label class="label">Password</label>
						<div class="control">
							<b-input
								type="password"
								password-reveal
								@keyup.native.enter="login"
								v-model="form.password"
								placeholder="Password"
							></b-input>
							<div class="forget">
								<a @click="password_recovery()">Esqueceu-se da password?</a>
							</div>
						</div>
					</div>

					<b-button @click="login()" class="is-primary-color is-medium is-fullwidth">Login</b-button>
					<hr />
					<h6 class="subtitle is-6">Não é membro?</h6>
					<b-button
						@click="register()"
						class="is-outlined is-secondary-color is-medium is-fullwidth"
					>Criar Conta</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { login } from "@/api/auth/login";

	import { associateInstitution, associatePartner } from "../../api/user/user.js";

	export default {
		data() {
			return {
				isLoading: false,
				fullPage: true,
				form: {
					email: "",
					password: "",
				},
			};
		},
		components: {},
		mixins: [],
		methods: {
			register() {
				if (!this.$route.query.i) return this.$router.push("/register");
				return this.$router.push(
					"/register?i=" +
						this.$route.query.i +
						"&n=" +
						this.$route.query.n
				);
			},
			password_recovery() {
				this.$router.push("/recovery");
			},
			login() {
				this.isLoading = true;

				login(this.form)
					.then((response) => {
						this.addUser(response.data.body);
						this.addUserID(response.data.body._id);
						this.addUserToken(response.headers.authorization);
						this.$buefy.toast.open({
							message: "Login efetuado com sucesso!",
							type: "is-success",
							position: "is-bottom",
						});
						this.$socket.emit(
							"get_notifications",
							response.data.body._id
						);
						this.isLoading = false;
						if (!this.$route.query.i && !this.$route.query.p) {
							return this.$router.push({ name: "SharedProducts" });
						}

						let _this = this;

						if (this.$route.query.i) {
							this.$buefy.dialog.confirm({
								title: "Convite para instituição",
								message: `Usou um link de convite de uma instituição, pretende aceitar?<br><b>${this.$route.query.n}</b>`,
								cancelText: "Não",
								confirmText: "Sim",
								type: "is-primary-color",
								onConfirm: () => {
									associateInstitution(
										this.getUserInfo._id,
										this.$route.query.i
									)
										.then((res) => {
											_this.addUser(response.data.body);
											_this.addUserID(response.data.body._id);
											_this.$buefy.toast.open({
												message:
													"Convite aceite com sucesso!",
												type: "is-success",
												position: "is-bottom",
											});
											_this.isLoading = false;
											_this.$router.push({
												name: "SharedProducts",
											});
										})
										.catch((error) => {
											console.log(error);
											_this.$buefy.toast.open({
												message:
													"Erro a aceitar o convite!",
												type: "is-danger",
												position: "is-bottom",
											});
											_this.isLoading = false;
										});
								},
								onCancel() {
									return _this.$router.push({
										name: "SharedProducts",
									});
								},
							});
						}

						if (this.$route.query.p) {
							this.$buefy.dialog.confirm({
								title: "Convite para munícipio parceiro",
								message: `Usou um link de convite de um munícipio parceiro, pretende aceitar?<br><b>${this.$route.query.n}</b>`,
								cancelText: "Não",
								confirmText: "Sim",
								type: "is-primary-color",
								onConfirm: () => {
									associatePartner(
										this.getUserInfo._id,
										this.$route.query.p
									)
										.then((res) => {
											_this.addUser(response.data.body);
											_this.addUserID(response.data.body._id);
											_this.$buefy.toast.open({
												message:
													"Convite aceite com sucesso!",
												type: "is-success",
												position: "is-bottom",
											});
											_this.isLoading = false;
											_this.$router.push({
												name: "SharedProducts",
											});
										})
										.catch((error) => {
											console.log(error);
											_this.$buefy.toast.open({
												message:
													"Erro a aceitar o convite!",
												type: "is-danger",
												position: "is-bottom",
											});
											_this.isLoading = false;
										});
								},
								onCancel() {
									return _this.$router.push({
										name: "SharedProducts",
									});
								},
							});
						}
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;

						if (
							error.response.data.code ==
							"GuardianHasntAcceptedANDAccountNotActivated"
						) {
							this.$buefy.toast.open({
								message:
									"Conta inativa e ainda não aceite pelo tutor, verifique os emails respetivos! NOTA: Verifique o spam!",
								type: "is-danger",
								position: "is-bottom",
								duration: 5000,
							});
						} else if (
							error.response.data.code == "GuardianHasntAccepted"
						) {
							this.$buefy.toast.open({
								message:
									"Conta ainda não aceite pelo tutor, verifique o email!  NOTA: Verifique o spam!",
								type: "is-danger",
								position: "is-bottom",
								duration: 5000,
							});
						} else if (
							error.response.data.code == "AccountNotActivated"
						) {
							this.$buefy.toast.open({
								message: "Conta inativa, verifique o seu email!  NOTA: Verifique o spam!",
								type: "is-danger",
								position: "is-bottom",
								duration: 5000,
							});
						} else if (
							error.response.data.code == "BadEmailOrPassword"
						) {
							this.$buefy.toast.open({
								message: "Dados de login errados!",
								type: "is-danger",
								position: "is-bottom",
								duration: 3000,
							});
						} else {
							this.$buefy.toast.open({
								message: "Ocorreu um erro!",
								type: "is-danger",
								position: "is-bottom",
								duration: 5000,
							});
						}
					});
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../../assets/sass/main.scss";

	.forget {
		text-align: right;
		font-size: 0.9rem;
	}
</style>