import {get, post, put, remove} from "../../utils/http"


export function getPartners() {
    let uri = '/partners'
    return get(uri)
}

export function getPartnerByID(id) {
    let uri = '/partners/' + id;
    return get(uri)
}

export function createPartner(data) {
    let uri = '/partners'
    return post(uri, data)
}

export function updatePartner(id, data) {
    let uri = '/partners/' + id
    return put(uri, data)
}

export function removePartner(id) {
    let uri = '/partners/' + id
    return remove(uri)
}