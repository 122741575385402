<template>
	<div class="column is-8 is-offset-2">
		<h1 class="title is-4">Os meus direitos</h1>

		<p>Os utilizadores poderão exercer determinados direitos a respeito dos seus Dados processados pelo Proprietário.</p>
		<p>Em especial, os utilizadores possuem os direitos a fazer o seguinte:</p>
		<ul type="disc">
			<li>
				<strong>Retirar a sua anuência a qualquer momento.</strong> Os utilizadores possuem o direito de retirar a sua anuência nos casos em que tenham dado a sua anuência anteriormente para o processamento dos seus Dados Pessoais.
			</li>
			<li>
				<strong>Objetar o processamento dos seus Dados.</strong> Os utilizadores possuem o direito de objetar o processamento dos seus Dados se o processamento for executado sobre outra base jurídica que não a anuência. São fornecidos detalhes adicionais na seção específica abaixo.
			</li>
			<li>
				<strong>Acessar os seus Dados.</strong> Os utilizadores possuem o direito de saber se os seus Dados estão sendo processados pelo Proprietário, obter revelações sobre determinados aspectos do processamento e conseguir uma cópia dos Dados que estiverem sendo processados.
			</li>
			<li>
				<strong>Verificar e pedir retificação.</strong> Os utilizadores possuem o direito de verificar a exatidão dos seus Dados e de pedir que os mesmos sejam atualizados ou corrigidos.
			</li>
			<li>
				<strong>Restringir o processamento dos seus Dados.</strong> Os utilizadores possuem o direito de, sob determinadas circunstâncias, restringir o processamento dos seus Dados para qualquer outra finalidade que não seja o armazenamento dos mesmos.
			</li>
			<li>
				<strong>Ter os seus Dados Pessoais apagados ou retirados de outra maneira.</strong> Os utilizadores possuem o direito de, sob determinadas circunstâncias, obter a eliminação dos seus Dados do Proprietário.
			</li>
			<li>
				<strong>Receber os seus Dados e ter os mesmos transferidos para outro controlador.</strong> Os utilizadores possuem o direito de receber os seus Dados em um formato estruturado, utilizado comumente e apto a ser lido por máquinas e, se for viável tecnicamente, fazer com que os mesmos sejam transmitidos para outro controlador sem nenhum empecilho. Esta determinação se aplica condicionada a que os Dados sejam processados por meios automatizados e que o processamento esteja baseado na anuência do utilizador, em um contrato do qual o utilizador seja uma das partes ou por obrigações pré-contratuais do mesmo.
			</li>
			<li>
				<strong>Registrar uma reclamação.</strong> Os utilizadores possuem o direito de apresentar reclamação perante a sua autoridade de proteção de dados competente.
			</li>
		</ul>
	<br>
		<BulmaAccordion dropdown icon="plus-minus">
			<!-- The wrapper component for all the items -->
			<BulmaAccordionItem>
				<h4 slot="title">
					<strong>Detalhes sobre o direito de objetar ao processamento</strong>
				</h4>
				<div slot="content">
					<p>Nos casos em que os Dados Pessoais forem processados por um interesse público, no exercício de uma autorização oficial na qual o Proprietário estiver investido ou para finalidades dos interesses legítimos perseguidos pelo Proprietário, os utilizadores poderão objetar tal processamento através do fornecimento de um motivo relacionado com a sua situação em especial para justificar a objeção.</p>
					<p>Os utilizadores devem saber, entretanto, que caso os seus Dados Pessoais sejam processados para finalidades de marketing direto e remarketing eles podem objetar tal processamento a qualquer momento sem fornecer nenhuma justificativa. Os utilizadores podem consultar as seções respectivas deste documento.</p>
					<p>
						<strong>Como exercer estes direitos</strong>
					</p>
					<p>Quaisquer pedidos para exercer os direitos dos utilizadores podem ser direcionados ao Proprietário através dos dados para contato fornecidos neste documento. Estes pedidos podem ser exercidos sem nenhum custo e serão atendidos pelo Proprietário com a maior brevidade possível e em todos os casos em prazo inferior a um mês.</p>
				</div>
			</BulmaAccordionItem>
			<BulmaAccordionItem>
				<h4 slot="title">
					<strong>Informações adicionais sobre a coleta e processamento de Dados</strong>
				</h4>
				<div slot="content">
					<p>
						<strong>Ação jurídica</strong>
					</p>
					<p>Os Dados Pessoais dos utilizadores podem ser utilizados para fins jurídicos pelo Proprietário em juízo ou nas etapas conducentes à possível ação jurídica decorrente de uso indevido deste Serviço (este website) ou dos Serviços relacionados.</p>
					<p>O utilizador declara estar ciente de que o Proprietário poderá ser obrigado a revelar os Dados Pessoais mediante solicitação das autoridades governamentais.</p>
					<p>
						<strong>Informações adicionais sobre os Dados Pessoais do utilizador</strong>
					</p>
					<p>Além das informações contidas nesta política de privacidade, este website poderá fornecer ao utilizador informações adicionais e contextuais sobre os serviços específicos ou a coleta e processamento de Dados Pessoais mediante solicitação.</p>
					<p>
						<strong>Logs do sistema e manutenção</strong>
					</p>
					<p>Para fins de operação e manutenção, este website e quaisquer serviços de terceiros poderão coletar arquivos que gravam a interação com este website (logs do sistema) ou usar outros Dados Pessoais (tais como endereço IP) para esta finalidade.</p>
					<p>
						<strong>As informações não contidas nesta política</strong>
					</p>
					<p>Mais detalhes sobre a coleta ou processamento de Dados Pessoais podem ser solicitados ao Proprietário, a qualquer momento. Favor ver as informações de contato no início deste documento.</p>
					<p>
						<strong>Como são tratados os pedidos de “Não me Rastreie”</strong>
					</p>
					<p>Este website não suporta pedidos de “Não Me Rastreie”.</p>
					<p>Para determinar se qualquer um dos serviços de terceiros que utiliza honram solicitações de “Não Me Rastreie”, por favor leia as políticas de privacidade.</p>
					<p>
						<strong>Mudanças nesta política de privacidade</strong>
					</p>
					<p>O Proprietário se reserva o direito de fazer alterações nesta política de privacidade a qualquer momento, mediante comunicação aos seus utilizadores nesta página e possivelmente dentro deste website e/ou – na medida em que for viável tecnicamente e juridicamente – enviando um aviso para os utilizadores através de quaisquer informações de contato disponíveis para o Proprietário. É altamente recomendável que esta página seja consultada várias vezes em relação à última modificação descrita na parte inferior.</p>
					<p>Caso as mudanças afetem as atividades de processamento realizadas com base na anuência do utilizador, o Proprietário coletará nova anuência do utilizador, onde for exigida.</p>
					<p>
						<strong>Definições e referências jurídicas</strong>
					</p>
					<p>
						<strong>Dados Pessoais (ou Dados)</strong>
					</p>
					<p>Quaisquer informações que diretamente, indiretamente ou em relação com outras informações – incluindo um número de identificação pessoal – permitam a identificação ou identificabilidade de uma pessoa física.</p>
					<p>
						<strong>Dados de Uso</strong>
					</p>
					<p>As informações coletadas automaticamente através deste este website (ou serviços de terceiros contratados neste Serviço (este website)), que podem incluir: os endereços IP ou nomes de domínio dos computadores utilizados pelos utilizadores que utilizam este website, os endereços URI (Identificador Uniforme de Recurso), a data e hora do pedido, o método utilizado para submeter o pedido ao servidor, o tamanho do arquivo recebido em resposta, o código numérico que indica o status do servidor de resposta (resultado positivo, erro , etc.), o país de origem, as características do navegador e do sistema operacional utilizado pelo utilizador, os vários detalhes de tempo por visita (por exemplo, o tempo gasto em cada página dentro do website) e os detalhes sobre o caminho seguido dentro da aplicação, com especial referência à sequência de páginas visitadas e outros parâmetros sobre o sistema operacional do dispositivo e/ou ambiente de TI do utilizador.</p>
					<p>
						<strong>Utilizador</strong>
					</p>
					<p>A pessoa que usa este website que, a menos que especificado diferentemente, coincida com o Titular dos Dados.</p>
					<p>
						<strong>Titular dos Dados</strong>
					</p>
					<p>A pessoa física a quem os Dados Pessoais se referem.</p>
					<p>
						<strong>Processador de Dados (ou supervisor de Dados)</strong>
					</p>
					<p>A pessoa física ou jurídica, administração pública, agência ou outro órgão que processe os Dados Pessoais em nome do Controlador conforme descrito nesta política de privacidade.</p>
					<p>
						<strong>Controlador de Dados (ou Proprietário)</strong>
					</p>
					<p>A pessoa física ou jurídica, administração pública, agência ou outro órgão que, isoladamente ou em conjunto com outros determinar as finalidades e os meios de processamento dos Dados Pessoais, incluindo medidas de segurança relativas ao funcionamento e ao uso deste Serviço (este website). O Controlador de Dados, a menos que seja especificado de outra forma, é o Proprietário deste Serviço (este website).</p>
					<p>
						<strong>Este website</strong>
					</p>
					<p>O meio pelo qual os Dados Pessoais do utilizador são coletados e processados.</p>
					<p>
						<strong>Serviço</strong>
					</p>
					<p>O serviço fornecido por este website conforme descrito nos termos relativos (se disponíveis) e neste site/website.</p>
					<p>
						<strong>União Europeia (ou UE)</strong>
					</p>
					<p>A menos que especificado diferentemente, todas as referências feitas neste documento à União Europeia incluem todos os atuais estados membros da União Europeia e do Espaço Econômico Europeu.</p>
					<p>
						<strong>Cookie</strong>
					</p>
					<p>Pequenas unidades de dados armazenados no dispositivo do utilizador.</p>
					<p>
						<strong>Informação jurídica</strong>
					</p>
					<p>Esta declaração de privacidade foi preparada com base em determinações de múltiplas legislações, inclusive os Arts. 13/14 do Regulamento (EU) 2016/679 (GDPR – Regulamento Geral de Proteção de Dados).</p>
					<p>Esta política de privacidade se refere somente a este website, se não afirmado diferentemente neste documento.</p>
				</div>
			</BulmaAccordionItem>
		</BulmaAccordion>
	</div>
</template>

<script>
	import { BulmaAccordion, BulmaAccordionItem } from "vue-bulma-accordion";

	export default {
		name: "MyRights",
		components: { BulmaAccordion, BulmaAccordionItem },
		created() {},
	};
</script>

<style scoped>
	.card {
		border-radius: unset;
	}
	.column {
		margin-top: 5vh;
		margin-bottom: 5vh;
	}
	ul {
		list-style: outside;
		padding-left: 30px;
	}

	p {
		margin-bottom: 10px;
	}
</style>