<template>
	<div class="modal">
		<ValidationObserver v-slot="{}">
			<div class="modal-background"></div>
			<div v-if="product_edit" class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">Editar artigo</p>
					<button @click="setEditProductOpen(false)" class="delete" aria-label="close"></button>
				</header>
				<section class="modal-card-body" style="background: white;">
					<div class="card-content">
						<ProductInfo v-model="product_edit" />
					</div>
				</section>
				<footer class="modal-card-foot">
					<b-button
						v-if="!fromImport"
						@click="edit()"
						class="is-primary-color is-medium is-fullwidth"
						:loading="isLoading"
					>Guardar</b-button>
					<b-button
						v-else
						@click="sendBack()"
						class="is-primary-color is-medium is-fullwidth"
						:loading="isLoading"
					>Guardar</b-button>
				</footer>
			</div>
		</ValidationObserver>
	</div>
</template>

<script>
	//API
	import { editProduct } from "../../api/products/products";

	import ProductInfo from "./ProductInfo";

	export default {
		name: "EditProduct",
		components: {
			ProductInfo
		},
		props: {
			product_edit: Object,
			fromImport: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				isLoading: false,
				user: null
			};
		},
		created() {
		},
		computed: {},
		methods: {
			edit() {
				this.isLoading = true;

				editProduct(this.product_edit._id, this.product_edit)
					.then((resp) => {
						this.isLoading = false;
						if (resp.data.http == 200) {
							this.$emit("productupdate", resp.data.body);
							this.$buefy.toast.open({
								message: "Artigo atualizado!",
								type: "is-success",
								position: "is-bottom",
							});
							this.setEditProductOpen(false);
						} else {
							this.$buefy.toast.open({
								message: "Erro a atualizar o artigo!",
								type: "is-danger",
								position: "is-bottom",
							});
						}
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;
						this.$buefy.toast.open({
							message: "Erro a atualizar o artigo!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
			sendBack() {
				this.$emit("productupdate", this.product_edit);
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../../assets/sass/main";

	.blank {
		border-color: transparent;
	}

	.modal {
		text-align: center !important;
	}
</style>