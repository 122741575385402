// initial state
const state = {
    notifications: [],
}

// getters
const getters = {
    getNotifications(state, getters) {
        return state.notifications
    },
}

// mutations
const mutations = {
    resetStore(s) {
        const initial = JSON.parse(JSON.stringify(state));
        Object.keys(initial).forEach(key => {
            s[key] = initial[key]
        });
    },
    saveNotifications(state, _notifications) {
        state.notifications = _notifications
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}