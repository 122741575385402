
export const user = {
    computed: {
        getUserID() {
            return this.$store.getters['user/getUserID']
        },
        getUserInfo() {
            return this.$store.getters['user/getUserInfo']
        },
        getUserToken() {
            return this.$store.getters['user/getUserToken']
        }
    },
    methods: {
        resetStores: function() {
            this.$store.commit('user/resetStore')
            this.$store.commit('cart/resetStore')
            this.$store.commit('map/resetStore')
            this.$store.commit('navbar/resetStore')
            this.$store.commit('order/resetStore')
            this.$store.commit('app/resetStore')
        },
        addUser: function (user) {
            this.$store.commit('user/addUser',user)
        },
        addUserID: function (user) {
            this.$store.commit('user/addUserID',user)
        },
        addUserToken: function (token) {
            this.$store.commit('user/addUserToken',token)
        },
        resetUser: function(){
            this.$store.commit('user/resetUser')
        },
        addUserInstitution: function(institution_id){
            this.$store.commit('user/addUserInstitution', institution_id)
        }
    }
}
