

<template>
	<div>
		<section>
			<b-button
				:loading="isLoading"
				class="target-camera"
				@click="openTarget('camera')"
			>Tirar fotografia</b-button>
			<b-button
				:loading="isLoading"
				class="target-gallery"
				@click="openTarget('gallery')"
			>Escolher da galeria</b-button>
		</section>
	</div>
</template>

<script>
	/* eslint-disable no-undef */
	export default {
		name: "TakePicture",
		props: {},
		data() {
			return {
				types: {
					camera: Camera.PictureSourceType.CAMERA,
					gallery: Camera.PictureSourceType.SAVEDPHOTOALBUM,
				},
				isLoading: false,
			};
		},
		created() {},
		methods: {
			openTarget(srcType) {
				this.isLoading = true;
				let options = this.setOptions(this.types[srcType]);
				var self = this;
				navigator.camera.getPicture(
					function cameraSuccess(imageUri) {
						self.isLoading = false;
						return self.$emit("picture", imageUri);
					},
					function cameraError(error) {
						self.isLoading = false;
						return self.$emit("picture", "error");
					},
					options
				);
			},
			setOptions(srcType) {
				var options = {
					destinationType: Camera.DestinationType.DATA_URL,
					sourceType: srcType,
					encodingType: Camera.EncodingType.JPEG,
					mediaType: Camera.MediaType.PICTURE,
					// allowEdit: true,
					correctOrientation: true,
				};
				return options;
			},
		},
	};
</script>

<style>
	.target-gallery,
	.target-camera {
		width: 50%;
	}

	.target-gallery {
		border-top-left-radius: 0px !important;
		border-bottom-left-radius: 0px !important;
	}

	.target-camera {
		border-top-right-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
	}
</style>