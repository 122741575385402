<template>
	<div style="display: flex;">
		<ShareNetwork
			network="facebook"
			:url="link"
			:title="title"
			:description="description"
			:quote="title"
			:hashtags="hashtags"
		>
			<a @click.prevent class="button social-button button-facebook">
				<b-icon icon="facebook"></b-icon>
			</a>
		</ShareNetwork>

		<ShareNetwork
			network="twitter"
			:url="link"
			:title="title"
			:twitter-user="twitter_user"
			:description="description"
			:quote="title"
			:hashtags="hashtags"
		>
			<a @click.prevent class="button social-button button-twitter">
				<b-icon icon="twitter"></b-icon>
			</a>
		</ShareNetwork>

		<ShareNetwork
			network="whatsapp"
			:url="link"
			:title="title"
			:description="description"
			:quote="title"
			:hashtags="hashtags"
		>
			<a @click.prevent class="button social-button button-whatsapp">
				<b-icon icon="whatsapp"></b-icon>
			</a>
		</ShareNetwork>

		<a v-if="navigator_share" @click="share()" class="button social-button button-share">
			<b-icon icon="share-variant"></b-icon>
		</a>
	</div>
</template>

<script>

	export default {
		name: "ShareProduct",
		components: {
		},
		computed: {
			link() {
				return window.location.href;
			},
			url() {
				return window.location.href;
			},
		},
		props: {
			title: String,
			description: String,
		},
		data() {
			return {
				hashtags: "tobegreen",
				twitter_user: null,
				navigator_share: false,
			};
		},
		created() {
			if (navigator.share) this.navigator_share = true;
		},
		methods: {
			onError(a) {
				alert(a);
			},
			onSuccess(a) {
				alert(a);
			},
			share() {
				const data = {
					title: this.title,
					text: this.description,
					url: this.url,
				};

				if (navigator.share) {
					navigator
						.share(data)
						.then((succ) => {})
						.catch((error) => {
							console.log(error)
						});
				}
			},
		},
	};
</script>

<style lang="scss">
	@import "../../assets/sass/colors";

	.button-facebook {
		background-color: $facebook !important;
		margin-right: 1rem !important;
		color: $white;
	}

	.button-whatsapp {
		background-color: $whatsapp !important;
		margin-right: 1rem !important;
		color: $white;
	}

	.button-twitter {
		background-color: $twitter !important;
		margin-right: 1rem !important;
		color: $white;
	}

	.button-share {
		background-color: $dark !important;
		margin-right: 1rem !important;
		color: $white;
	}

	.social-button {
		color: white !important;
	}

	.social-button:hover {
		color: $white;
		opacity: 0.7;
	}

	.has-space {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}
</style>