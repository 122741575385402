import config from '../config/config'
import store from './../store'
import axios from 'axios'

const getHeaders = () => {
    if (store.getters['user/getUserToken']) {
        let token= store.getters['user/getUserToken']
        return {
            'Authorization': token
        }
    } 
}

export function post(uri, data) {
    return axios.post(endpoint(uri), data, {
        headers: getHeaders()
    })
}

export function put(uri, data) {
    return axios.put(endpoint(uri), data, {
        headers: getHeaders()
    })
}

export function remove(uri, data) {
    return axios.delete(endpoint(uri), {
        data,
       headers: getHeaders()
    })
}

export function get(uri) {
    return axios.get(endpoint(uri), {
        headers: getHeaders()
    })
}

export function endpoint(uri) {
    return process.env.VUE_APP_API_URL + uri
}

