<template>
    <div>
        <div class="modal" :class="{'is-active': zoomed}">
            <div class="modal-background" @click="setZoom"></div>
            <div class="modal-content m-image">
                <p class="image">
                    <img :src="checkImg(imgsrc)" @click="setZoom"
                        alt="">
                </p>
            </div>
            <button class="modal-close is-large" @click="setZoom"
                aria-label="close"></button>
        </div>
        <div>
            <img class="pending" @click="setZoom()" :src="checkImg(imgsrc)" />
        </div>
    </div>
    


</template>

<script>
    export default {

        name: "ImgZoom",
        data() {
            return {
                zoomed: false
            }
        },
        props: {
            imgsrc: {
                type: String,
                default: null
            }
        },
        methods: {
            setZoom() {

                this.zoomed = !this.zoomed;


            }
        }



    }
</script>

<style scoped lang="scss">
    @import "../../assets/sass/main";

    .m-image {
        max-height: 80vh;
    }

    .image>img {
        max-width: 100%;
        max-height: 80vh;
        height: auto;
        width: auto;
        margin: auto;
    }
</style>