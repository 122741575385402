<template>
	<div>
		<div class="columns">
			<div class="column">
				<a @click="$router.go(-1)" class="button is-text">
					<b-icon type="is-secondary-color" icon="chevron-left"></b-icon>
					<span>&nbsp; página anterior</span>
				</a>
			</div>
		</div>
		<div class="columns">
			<div class="column is-10 is-10-touch is-offset-1 is-offset-1-touch">
				<div class="columns">
					<!-- <div class="column">
            <button class="button is-primary-color">Exportar Dados</button>
					</div>-->
				</div>
				<div class="columns">
					<div class="column">
						<b-table
							paginated
							:per-page="perPage"
							mobile-cards
							:checked-rows.sync="checkedRows"
							checkable
							:data="data"
							:columns="columns"
						>
							<template slot-scope="props">
								<b-table-column field="id" label="ID" width="40" numeric>{{ props.row.id }}</b-table-column>
								<b-table-column field="name" label="Nome">{{ props.row.name }}</b-table-column>
								<b-table-column field="location.address" label="Morada">{{ props.row.location.address }}</b-table-column>
								<b-table-column field="water_id" label="Conta água">{{ props.row.water_id }}</b-table-column>
								<b-table-column field="type" label="Tipo Utilizador">{{ props.row.type }}</b-table-column>
								<b-table-column field="change" label>
									<a
										v-if="props.row.type=='user'"
										@click="change_user_type(props.row)"
										class="button is-primary-color"
									>Tornar Admin</a>
								</b-table-column>
							</template>
						</b-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import { get_users, complete_Data } from "./../api/user/user";

	export default {
		name: "UserMunicipality",
		created() {
			this.users();
			this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
		},
		components: {},
		data() {
			return {
				dropFiles: [],
				checkedRows: [],
				previewImage: null,
				color: null,
				perPage: 10,
				description: null,
				selectedGenre: null,
				selectedSize: null,
				selectedOrigin: null,
				data: [
					{
						id: 1,
						first_name: "Jesse",
						last_name: "Simmons",
						address: "Rua de Famalicão,235, ****-*** VNF",
						gender: "Male",
						water_bill: "123456789",
					},
					{
						id: 2,
						first_name: "John",
						last_name: "Jacobs",
						address: "Rua de Famalicão,235, ****-*** VNF",
						gender: "Male",
						water_bill: "123456789",
					},
					{
						id: 3,
						first_name: "Tina",
						last_name: "Gilbert",
						address: "Rua de Famalicão,235, ****-*** VNF",
						gender: "Female",
						water_bill: "123456789",
					},
					{
						id: 4,
						first_name: "Clarence",
						last_name: "Flores",
						address: "Rua de Famalicão,235, ****-*** VNF",
						gender: "Male",
						water_bill: "123456789",
					},
					{
						id: 5,
						first_name: "Anne",
						last_name: "Lee",
						address: "Rua de Famalicão,235, ****-*** VNF",
						gender: "Female",
						water_bill: "123456789",
					},
					{
						id: 6,
						first_name: "Anne",
						last_name: "Lee",
						address: "Rua de Famalicão,235, ****-*** VNF",
						gender: "Female",
						water_bill: "123456789",
					},
					{
						id: 7,
						first_name: "Anne",
						last_name: "Lee",
						address: "Rua de Famalicão,235, ****-*** VNF",
						gender: "Female",
						water_bill: "123456789",
					},
				],
				columns: [
					{
						field: "id",
						label: "ID",
						width: "40",
						numeric: true,
					},
					{
						field: "name",
						label: "Primeiro Nome",
						centered: true,
					},

					{
						field: "location.address",
						label: "Morada",
						centered: true,
					},
					// {
					//   field: "gender",
					//   label: "Gênero",
					//   centered: true
					// },
					{
						field: "water_id",
						label: "Conta Água",
						centered: true,
					},
					{
						field: "type",
						label: "Tipo Utilizador",
						centered: true,
					},
				],
			};
		},
		methods: {
			send() {},
			users() {
				let self = this;
				get_users()
					.then((response) => {
						self.data = response.data.body;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			change_user_type(info) {
				let user = info;
				user.type = "admin";

				complete_Data(user)
					.then((response) => {
						//SELF
						this.$buefy.toast.open({
							message: "Atualizado efetuado com sucesso!",
							type: "is-success",
							position: "is-bottom",
						});
					})
					.catch((error) => {
						console.log(error);
						//SELF
						this.$buefy.toast.open({
							message: "Não foi possível atualizar!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
		},
	};
</script>
