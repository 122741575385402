    <template>
      <div>
        <gmap-map :center="center" :zoom="zoom" style="width: 100%; height: 500px">

          <gmap-marker @dragend="dragEnd" v-for="(m, index) in markers" :position="m.position" :clickable="true"
            :draggable="m.draggable" @click="clickMarker(m)" :key="index"></gmap-marker>

        </gmap-map>
      </div>
    </template>

    <script>
      import * as VueGoogleMaps from "vue2-google-maps";
      // import MapInfoWindow from "../../components/Map/MapInfoWindow";
      // import MapPanel from "../../components/Map/MapPanel";
      export default {
        name: "MapView",
        props: {
          center: {
            lat: {
              type: Number,
              default: 0
            },
            lng: {
              type: Number,
              default: 0
            }
          },
          markers: {
            type: Array,
            default: function () {
              return [];
            }
          },
          zoom: {
            type: Number,
            default: 9
          }
        },
        watch: {
          "center.lat"() {
            this.center.lat = parseFloat(this.center.lat)
          },
          "center.lng"() {
            this.center.lng = parseFloat(this.center.lng)
          }
        },
        data() {
          return {
            // center: {
            //   lat: 10.0,
            //   lng: 10.0
            // },
            // markers: [{
            //     position: {
            //       lat: 10.0,
            //       lng: 10.0
            //     }
            //   },
            //   {
            //     position: {
            //       lat: 11.0,
            //       lng: 11.0
            //     }
            //   }
            // ]
          };
        },
        created() {},
        mounted() {},
        methods: {
          dragEnd(e) {
            this.$emit("changemarker", {
              lat: e.latLng.lat(),
              lng: e.latLng.lng()
            })
          },
          clickMarker(m) {
            this.$emit("clickmarker", m.obj)
          }
        }
      };
    </script>

    <style scoped>
      .map-size {
        width: 100%;
        height: 400px;
      }
    </style>