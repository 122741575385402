// initial state
const state = {
    mapOpen: false
}

// getters
const getters = {
    getmapOpen(state, getters) {
        return state.mapOpen
    }

}

// mutations
const mutations = {
    resetStore(s) {
        const initial = JSON.parse(JSON.stringify(state));
        Object.keys(initial).forEach(key => {
            s[key] = initial[key]
        });
    },
    changemapOpen (state,boolean) {
        state.mapOpen =  !state.mapOpen
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}
