<template>
	<div>
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>

		<div class="columns">
			<div class="column">
				<a @click="$router.go(-1)" class="button is-text">
					<b-icon type="is-secondary-color" icon="chevron-left"></b-icon>
					<span>&nbsp; página anterior</span>
				</a>
			</div>
		</div>

		<div v-if="product.product" class="columns">
			<!-- <div class="container"> -->
			<div class="column is-half">
				<!-- <img class="product img" :src="product.product.img" alt="Placeholder image" /> -->

				<b-carousel
					animated="fade"
					:indicator-custom="true"
					:indicator-inside="false"
					:autoplay="false"
				>
					<b-carousel-item v-for="(img, i) in product.product.imgs" :key="i">
						<figure class="image is-4by3">
							<img class="product-image" :src="img" />
						</figure>
					</b-carousel-item>
					<template slot="indicators" slot-scope="props">
						<figure class="image">
							<img
								class="product-image custom-indicator"
								:src="product.product.imgs[props.i]"
								:title="props.i"
							/>
						</figure>
					</template>
				</b-carousel>
			</div>
			<div class="column is-half desc">
				<!-- <h1 class="title">Camisola Branca</h1> -->
				<h3 class="title is-3 has-text-dark">{{ product.product.description }}</h3>

				<h5 class="title is-5">{{ product.product.genre }}</h5>

				<h5 class="title is-5">
					Tamanho:
					<span class="subtitle is-6">
						{{
						product.product.size
						}}
					</span>
				</h5>

				<h5 class="title is-5">
					Cor:
					<span class="subtitle is-6">
						{{
						product.product.color
						}}
					</span>
				</h5>

				<h5 class="title is-5">
					Descrição:
					<span class="subtitle is-6">
						{{
						product.product.description
						}}
					</span>
				</h5>

				<h5 class="title is-5">
					Distrito de Entrega:
					<span class="subtitle is-6">
						{{
						product.location.district
						}}
					</span>
				</h5>

				<h5
					v-if="
						getAppSelectedType == 'institution' &&
						user.institutions[0] &&
						user.institutions[0]._id
					"
					class="title is-5"
				>
					Valor:
					<span class="subtitle is-5 has-text-secondary-color">
						{{
						product.price.regularized
						? product.price.not_regularized
						: product.price.not_regularized
						}}€
					</span>
				</h5>

				<h5 v-else class="title is-5">
					Valor:
					<span class="subtitle is-5 has-text-secondary-color">{{ product.points }} Pontos</span>
				</h5>

				<ShareProduct
					v-if="
						product.status != 'bought' &&
						product.status != 'recycled'
					"
					:title="product.product.description"
					:description="product.product.description"
				/>
				<br />
				<div class="columns is-desktop">
					<div class="column is-3 center">
						<b-button
							v-if="
								user.type == 'admin' ||
								(user.type == 'glad' && authorized_actions)
							"
							@click="setEditProductOpen(true)"
							class="button is-secondary-color btn"
						>Editar</b-button>
					</div>
					<div class="column is-3 center">
						<b-button
							v-if="
								user.type == 'admin' ||
								(user.type == 'glad' && authorized_actions)
							"
							@click="disable(product._id)"
							class="button is-danger btn"
						>Remover</b-button>
					</div>
					<div v-if="user.type != 'admin' && user.type != 'glad'" class="column is-3 center">
						<b-button
							v-if="
								!inCart &&
								getUserToken &&
								product.status != 'bought' &&
								product.status != 'recycled'
							"
							class="is-primary-color"
							@click="add(product)"
						>Adicionar ao carrinho</b-button>
						<b-button
							v-if="
								inCart &&
								getUserToken &&
								product.status != 'bought' &&
								product.status != 'recycled'
							"
							class="is-danger"
							@click="remove(product)"
						>Remover do carrinho</b-button>
					</div>
					<div class="column is-3"></div>
				</div>
			</div>
		</div>

		<EditProduct
			v-on:productupdate="productUpdate"
			:product_edit="product_edit"
			v-if="isEditProductOpen"
			:class="{ 'is-active': isEditProductOpen }"
		/>
	</div>
	<!-- </div> -->
</template>


<script>
	//Mixins
	import { cart } from "./../mixins/modules/cart";
	import { product } from "@/mixins/modules/product";

	//API
	import {
		getProduct,
		disableProduct,
		editProduct,
	} from "./../api/products/products";

	import Filters from "./../config/Filters";
	import EditProduct from "./product/EditProduct";
	import ShareProduct from "./product/ShareProduct";

	export default {
		name: "Product",
		mixins: [cart, product],
		components: {
			EditProduct,
			ShareProduct,
		},
		data() {
			return {
				inCart: false,
				isLoading: false,
				fullPage: true,
				user: null,
				productID: null,
				product: {},
				authorized_actions: false,
				product_edit: {},
				selectTypeMen: Filters["selectTypeMen"],
				selectTypeWomen: Filters["selectTypeWomen"],
				selectGenre: Filters["selectGenre"],
				selectSize: Filters["selectSize"],
				modelSizeMenWomen: Filters["modelSizeMenWomen"],
				modelSizeMenWomen2: Filters["modelSizeMenWomen2"],
				modelSizeChildren: Filters["modelSizeChildren"],
				modelSizeChildrenBaby: Filters["modelSizeChildrenBaby"],
				modelSizeSuitsJackets: Filters["modelSizeSuitsJackets"],
				modelSizeWomen: Filters["modelSizeWomen"],
				selectType2: [
					{
						text: "Rapaz",
						value: "Rapaz",
					},
					{
						text: "Rapariga",
						value: "Rapariga",
					},
					{
						text: "Bebé",
						value: "Bebé",
					},
				],
				selectType: null,
				selectOrigin: Filters["districts"],
			};
		},
		created() {
			// this.scrollToTop();
			this.user = this.getUserInfo;
			this.productID = this.$route.params.id;
			this.getProduct(this.productID);

			// if (this.user._id == this.product.user._id)
			//   this.authorized_actions = true;

			if (
				this.user.type == "glad" &&
				(this.user.institutions[0]._id == this.product.institution ||
					((this.user.institutions.length == 0 ||
						this.user.institutions == null ||
						typeof this.user.institutions === "undefined") &&
						this.user.location.municipality ==
							this.product.location.municipality))
			)
				this.authorized_actions = true;

			this.inCart = this.isInCart(this.getAppSelected._id, this.productID);
		},

		methods: {
			getProduct(id) {
				this.isLoading = true;
				getProduct(id)
					.then((response) => {
						if (response.status === 200) {
							this.product = response.data.body;
							this.product_edit = JSON.parse(
								JSON.stringify(this.product)
							);
						}
						this.isLoading = false;
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;
					});
			},
			add(product) {
				this.addToCart(this.getAppSelected._id, product);
				this.inCart = true;
				this.$buefy.toast.open({
					message: "Adicionado ao carrinho!",
					type: "is-success",
					position: "is-bottom",
				});
			},
			productUpdate(_product) {
				this.product = _product;
				this.product_edit = _product;
			},
			disable(product_id) {
				disableProduct(product_id)
					.then((response) => {
						if (response.status === 200) {
							this.$buefy.toast.open({
								message: "Artigo removido!",
								type: "is-success",
								position: "is-bottom",
							});
						}
						this.isLoading = false;
						this.removeProduct(product_id);
						this.$router.go(-1);
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: "Não foi possível eliminar o artigo!",
							type: "is-danger",
							position: "is-bottom",
						});
						this.isLoading = false;
					});
			},
			remove(product) {
				this.removeFromCart(this.getAppSelected._id, product._id);
				this.inCart = false;
				this.$buefy.toast.open({
					message: "Removido do carrinho!",
					type: "is-success",
					position: "is-bottom",
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../assets/sass/main";

	.btn {
		width: 85px;
	}

	.center {
		text-align: center;
	}

	.desc {
		margin-top: 3.5rem;
		margin-left: 2rem;
		margin-right: 2rem;
	}

	.button.add {
		/* position: absolute;
					right: 12vw;
					margin-bottom: 2rem; */
	}
</style>