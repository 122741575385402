import Vue from 'vue'
import VueRouter from 'vue-router'
import ComercialPage from '../views/ComercialPage.vue'
import Login from '../views/Login'
import ActivateAccount from '../views/ActivateAccount.vue'
import Product from '../components/Product.vue'
import Order from '../views/Order.vue'
import Cart from '../views/Cart.vue'
// import Delivery from '../views/Delivery.vue'
import Process from '../views/Process.vue'
import UserMunicipality from '../views/UserMunicipality.vue'
import Profile from '../views/Profile.vue'
import Orders from '../views/Orders.vue'
// import Backoffice from '../views/Backoffice.vue'
import InviteInstitution from '../views/InviteInstitution'
import InvitePartner from '../views/InvitePartner';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import MyRights from '../views/MyRights.vue';
import CookiesPolicy from '../views/CookiesPolicy.vue';
import GuardianAccept from "../views/GuardianAccept.vue";
import Main from '../views/Main';

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'ComercialPage',
		component: ComercialPage
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			redirectToApp: true
		}
	},
	{
		path: '/register',
		name: 'Register',
		component: Login
	},
	{
		path: '/recovery',
		name: 'Recovery',
		component: Login
	},
	{
		path: '/recover_password',
		name: 'RecoverPassword',
		component: Login
	},
	{
		path: '/app/shared',
		name: 'SharedProducts',
		component: Main,
		meta: {
			canAuth: true
		}
	},
	{
		path: '/app/myproducts',
		name: 'MyProducts',
		component: Main,
		meta: {
			canAuth: true
		}
	},
	{
		path: '/app/pending',
		name: 'PendingProducts',
		component: Main,
		meta: {
			canAuth: true
		}
	},
	{
		path: '/app/orders',
		name: 'OrdersProducts',
		component: Main,
		meta: {
			canAuth: true
		}
	},
	{
		path: '/app/recycling',
		name: 'RecycledProducts',
		component: Main,
		meta: {
			canAuth: true
		}
	},
	{
		path: '/product/:id',
		name: 'Product',
		component: Product,
		meta: {
			canAuth: true
		}
	},
	{
		path: '/order/:id',
		name: 'Order',
		component: Order,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/cart',
		name: 'Cart',
		component: Cart,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/users/:id/guardian',
		name: 'GuardianAccept',
		component: GuardianAccept,
	},
	// {
	// 	path: '/new_delivery',
	// 	name: 'NewDelivery',
	// 	component: Delivery,
	// 	meta: {
	// 		requiresAuth: true
	// 	}
	// },
	{
		path: '/order',
		name: 'Process',
		component: Process,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/user_municipality',
		name: 'UserMunicipality',
		component: UserMunicipality,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/offline',
		name: 'Offline',
		component: () => import( /* webpackChunkName: "offline" */ '../views/404.vue')
	},
	{
		path: '/profile',
		name: 'Profile',
		component: Profile,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/orders',
		name: 'Orders',
		component: Orders,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/backoffice/institutions',
		name: 'Backoffice-Institutions',
		component: () => import( /* webpackChunkName: "backoffice" */ '../views/Backoffice.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/backoffice/glad',
		name: 'Backoffice-Glad',
		component: () => import( /* webpackChunkName: "backoffice" */ '../views/Backoffice.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/backoffice/places',
		name: 'Backoffice-Places',
		component: () => import( /* webpackChunkName: "backoffice" */ '../views/Backoffice.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/backoffice/partners',
		name: 'Backoffice-Partners',
		component: () => import( /* webpackChunkName: "backoffice" */ '../views/Backoffice.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/backoffice/info',
		name: 'Backoffice-Info',
		component: () => import( /* webpackChunkName: "backoffice" */ '../views/Backoffice.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/activate/:id',
		name: 'ActivateAccount',
		component: ActivateAccount
	},
	{
		path: '/institutions/:id',
		name: 'InviteInstitution',
		component: InviteInstitution
	},
	{
		path: '/partners/:id',
		name: 'InvitePartner',
		component: InvitePartner
	},
	{
		path: '/policy/privacy',
		name: 'PrivacyPolicy',
		component: PrivacyPolicy
	},
	{
		path: '/policy/rights',
		name: 'MyRights',
		component: MyRights
	},
	{
		path: '/policy/cookies',
		name: 'CookiesPolicy',
		component: CookiesPolicy
	},
	{
		path: '*',
		name: '404',
		meta: {
			redirectToHome: true
		}
	}
]

const router = new VueRouter({
	mode: process.env.VUE_APP_ROUTER_MODE,
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash
			}
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
})

import redirectToApp from './guards/redirectToApp';
import requiresAuth from './guards/requiresAuth';
import canAuth from './guards/canAuth';
import redirectToHome from './guards/redirectToHome';

router.beforeEach((to, from, next) => {
	const _requiresAuth = to.matched.some(record => record.meta.requiresAuth)
	const _canAuth = to.matched.some(record => record.meta.canAuth)
	const _redirectToApp = to.matched.some(record => record.meta.redirectToApp)
	const _redirectToHome = to.matched.some(record => record.meta.redirectToHome)

	if (_requiresAuth)
		requiresAuth(to, from, next, router);
	else if (_canAuth)
		canAuth(to, from, next, router);
	else
		next();

	if (_redirectToHome)
		redirectToHome(to, from, next, router);

	if (_redirectToApp)
		redirectToApp(to, from, next, router);

})

export default router