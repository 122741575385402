var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title is-4"},[_vm._v("Alterar os meus dados pessoais")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Nome"}},[_c('ValidationProvider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","name":"name"},model:{value:(_vm.user.info.name),callback:function ($$v) {_vm.$set(_vm.user.info, "name", $$v)},expression:"user.info.name"}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Email"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","name":"email"},model:{value:(_vm.user.auth.email),callback:function ($$v) {_vm.$set(_vm.user.auth, "email", $$v)},expression:"user.auth.email"}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Nº Cliente Serviços Água/Resíduos"}},[_c('b-input',{attrs:{"type":"text","name":"waterID"},model:{value:(_vm.user.info.water_id),callback:function ($$v) {_vm.$set(_vm.user.info, "water_id", $$v)},expression:"user.info.water_id"}})],1),_c('b-field',{attrs:{"label":"Contacto"}},[_c('ValidationProvider',{attrs:{"name":"Contacto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","name":"contact"},model:{value:(_vm.user.info.contact),callback:function ($$v) {_vm.$set(_vm.user.info, "contact", $$v)},expression:"user.info.contact"}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Data de Nascimento"}},[_c('ValidationProvider',{attrs:{"name":"Género","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-datepicker',{attrs:{"show-week-number":false,"placeholder":"Clica para selecionar","max-date":_vm.maxDate},model:{value:(_vm.user.info.birthday),callback:function ($$v) {_vm.$set(_vm.user.info, "birthday", $$v)},expression:"user.info.birthday"}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"block"},[_c('label',{staticClass:"label"},[_vm._v("Género")]),_c('b-radio',{attrs:{"type":"is-dark","name":"genre","native-value":"F"},model:{value:(_vm.user.info.genre),callback:function ($$v) {_vm.$set(_vm.user.info, "genre", $$v)},expression:"user.info.genre"}},[_vm._v("Feminino")]),_c('b-radio',{attrs:{"type":"is-dark","name":"genre","native-value":"M"},model:{value:(_vm.user.info.genre),callback:function ($$v) {_vm.$set(_vm.user.info, "genre", $$v)},expression:"user.info.genre"}},[_vm._v("Masculino")])],1),_c('div',{staticClass:"block"},[_c('label',{staticClass:"label"},[_vm._v("Deseja receber as comunicações via:")]),_c('b-checkbox',{attrs:{"type":"primary-color"},model:{value:(_vm.user.communication),callback:function ($$v) {_vm.$set(_vm.user, "communication", $$v)},expression:"user.communication"}},[_vm._v(" Email ")])],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Morada"}},[_c('ValidationProvider',{attrs:{"name":"Morada","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","name":"address"},model:{value:(_vm.user.info.location.address),callback:function ($$v) {_vm.$set(_vm.user.info.location, "address", $$v)},expression:"user.info.location.address"}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1),_c('b-field',{attrs:{"label":"País"}},[_c('ValidationProvider',{attrs:{"name":"País","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"disabled":_vm.countryDisabled,"name":"origin","expanded":""},on:{"input":function($event){return _vm.getDistricts()}},model:{value:(_vm.countrySelected),callback:function ($$v) {_vm.countrySelected=$$v},expression:"countrySelected"}},_vm._l((_vm.countries),function(option,i){return _c('option',{key:i,domProps:{"value":option}},[_vm._v(" "+_vm._s(option.country)+" ")])}),0),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Distrito"}},[_c('ValidationProvider',{attrs:{"name":"Distrito","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"disabled":_vm.districtDisabled,"name":"origin","expanded":""},on:{"input":function($event){return _vm.getMunicipalities()}},model:{value:(_vm.districtSelected),callback:function ($$v) {_vm.districtSelected=$$v},expression:"districtSelected"}},_vm._l((_vm.districts),function(option,i){return _c('option',{key:i,domProps:{"value":option}},[_vm._v(" "+_vm._s(option.district_name)+" ")])}),0),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Município"}},[_c('ValidationProvider',{attrs:{"name":"Município","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"disabled":_vm.municipalityDisabled,"name":"origin","expanded":""},model:{value:(_vm.municipalitySelected),callback:function ($$v) {_vm.municipalitySelected=$$v},expression:"municipalitySelected"}},_vm._l((_vm.municipalities),function(option,i){return _c('option',{key:i,domProps:{"value":option}},[_vm._v(" "+_vm._s(option.municipality_name)+" ")])}),0),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)],1)]),_c('b-button',{staticClass:"is-primary-color",attrs:{"loading":_vm.isLoading},on:{"click":function($event){return handleSubmit(_vm.add)}}},[_vm._v(" Atualizar ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }