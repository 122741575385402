import {
    get,
    post,
    remove,
    put
} from "../../utils/http"

const prefix = '/places'


export function getPlaces(query) {
    if(!query) query = "";
    let uri = `${prefix}${query}`
    return get(uri)
}

export function createPlace(data) {
    let uri = prefix + '/'
    return post(uri, data)
}

export function updatePlace(id, data) {
    let uri = prefix + '/' + id
    return put(uri, data)
}

export function removePlace(id) {
    let uri = prefix + '/' + id
    return remove(uri)
}

export function getCoordinates(place) {

    let uri = `${prefix}/coordinates?text=${place}`
    return get(uri);

}