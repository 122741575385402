<template>
	<div class="app">
		<div class="nav" v-if="showNav">
			<NavBar v-if="isHome" />
			<div v-else>
				<AppNavBar />
			</div>
		</div>
		<div class="content-wrap">
			<FormCompleteRegister
				v-if="getUserInfo.isRegistered == false && $store.getters['user/getUserToken']"
				:modalOpen="true"
			/>
			<router-view v-else />
		</div>

		<div id="footer" class="columns">
			<Footer
				:class="$route.name =='SharedProducts' || $route.name=='MyProducts' ? 'margin-left' : ''"
				class="io-footer"
			/>
		</div>
	</div>
</template>

<script>
	import { tokenAuth } from "./api/auth/login";
	import NavBar from "./components/Parts/NavBar";
	import AppNavBar from "./components/Parts/AppNavBar";
	import FormCompleteRegister from "./components/Forms/FormCompleteRegister";
	import Footer from "./components/Parts/Footer";

	import { registerInstall } from "./api/info/info.js";

	import { notifications } from "./mixins/modules/notifications";

	export default {
		mixins: [notifications],
		components: {
			NavBar,
			AppNavBar,
			FormCompleteRegister,
			Footer,
		},
		created() {
			addEventListener("appinstalled", (evt) => {
				registerInstall();
			});

			this.$store.commit("navbar/resetStore");

			this.hideNav();
			this.chooseNav();
			
			if (process.env.NODE_ENV === "production")
				window.addEventListener("error", (event) => {
					console.log(event);
				});

			addEventListener("resize", () => {
				this.updateWidth(innerWidth);
			});

			if (process.env.CORDOVA_PLATFORM) {
				this.$router.push({ name: "Login" });
			}

			 this.$ga.enable();
		},
		data() {
			return {
				showNav: false,
				isHome: true,
			};
		},
		watch: {
			$route(to, from) {
				this.hideNav();
				this.chooseNav();
			},
		},
		methods: {
			hideNav() {
				if (
					this.$route.path == "/login" ||
					this.$route.path == "/register" ||
					this.$route.path == "/recovery" ||
					this.$route.path == "/recover_password" ||
					this.$route.name == "ActivateAccount" ||
					this.$route.name == "InviteInstitution"
				) {
					this.showNav = false;
				} else this.showNav = true;
			},
			chooseNav() {
				if (this.$route.path == "/") this.isHome = true;
				else this.isHome = false;
			},
		},
		sockets: {
			connect() {
				if (this.$store.getters["user/getUserToken"]) {
					this.$socket.emit("get_notifications", this.getUserID);
				}
			},
			get_notifications(notifications) {
				this.saveNotifications(notifications);
			},
			new_notification(notification) {
				this.pushNotification(notification);
				this.setNotificationsOpen(true);
			},
		},
	};
</script>


<style lang="scss">
	@import "./assets/sass/main.scss";

	.app {
		font-family: 'Nunito Sans', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;

		min-height: 100vh;
		position: relative;
	}

	.nav {
		padding: 25px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}

	.fixed {
		position: fixed;
		width: 100%;
	}

	.content-wrap {
		padding-bottom: 188px;
	}

	#footer {
		// bottom: -100px;
		bottom: -5px;
		position: absolute;
		width: 101%;
		height: 200px;
	}

	.io-footer {
		width: 100%;
	}
	@media only screen and (min-width: 769px) {
		.margin-left {
			margin-left: 260px;
		}
	}

	.login section {
		background: url(./assets/img/7.png) left no-repeat;
		background-color: #e4e4e4;
		background-attachment: fixed;
		background-size: cover;
	}
</style>