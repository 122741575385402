<template>
  <div>
    <div class="columns">
      <div class="column sidebar is-2 is-narrow-mobile is-fullheight section">
        <aside class="menu">
          <!-- <p class="menu-label">General</p> -->
          <ul class="menu-list">
            <li>
              <a :class="{'active': selectedMenu == 'Profile'}" @click="changeMenu('Profile')">Perfil</a>
            </li>
            <li>
              <a :class="{'active': selectedMenu == 'Password'}" @click="changeMenu('Password')">Alterar Password</a>
            </li>
            <li v-if="getUserInfo.type == 'user'">
              <a :class="{'active': selectedMenu == 'ShoppingHistory'}" @click="changeMenu('ShoppingHistory')">Histórico
                de compras</a>
            </li>
            <li>
              <a :class="{'active': selectedMenu == 'Contact'}" @click="changeMenu('Contact')">Contacte-nos</a>
            </li>
          </ul>
        </aside>
      </div>
      <div class="column is-8-desktop is-offset-1-desktop is-10-touch is-offset-1-mobile section">
        <profile :user="getUserInfo" v-if="selectedMenu==='Profile'"></profile>
        <password v-if="selectedMenu==='Password'"></password>
        <shoppingHistory v-if="getUserInfo.type == 'user'" v-show="selectedMenu==='ShoppingHistory'"></shoppingHistory>
        <contact v-if="selectedMenu==='Contact'"></contact>
      </div>
    </div>
  </div>
</template>

<script>

  /**Components */
  import profile from "@/components/Profile/profile.vue";
  import password from "@/components/Profile/password.vue";
  import shoppingHistory from "@/components/Profile/shoppingHistory.vue";
  import contact from "@/components/Profile/contact.vue";

  export default {
    name: "ProfilePage",
    components: {
      profile,
      password,
      shoppingHistory,
      contact
    },
    mixins: [],
    data() {
      return {
        selectedMenu: "Profile"
      };
    },
    watch: {},
    methods: {
      changeMenu(selectedMenu) {
        this.selectedMenu = selectedMenu;
      }
    },
    created: function () {
      this.selectedMenu = "Profile";
    }
  };
</script>
<style scoped lang="scss">
  @import "../assets/sass/main";

  .active {
    background-color: $primary-color !important;
    color: white !important;
  }

  @media only screen and (min-width: 769px) {
    .sidebar {
      background-color: #f5f5f5;
      width: 13%;
      min-height: 100vh;
    }
  }
</style>