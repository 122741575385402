// initial state
const state = {
    selectedPlace: {},
    selectedPlaceID: null
}

// getters
const getters = {
    getSelectedPlace(state, getters) {
        return state.selectedPlace
    },
    getSelectedPlaceID(state) {
        return state.selectedPlaceID
    }

}

// mutations
const mutations = {
    resetStore(s) {
        const initial = JSON.parse(JSON.stringify(state));
        Object.keys(initial).forEach(key => {
            s[key] = initial[key]
        });
    },
    setSelectedPlace(state, place) {
        if (place === '') {
            state.selectedPlace = {};
            state.selectedPlaceID = null;
        } else {
            state.selectedPlace = place;
            state.selectedPlaceID = place._id;

        }
    },
    resetProcess(state) {
        state.selectedPlace = {}
        state.selectedPlaceID = null
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}