<template>
	<div>
		<b-field label="País">
			<b-select
				:disabled="countryDisabled"
				@input="getDistricts()"
				v-model="countrySelected"
				name="origin"
				expanded
				placeholder="Selecione o país de entrega"
			>
				<option :key="i" v-for="(option, i) in countries" :value="option">
					{{ option.country }}
				</option>
			</b-select>
		</b-field>

		<b-field label="Distrito">
			<b-select
				@input="getMunicipalities()"
				:disabled="districtDisabled"
				v-model="districtSelected"
				name="origin"
				expanded
				placeholder="Selecione a distrito de entrega"
			>
				<option :key="i" v-for="(option, i) in districts" v-bind:value="option">
					{{ option.district_name }}
				</option>
			</b-select>
		</b-field>

		<b-field label="Município">
			<b-select
				@input="getCoordinates"
				:disabled="municipalityDisabled"
				v-model="municipalitySelected"
				name="origin"
				expanded
				placeholder="Selecione o município de entrega"
			>
				<option :key="i" v-for="(option, i) in municipalities" v-bind:value="option">
					{{ option.municipality_name }}
				</option>
			</b-select>
		</b-field>

		<b-loading :active.sync="isLoading"></b-loading>

		<div v-if="getFilteredPlaces.length <= 0">
			<b-message v-if="municipalitySelected">
				Não foram encontrados locais de entrega neste município!
			</b-message>
		</div>
		<div v-else>
			<b-field label="Local de entrega">
				<b-select
					@input="changeCenter(placeSelected)"
					:disabled="placeDisabled"
					v-model="placeSelected"
					expanded
					placeholder="Selecione o local de entrega"
				>
					<option :key="i" v-for="(option, i) in getFilteredPlaces" :value="option">
						{{ option.name }}
					</option>
				</b-select>
			</b-field>
			<br />
			<b-message v-if="placeSelected">
				<b>Nome: </b> {{ placeSelected.name }} <br />
				<b>Morada: </b> {{ placeSelected.address }} <br />
				<b>Código postal: </b> {{ placeSelected.zip_code }} <br />
				<b>Coordenadas: </b> {{ placeSelected.coordinates.lat }} |
				{{ placeSelected.coordinates.lng }} <br />
			</b-message>
			<br />
			<MapView
				v-if="mapEnabled && showMap"
				:zoom="13"
				@clickmarker="changeCenter"
				:center="coordinates"
				:markers="markers"
			/>
		</div>
	</div>
</template>

<script>
	import { getPlaces, getCoordinates } from "./../../api/places/places";

	import Countries from "@/config/Countries.json";
	import Districts from "@/config/Districts.json";
	import Municipalities from "@/config/Municipality.json";
	import MapView from "../Map/MapView";

	import { lastLocation } from "../../mixins/modules/lastLocation";

	export default {
		name: "ChoosePlace",
		components: {
			MapView,
		},
		mixins: [lastLocation],
		props: {
			showMap: {
				type: Boolean,
				default: true,
			},
		},
		created() {
			this.getPlaces();
			let lastLocation = this.getLastLocation;
			if (lastLocation) {
				this.countrySelected = lastLocation.countrySelected;
				this.districtSelected = lastLocation.districtSelected;
				this.municipalitySelected = lastLocation.municipalitySelected;
				this.placeSelected = lastLocation.placeSelected;
				this.getDistricts(true);
				this.getMunicipalities(true)
				this.getCoordinates();
				this.changeCenter(this.placeSelected)
			}
		},
		data() {
			return {
				districts: [],
				municipalities: [],
				municipalityDisabled: true,
				municipalitySelected: null,
				districtDisabled: true,
				districtSelected: null,
				countries: Countries,
				countrySelected: null,
				countryDisabled: false,
				addressDisabled: true,
				mapEnabled: false,
				isLoading: false,
				coordinates: null,
				isLoadingMap: false,
				places: [],
				placeSelected: null,
				placeDisabled: true,
				markers: [],
			};
		},
		methods: {
			getDistricts(not_null) {
				this.districts = [];
				if (!not_null) this.districtSelected = null;
				for (let m of Districts) {
					if (this.countrySelected.country_id == m.country_id)
						this.districts.push(m);
				}
				this.districtDisabled = false;
			},
			getMunicipalities(not_null) {
				this.municipalities = [];
				if(!not_null)
				this.municipalitySelected = null;
				for (let m of Municipalities) {
					console.log();

					if (this.districtSelected.district_code == m.district_code)
						this.municipalities.push(m);
				}
				this.municipalityDisabled = false;
			},
			getPlaces() {
				// this.getCoordinates();
				// if (this.municipalitySelected.municipality_name && this.districtSelected
				//     .district_name && this.countrySelected.country) {
				this.isLoading = true;
				// this.placeSelected = null;
				// this.$emit("placeselected", null);
				let query = "";
				// `?country=${this.countrySelected.country}&district=${this.districtSelected.district_name}&municipality=${this.municipalitySelected.municipality_name}`;

				getPlaces(query)
					.then((response) => {
						this.places = response.data.body;
						this.placeDisabled = false;
						this.isLoading = false;
					})
					.catch((error) => {
						console.log(error)
						this.isLoading = false;
					});
				// }
			},
			getCoordinates() {
				if (
					this.municipalitySelected.municipality_name &&
					this.districtSelected.district_name &&
					this.countrySelected.country
				) {
					let place =
						this.municipalitySelected.municipality_name +
						" " +
						this.districtSelected.district_name +
						" " +
						this.countrySelected.country;
					getCoordinates(place)
						.then((response) => {
							this.coordinates =
								response.data.candidates[0].geometry.location;
							this.mapEnabled = true;
						})
						.catch((error) => {
							console.log(error)
							this.mapEnabled = false;
						});
				}
			},
			changeCenter(m) {
				if (m != null) this.coordinates = m.coordinates;
				this.placeSelected = m;
				this.$emit("placeselected", m);
				this.saveLastLocation({
					countrySelected: this.countrySelected,
					districtSelected: this.districtSelected,
					municipalitySelected: this.municipalitySelected,
					placeSelected: this.placeSelected
				});
			},
		},
		computed: {
			getFilteredPlaces() {
				if (
					this.municipalitySelected &&
					this.districtSelected &&
					this.countrySelected
				) {
					let filtered_p = this.places.filter(
						(e) =>
							e.country == this.countrySelected.country &&
							e.district == this.districtSelected.district_name &&
							e.municipality ==
								this.municipalitySelected.municipality_name
					);

					for (let i of filtered_p) {
						if (i.coordinates && i.coordinates.lat && i.coordinates.lng)
							// eslint-disable-next-line vue/no-side-effects-in-computed-properties
							this.markers.push({
								position: i.coordinates,
								draggable: false,
								obj: i,
							});
					}
					// this.mapEnabled = true;
					return filtered_p;
				} else return [];
			},
		},
	};
</script>