
export const map = {
    computed: {
        mapOpen() {
            return this.$store.getters['map/getmapOpen']
        }
    },
    methods: {
        changemapOpen: function () {
            this.$store.commit('map/changemapOpen')
        }
    }
}
