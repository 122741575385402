// initial state
const state = {
    currentApp: {
        _id: 'app'
    },
    currentAppType: null,
    query: {
        shared: {},
        myproducts: {}
    }
}

// getters
const getters = {
    getAppSelected(state, getters) {
        return state.currentApp;
    },
    getAppSelectedType(state, getters) {
        return state.currentAppType;
    },
    getCurrentTab(state, getters) {
        return state.currentTab;
    },
    getQueryShared(state) {
        return state.query.shared
    },
    getQueryMyProducts(state) {
        return state.query.myproducts
    }

}

// mutations
const mutations = {
    resetStore(s) {
        const initial = JSON.parse(JSON.stringify(state));
        Object.keys(initial).forEach(key => {
            s[key] = initial[key]
        });
    },
    changeAppSelected(state, app) {
        state.currentApp = app;
    },
    changeAppSelectedType(state, app) {
        state.currentAppType = app;
    },
    setQueryShared(state, query) {
        state.query.shared = query;
    },
    setQueryMyProducts(state, query) {
        state.query.myproducts = query;
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}