import {
    get,
    post,
    remove,
    put
} from "./../../utils/http"



const prefix = '/users'

export function register(data) {
    let uri = `${prefix}`
    return post(uri, data)
}

export function complete_Data(data) {
    let uri = `${prefix}/registration`
    return put(uri, data)
}

export function updateProfile(data) {
    let uri = `${prefix}/${data._id}/profile`
    return put(uri, data)
}


export function change_user_type(data) {
    let uri = `${prefix}`
    return post(uri, data)
}

export function get_users() {
    let uri = `${prefix}`
    return get(uri)
}


export function get_glads() {
    let uri = `${prefix}` + '/glad'
    return get(uri)
}

export function createGlad(data) {
    let uri = '/users/glad/'
    return post(uri, data)
}

export function updateGlad(id, data) {
    let uri = '/users/glad/' + id
    return put(uri, data)
}

export function removeGlad(id) {
    let uri = '/users/glad/' + id
    return remove(uri)
}


export function get_user_info(user__id) {
    let uri = `${prefix}/${user__id}`
    return get(uri)
}


export function change_password(data) {
    let uri = `${prefix}/password`
    return put(uri, data)
}


export function recover_password_email(email) {
    let uri = `${prefix}/password/recover`;
    let data = {
        email: email
    };
    return post(uri, data)
}

export function recover_password_key(recovery_key, password) {
    let uri = `${prefix}/password/recover/` + recovery_key;
    let data = {
        password: password
    };
    return put(uri, data)
}

export function associateInstitution(user_id, ins_id) {
    let uri = `${prefix}/${user_id}/institutions/${ins_id}`;
    // let data = {
    //     code: code
    // };
    return post(uri)
}

export function associatePartner(user_id, ins_id) {
    let uri = `${prefix}/${user_id}/partners/${ins_id}`;
    // let data = {
    //     code: code
    // };
    return post(uri)
}

export function activateUser(id) {
    let uri = `${prefix}/${id}/activate`;
    return put(uri);
}

export function guardianAccept(id) {
    let uri = `${prefix}/${id}/guardian`;
    return put(uri);
}