<template>
	<div>
		<div class="columns">
			<Sidebar />

			<b-button
				v-if="($route.name=='SharedProducts' || $route.name=='MyProducts')"
				class="is-large is-secondary-color"
				id="openSidebarBtn"
				@click="setSidebarOpen(true)"
			>
				<b-icon icon="format-horizontal-align-left" type="is-white"></b-icon>
			</b-button>

			<div
				:class="$route.name=='SharedProducts' || $route.name=='MyProducts' ? 'is-9-desktop is-offset-3-desktop is-10-widescreen is-offset-2-widescreen' : 'is-8-desktop is-offset-2-desktop is-10-widescreen is-offset-1-widescreen'"
				class="column is-fullheight is-12-mobile is-10-tablet is-offset-1-tablet"
			>
				<div class="tabs is-centered">
					<ul>
						<li
							@click="$router.push({name: 'SharedProducts'})"
							:class="$route.name=='SharedProducts' ? 'is-active shared' : ' ' "
						>
							<a>Partilhas</a>
						</li>
						<li
							@click="$router.push({name: 'MyProducts'})"
							:class="$route.name=='MyProducts' ? 'is-active delivered' : ''"
							v-if="getAppSelectedType != 'partner' && getUserToken"
						>
							<a>Os meus artigos</a>
						</li>
						<li
							@click="$router.push({name: 'PendingProducts'})"
							v-if="getUserInfo.type != 'user' && getUserToken"
							:class="$route.name=='PendingProducts' ? 'is-active delivered' : ' '"
						>
							<a>Pendente</a>
						</li>
						<li
							@click="$router.push({name: 'RecycledProducts'})"
							v-if="getUserInfo.type != 'user' && getUserToken"
							:class="$route.name=='RecycledProducts' ? 'is-active delivered' : ' '"
						>
							<a>Reciclagem</a>
						</li>
						<li
							@click="$router.push({name: 'OrdersProducts'})"
							v-if="getUserInfo.type != 'user'  && getUserToken"
							:class="$route.name=='OrdersProducts' ? 'is-active delivered' : ' '"
						>
							<a>Encomendas</a>
						</li>
					</ul>
				</div>
				<div class="columns">
					<div class="column is-10 is-offset-1-desktop is-12-touch">
						<SharedProducts
							v-if="enableShared"
							:currentTab="$route.name"
							v-show="$route.name=='SharedProducts'"
						/>
						<MyProducts
							v-if="enableMyProducts"
							:currentTab="$route.name"
							v-show="$route.name=='MyProducts' && getUserToken"
						/>

						<Pending
							:currentTab="$route.name"
							v-show="$route.name=='PendingProducts' && getUserToken"
							v-if="getUserInfo.type != 'user' && !(getUserInfo.type == 'glad' && getAppSelectedType == 'app') && enablePending"
						/>
						
						<PendingUser
							:currentTab="$route.name"
							v-else
							v-show="$route.name=='PendingProducts' && getUserToken"
						/>

						<Recycled v-show="$route.name=='RecycledProducts' && getUserToken && getUserInfo.type != 'user'"/>

						<Orders :currentTab="$route.name" v-show="$route.name=='OrdersProducts' && getUserToken" />
					</div>
				</div>
			</div>
		</div>
		<AddProductButton v-if="getAppSelectedType != 'place'" />
	</div>
</template>

<script>
	import { filters } from "../mixins/modules/filters";

	import SharedProducts from "../components/main/Shared";
	import Pending from "../components/main/Pending";
	import PendingUser from "../components/main/PendingUser";
	import MyProducts from "../components/main/MyProducts";
	import Orders from "../components/main/Orders";
	import Sidebar from "../components/Parts/Sidebar";
	import AddProductButton from "../components/product/AddProductButton";
	import Recycled from "../components/main/Recycled";

	export default {
		name: "Main",
		mixins: [filters],
		components: {
			SharedProducts,
			Pending,
			MyProducts,
			PendingUser,
			Orders,
			Sidebar,
			AddProductButton,
			Recycled
		},
		watch: {
			isMobile() {
				if (this.isMobile) this.setSidebarOpen(false);
				else this.setSidebarOpen(true);
			},
			"$route.query"() {
				if (this.isMobile) this.setSidebarOpen(true);
			},
		},
		data() {
			return {
				enableMyProducts: true,
				enableShared: true,
				enableRecycled: true,
				enablePending: true,
				enablePendingUser: true,
			};
		},
		methods: {
			forceUpdate() {
				this.enableMyProducts = false;
				this.enableShared = false;
				this.enablePending = false;
				this.enableRecycled = false;
				this.enablePendingUser = false;
				this.$nextTick(() => {
					this.enableMyProducts = true;
					this.enableShared = true;
					this.enablePending = true;
					this.enableRecycled = true;
					this.enablePendingUser = true;
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../assets/sass/main.scss";

	.tabs {
		padding-top: 2vh;
	}

	.tabs ul {
		border: none;
	}

	.tabs li.is-active.shared a,
	.tabs li.is-active.delivered a {
		border-bottom: solid 5px $primary-color;
		color: $dark;
	}

	.margin-10 {
		margin: 10px;
	}

	#openSidebarBtn {
		border-radius: 100px;
		position: fixed !important;
		top: 60px !important;
		left: 15px !important;
		z-index: 2;
	}

	// ._sidebar {
	// 	margin-top: 58px;
	// }

	// .b-sidebar .sidebar-content.is-fullheight {
	// 	z-index: 1 !important;
	// }

	// .b_sidebar .sidebar-content {
	// 	z-index: 1 !important;
	// }
	// ._sidebar:first-child {
	// 	z-index: 1 !important;
	// }

	// .sidebar {
	// 	background-color: #f5f5f5;
	// 	width: 13%;
	// 	min-height: 100vh;
	// }
</style>