import Vue from 'vue'

// initial state
const state = {
    products: [],
    lastLocation: {},
    shared: {
        count: 0,
        pages: []
    },
    myProducts: [],
    pending: [],
    recycled: []
}

// getters
const getters = {
    getProducts(state, getters) {
        return state.products
    },
    getSharedProducts(state, getters) {
        return state.shared
    },


    getSharedProductsByPage: (state, getters) => (_page) => {
        return state.shared.pages.find(page => page.page == _page);
    },
    getSharedCount(state, getters) {
        return state.shared.count;
    },
    getPendingProductsByPage: (state, getters) => (_page) => {
        return state.pending.pages.find(page => page.page == _page);
    },
    getPendingCount(state, getters) {
        return state.pending.count;
    },
    getMyProductsByPage: (state, getters) => (_page) => {
        return state.myProducts.pages.find(page => page.page == _page);
    },
    getMyCount(state, getters) {
        return state.myProducts.count;
    },


    getMyProducts(state, getters) {
        return state.myProducts
    },
    getPendingProducts(state, getters) {
        return state.pending
    },

    getRecycledProducts(state, getters) {
        return state.recycled
    },

    getLastLocation(state, getters) {
        if (Object.keys(state.lastLocation).length === 0)
            return null;
        else
            return state.lastLocation;
    }
}

// mutations
const mutations = {
    resetStore(s) {
        const initial = JSON.parse(JSON.stringify(state));
        Object.keys(initial).forEach(key => {
            s[key] = initial[key]
        });
    },
    saveLastLocation(state, obj) {
        state.lastLocation = obj;
    },
    setMyProducts(state, obj) {
        state.myProducts = obj;
    },
    setPendingProducts(state, obj) {
        state.pending = obj;
    },

    addSharedPage(state, __page) {
        let _page = __page;
        if (!Array.isArray(__page))
            _page = [__page];

        for (let page of _page) {
            let index = function () {
                for (let i in state.shared.pages)
                    if (state.shared.pages[i].page == page.page)
                        return i;
                return -1;
            }();
            if (index != -1) {
                Vue.set(state.shared.pages, index, page)
            } else {
                state.shared.pages.push(page);
            }
        }

    },
    setSharedCount(state, count) {
        state.shared.count = count;
    },
    addPendingPage(state, __page) {
        let _page = __page;
        if (!Array.isArray(__page))
            _page = [__page];

        for (let page of _page) {
            let index = function () {
                for (let i in state.pending.pages)
                    if (state.pending.pages[i].page == page.page)
                        return i;
                return -1;
            }();
            if (index != -1) {
                Vue.set(state.pending.pages, index, page)
            } else {
                state.pending.pages.push(page);
            }
        }

    },
    setPendingCount(state, count) {
        state.pending.count = count;
    },
    addMyProductsPage(state, __page) {
        let _page = __page;
        if (!Array.isArray(__page))
            _page = [__page];

        for (let page of _page) {
            let index = function () {
                for (let i in state.myProducts.pages)
                    if (state.myProducts.pages[i].page == page.page)
                        return i;
                return -1;
            }();
            if (index != -1) {
                Vue.set(state.myProducts.pages, index, page)
            } else {
                state.myProducts.pages.push(page);
            }
        }

    },


    setRecycledProducts(state, obj) {
        state.recycled = obj;
    },

    resetProductStoreByKey(s, key) {
        const initial = JSON.parse(JSON.stringify(state));
        Vue.set(s, key, initial[key])
    },



    addMyProduct(state, obj) {
        state.myProducts.push(obj);
    },
    joinMyProduct(state, obj) {
        state.myProducts = obj.concat(state.myProducts);
    },
    setMyProductsCount(state, count) {
        state.myProducts.count = count;
    },
    removeMyProduct(state, _id) {
        for (let i in state.myProducts) {
            if (state.myProducts[i]._id == _id)
                state.myProducts.splice(i, 1);
        }
    },
    updateMyProduct(state, obj) {
        for (let i in state.myProducts) {
            if (state.myProducts[i]._id == obj._id)
                Vue.set(state.myProducts, i, obj)
        }
    },



    addPendingProduct(state, obj) {
        state.pending.push(obj);
    },
    updatePendingProduct(state, obj) {
        for (let i in state.pending) {
            if (state.pending[i]._id == obj._id)
                Vue.set(state.pending, i, obj)
        }
    },
    removePendingProduct(state, _id) {
        for (let i in state.pending) {
            if (state.pending[i]._id == _id)
                state.pending.splice(i, 1);
        }
    },
    joinPendingProduct(state, obj) {
        state.pending = obj.concat(state.pending);
    },

}




export default {
    namespaced: true,
    state,
    getters,

    mutations
}