let defaultImg = require("@/assets/img/default-img.png");

export const app = {
    computed: {
        getAppSelected() {
            return this.$store.getters['app/getAppSelected']
        },
        getAppSelectedType() {
            return this.$store.getters['app/getAppSelectedType']
        },
        getQueryShared() {
            return this.$store.getters['app/getQueryShared']
        },
        getQueryMyProducts() {
            return this.$store.getters['app/getQueryMyProducts']
        }
    },
    methods: {
        changeAppSelected(app) {
            this.$store.commit('app/changeAppSelected', app)
        },
        changeAppSelectedType(app) {
            this.$store.commit('app/changeAppSelectedType', app)
        },
        scrollToTop() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        setQueryShared(query) {
            this.$store.commit('app/setQueryShared', query)
        },
        setQueryMyProducts(query) {
            this.$store.commit('app/setQueryMyProducts', query)
        },
        checkImg(img) {
            if (!img) return defaultImg;
            return img;
        }

    }
}