import Vue from 'vue'

// initial state
const state = {
    glads: [],
    institutions: [],
    places: [],
    partners: [],
    info: [],
    currentTab: "Institutions"
}

// getters
const getters = {
    getGlads(state, getters) {
        return state.glads
    },
    getInstitutions(state, getters) {
        return state.institutions
    },
    getPlaces(state, getters) {
        return state.places
    },
    getPartners(state, getters) {
        return state.partners
    },
    getInfo(state, getters) {
        return state.info;
    },
    getCurrentTab(state, getters) {
        return state.currentTab;
    }
}

// mutations
const mutations = {
    resetStore(s) {
        const initial = JSON.parse(JSON.stringify(state));
        Object.keys(initial).forEach(key => {
            s[key] = initial[key]
        });
    },
    setGlads(state, obj) {
        Vue.set(state, "glads", obj)
    },
    setInstitutions(state, obj) {
        Vue.set(state, "institutions", obj)
    },
    setPlaces(state, obj) {
        Vue.set(state, "places", obj)
    },
    setPartners(state, obj) {
        Vue.set(state, "partners", obj)
    },
    setInfo(state, obj) {
        Vue.set(state, "info", obj)
    },
    setCurrentTab(state, tab) {
        state.currentTab = tab;
    },
    removeGlad(state, _id) {
        for (let i in state.glads) {
            if (state.glads[i]._id == _id)
                Vue.delete(state.glads, i);
        }
    },
    removeInstitution(state, _id) {
        for (let i in state.institutions) {
            if (state.institutions[i]._id == _id)
                Vue.delete(state.institutions, i);
        }
    },
    removePlace(state, _id) {
        for (let i in state.places) {
            if (state.places[i]._id == _id)
                Vue.delete(state.places, i);
        }
    },
    removePartner(state, _id) {
        for (let i in state.partners) {
            if (state.partners[i]._id == _id)
                Vue.delete(state.partners, i);
        }
    },
    updateGlad(state, obj) {
        for (let i in state.glads) {
            if (state.glads[i]._id == obj._id)
                Vue.set(state.glads, i, obj)
        }
    },
    updatePlace(state, obj) {
        for (let i in state.places) {
            if (state.places[i]._id == obj._id)
                Vue.set(state.places, i, obj)
        }
    },
    updatePartner(state, obj) {
        for (let i in state.partners) {
            if (state.partners[i]._id == obj._id)
                Vue.set(state.partners, i, obj)
        }
    },
    updateInstitution(state, obj) {
        for (let i in state.institutions) {
            if (state.institutions[i]._id == obj._id)
                Vue.set(state.institutions, i, obj)
        }
    },
}




export default {
    namespaced: true,
    state,
    getters,

    mutations
}