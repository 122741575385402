<template>
	<div class="column is-8 is-offset-2">
		<h1 class="title is-4">Política de Cookies</h1>

		<!-- <p>
      <strong>Política de Cookies de&nbsp;</strong>
      <a href="http://www.to-be-green.com">
        <strong>www.to-be-green.com</strong>
      </a>
      <strong>&nbsp;e www.to-be-green.pt</strong>
		</p>-->
		<p>
			Esta é a Política de Cookies para o website “To be Green”, acessível apartir de&nbsp;
			<a
				href="http://www.to-be-green.com"
			>www.to-be-green.com</a> e www.to-be-green.pt .
		</p>
		<p>
			<strong>O que são “Cookies”?</strong>
		</p>
		<p>Como é prática comum com praticamente todos os websites corporativos, este website utiliza “cookies” que são pequenos ficheiros que são transferidos para o seu computador a fim de melhorar a sua experiência .</p>
		<p>Esta página descreve que tipo de informação recolhem , como a usamos e o porquê de necessitarmos de implementar “cookies” na sua utilização.</p>
		<p>Explicitaremos também como prevenir que estes “cookies” sejam armazenados no seu computador/dispositivo, podendo no entanto bloquear algumas funcionalidades deste mesmo website.</p>
		<p>
			Para mais informação geral sobre “cookies” e o seu funcionamento poderá consultar o artigo da Wikipedia sobre HTTP Cookies&nbsp;
			<a
				href="https://pt.wikipedia.org/wiki/Cookie_(inform%C3%A1tica)"
			>aqui</a>.
		</p>
		<p>
			<strong>Como utilizamos cookies?</strong>
		</p>
		<p>Utilizamos cookies por uma variedade de razões detalhadas abaixo. Infelizmente, na maioria dos casos não existem opções sistematizadas para desabilitar os cookies sem incorrer no mau funcionamento que acrescentam a este site. È recomendado que permita a utilização dos cookies se não tem a certeza de como poderão afetar a sua experiência no nosso website.</p>
		<p>
			<strong>&nbsp;</strong>
		</p>
		<p>
			<strong>Desabilitar a implementação de cookies</strong>
		</p>
		<p>Poderá prevenir a implementação de cookies ajustando as configurações no seu “browser”, (veja a secção de ajuda do seu browser para informações sobre como o fazer). Alertamos que a desabilitação de cookies, anulará consequentemente alguns aspectos do que é a sua experiência no nosso website. É portanto recomendado que não desative os cookies.</p>
		<p>
			<strong>&nbsp;</strong>
		</p>
		<p>
			<strong>Os cookies que implementamos</strong>
		</p>
		<ul type="disc">
			<li>Cookies relacionados com a sua conta</li>
		</ul>
		<p>Caso crie uma conta no nosso website, serão utilizados cookies para o gerenciamento do processo de registo e administração geral. Estes cookies são normalmente apagados no momento em que efetua “logout”, contudo, em alguns casos poderão permanecer no seu dispositivo para recordar as suas preferências enquanto estiver “logged out”.</p>
		<ul type="disc">
			<li>Cookies relacionados com o “Login”</li>
		</ul>
		<p>Utilizamos cookies enquanto se encontra conectado na sua conta no nosso website para que possamos tomar registo da sua atividade(que páginas visitou, quanto tempo esteve em cada, etc.) Estes cookies são normalmente removidos quando desliga a sua conta ou efetua “logout” a fim de garantir que só poderá aceder a conteúdos exclusivos enquanto está ligado na sua conta.</p>
		<ul type="disc">
			<li>Cookies relacionados com e-mail e newsletters</li>
		</ul>
		<p>Este site oferece aos seus utilizadores um serviço de newsletters/ subscrição por email e alguns cookies poderão ser utilizados para lembrar o sistema se já está registado a fim de lhe mostrar notificações específicas que poderão só ser visíveis para utilizadores subscritos/inscritos.</p>
		<ul type="disc">
			<li>Cookies de terceiros</li>
		</ul>
		<p>Em alguns casos específicos, utilizamos também cookies de terceiros autorizados.</p>
		<p>A presente seção detalha que cookies de terceiros poderão ser utilizados no nosso website.</p>
		<ul type="disc">
			<li>Este website utiliza Google Analythics que é uma das mais prestigiadas, seguras e conhecidas formas de controlo de métricas analíticas para nos ajudar a perceber como melhorar a sua experiência. Estes cookies poderão monitorizar aspectos como quanto tempo despende em determinada página para que consigamos continuar a criar conteúdo relevante.</li>
			<li>
				Para mais informação sobre Cookies de Google Analythics, veja a página oficial do Google Analythics&nbsp;
				<a
					href="https://policies.google.com/?hl=pt-PT"
				>aqui</a>.
			</li>
			<li>Estatísticas analíticas de terceiros são utilizadas para monitorizar e medir a utilização deste website para que possamos continuar a produzir conteúdo relevante.</li>
			<li>Este website utiliza Google Adwords, que é uma rede de publicidade inteligente por via de motores de pesquisa e posicionamento de conteúdo em páginas de interesse para o utilizador.</li>
			<li>
				Para mais informações sobre Cookies do Google Adwords e da sua política de privacidade, leia&nbsp;
				<a
					href="https://policies.google.com/technologies/ads"
				>aqui</a>.
			</li>
		</ul>
		<p>
			<strong>Mais informação</strong>
		</p>
		<p>Caso pretenda obter mais informação sobre algum aspecto em detalhe de como utilizamos os nossos cookies, queira por favor proceder com a solicitação de esclarecimento por um dos métodos preferidos:</p>
		<p>E-mail: adinis@det.uminho.pt</p>
		<p>Telefone: +351 918 204 280</p>
	</div>
</template>

<script>
	// import { BulmaAccordion, BulmaAccordionItem } from "vue-bulma-accordion";

	export default {
		name: "CookiesPolicy",
		// components: { BulmaAccordion, BulmaAccordionItem },
		created() {},
	};
</script>

<style scoped>
	.card {
		border-radius: unset;
	}
	.column {
		margin-top: 5vh;
		margin-bottom: 5vh;
	}

	ul {
		list-style: outside;
		padding-left: 30px;
	}

	p {
		margin-bottom: 10px;
	}
</style>