<template>
	<div
		class="column is-one-third-tablet is-5-mobile  is-one-quarter-desktop is-one-fifth-widescreen is-one-fifth-fullhd"
	>
		<div class="card" @mouseover="hover(true)" @mouseleave="hover(false)">
			<header class="card-header">
				<!-- <p class="card-header-title">Card header</p> -->
				<div class="card-header-icon">
					<b-icon pack="tbg" :icon="gender_icon" size="is-small" type="is-secondary-dark"></b-icon>
				</div>
				<span class="card-header-title is-secondary-dark">{{product.product.size}}</span>
				<div v-show="product.user._id == getUserInfo._id" class="card-header-icon">
					<b-icon pack="tbg" icon="myproduct" size="is-small" type="is-primary-color"></b-icon>
				</div>
			</header>
			<div class="card-image" @click="openProduct(product._id)">
				<figure class="image is-4by3">
					<img class="product-image" :src="checkImg(product.product.imgs[0])" />
				</figure>
			</div>
			<!-- <div
				v-if="(showEdit && product.user && (getUserInfo._id == product.user._id || getUserInfo._id == product.user) && product.status == 'pending') || (getUserInfo.type != 'user' && showEdit)"
				class="columns is-gapless"
			>
				<div class="column">
					<b-button v-show="isHover" @click="openProduct(product._id)" class="btn is-primary-color">
						<b-icon icon="eye" size="is-small"></b-icon>
					</b-button>
					<b-button v-show="isHover" @click="editProduct" class="btn is-secondary-color">
						<b-icon icon="pencil" size="is-small"></b-icon>
					</b-button>
					<b-button v-show="isHover" @click="deleteProduct" class="btn is-danger">
						<b-icon icon="delete" size="is-small"></b-icon>
					</b-button>
				</div>
			</div>-->
			<!-- <b-button
				v-else
				v-show="isHover"
				@click="openProduct(product._id)"
				class="btn is-primary-color is-fullwidth"
			>
				<b-icon icon="eye"></b-icon>
			</b-button>-->
			<div class="card-content">
				<div class="content">
					<div v-if="showStatus">
						<p v-if="product.status== 'pending'" class="product delivered pending">Pendente</p>
						<p v-if="product.status== 'approved'" class="product delivered approved">Aprovado</p>
						<!-- <p v-if="product.status== 'delivered'" class="product delivered approved">Entregue</p> -->
						<p v-if="product.status =='bought'" class="product delivered bought">Adquirido</p>
						<p v-if="product.status =='recycled'" class="product delivered recycled">Reciclado</p>
					</div>
					<span class="points">{{product.points}} pontos</span>
					<b-button class="btn-cart" v-show="product.user._id != getUserInfo._id && getUserInfo.type == 'user'" v-if="!isProductInCart" @click="addProductToCart">
						<b-icon pack="tbg" icon="addcart" size="is-medium" type="is-secondary-color"></b-icon>
					</b-button>
					<b-button class="btn-cart" v-show="product.user._id != getUserInfo._id  && getUserInfo.type == 'user'" v-else @click="removeProductFromCart">
						<b-icon pack="tbg" icon="addedcart" size="is-medium" type="is-secondary-color"></b-icon>
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { cart } from "../../mixins/modules/cart";

	export default {
		name: "CardProduct",
		mixins: [cart],
		props: {
			product: Object,
			showStatus: {
				type: Boolean,
				default: false,
			},
			showEdit: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				isHover: false,
				gender_icon: "man",
			};
		},
		created() {
			switch (this.product.product.genre) {
				case "Mulher":
					this.gender_icon = "woman";
					break;

				case "Homem":
					this.gender_icon = "man";
					break;

				case "Criança":
					switch (this.product.product.genreChild) {
						case "Rapaz":
							this.gender_icon = "boy";
							break;

						case "Rapariga":
							this.gender_icon = "girl";
							break;

						case "Bebe":
							this.gender_icon = "baby";
							break;

						default:
							break;
					}
					break;

				default:
					break;
			}
		},
		methods: {
			openProduct(productID) {
				this.$router.push(`/product/${productID}`);
			},
			hover(bol) {
				this.isHover = bol;
			},
			editProduct() {
				this.$emit("editproduct", this.product);
			},
			deleteProduct() {
				this.$emit("deleteproduct", this.product);
			},
			addProductToCart() {
				this.addToCart(this.getAppSelected._id, this.product);

				this.$buefy.toast.open({
					message: "Adicionado ao carrinho!",
					type: "is-success",
					position: "is-bottom",
				});
			},
			removeProductFromCart() {
				this.removeFromCart(this.getAppSelected._id, this.product._id);

				this.$buefy.toast.open({
					message: "Removido do carrinho!",
					type: "is-success",
					position: "is-bottom",
				});
			},
		},
		computed: {
			getDescription() {
				if (this.product.product.description)
					return this.product.product.description.length > 16
						? this.product.product.description.substr(0, 16) + "..."
						: this.product.product.description;
				else return "";
			},
			isProductInCart() {
				return this.isInCart(this.getAppSelected._id, this.product._id);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "../../assets/sass/colors.scss";
	.card-header-icon {
		padding: 0.75rem 0.75rem;
		cursor: unset;
	}
	.card-header-title {
		padding: 0;
	}
	.points {
		font-weight: bold;
		color: $secondary-dark;
	}
	.content {
		position: relative;
	}
	.btn-cart {
		position: absolute;
		right: -9px;
		bottom: -10px;
		border: none;
		outline: none;
	}
	.btn-cart:focus:not(:active),
	.btn-cart.is-focused:not(:active) {
		box-shadow: unset;
	}

	.card-header-icon:last-child {
		margin-right: 15px;
	}
</style>