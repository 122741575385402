<template>
	<div>
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>

		<div v-if="showGenders">
			<!-- MULHER -->
			<h1 class="subtitle">Mulher</h1>
			<div v-if="filterWomen.length<1">
				<section class="section">
					<div class="content has-text-grey has-text-centered">
						<p>Não foram encontrados artigos !</p>
					</div>
				</section>
			</div>
			<div v-else>
				<div class="columns is-multiline">
					<template v-for="(product,j) in filterWomen">
						<CardProduct :key="j" :product="product" />
					</template>
				</div>
			</div>
			<br />
			<!-- HOMEM -->
			<h1 class="subtitle">Homem</h1>
			<div v-if="filterMen.length<1">
				<section class="section">
					<div class="content has-text-grey has-text-centered">
						<p>Não foram encontrados artigos !</p>
					</div>
				</section>
			</div>
			<div v-else>
				<div class="columns is-multiline">
					<template v-for="(product,j) in filterMen">
						<CardProduct :key="j" :product="product" />
					</template>
				</div>
			</div>
			<br />
			<!-- CRIANÇA -->
			<h1 class="subtitle">Criança</h1>
			<div v-if="filterChildren.length<1">
				<section class="section">
					<div class="content has-text-grey has-text-centered">
						<p>Não foram encontrados artigos !</p>
					</div>
				</section>
			</div>
			<div v-else>
				<div class="columns is-multiline">
					<template v-for="(product,j) in filterChildren">
						<CardProduct :key="j" :product="product" />
					</template>
				</div>
			</div>
		</div>
		<div v-else>
			<!-- TODOS -->
			<div v-if="total < 1">
				<section class="section">
					<div class="content has-text-grey has-text-centered">
						<p>Não foram encontrados artigos !</p>
					</div>
				</section>
			</div>
			<div v-else>
				<section v-if="!getSharedProductsByPage(currentPage)" class="section">
					<div class="content has-text-grey has-text-centered">
						<p>A procurar...</p>
					</div>
				</section>
				<div v-else class="columns is-centered is-multiline is-mobile">
					<template v-for="(product,j) in getSharedProductsByPage(currentPage).products">
						<CardProduct :key="j" :product="product" />
					</template>
				</div>
			</div>
		</div>
		<div class="column marginauto products is-two-thirds">
			<b-pagination
				class="marginauto"
				:total="total"
				:current.sync="currentPage"
				order="is-centered"
				:per-page="perPage"
				aria-next-label="Next page"
				aria-previous-label="Previous page"
				aria-page-label="Page"
				aria-current-label="Current page"
				@change="changePage"
			></b-pagination>
		</div>
	</div>
</template>
<script>
	import {
		getAllProducts,
		getByMunicipality,
	} from "./../../api/products/products";
	import { product } from "../../mixins/modules/product.js";
	import CardProduct from "../product/CardProduct.vue";

	const queryString = require("query-string");
	import { Object as _Object } from "@iotechpis/utils";

	export default {
		name: "Shared",
		mixins: [product],
		components: {
			CardProduct,
		},
		created() {
			this.$route.query.page
				? (this.currentPage = parseInt(this.$route.query.page))
				: (this.currentPage = 1);
			this.perPage = this.showGenders
				? this.perPageGender
				: this.perPageFilter;
			this.resetProductStoreByKey("shared");
			this.getProducts();
			this.setQueryShared(this.$route.query);
		},
		data() {
			return {
				isLoading: false,
				genre: null,
				perPage: 5,
				perPageGender: 5,
				perPageFilter: 20,
				pagesMinusOffset: 1,
				pagesPlusOffset: 3,
				totalPages: 5,
				total: null,
				currentPage: 1,
				hasPageChanged: false,
				limitGender: 5,
				limitAll: 20,
			};
		},
		props: {
			currentTab: String,
		},
		watch: {
			"$route.query"(to, from) {
				if (this.$route.name == "SharedProducts") {
					if (!_Object.isEqual(to, from))
						if (!this.hasPageChanged) {
							this.resetProductStoreByKey("shared");
							this.currentPage = this.$route.query.page
								? parseInt(this.$route.query.page)
								: 1;
							this.getProducts();
						} else {
							this.hasPageChanged = false;
							if (!this.getSharedProductsByPage(this.currentPage))
								this.getProducts();
						}
				}
			},
			"$route.name"(to, from) {
				if (this.$route.name == "SharedProducts") {
					this.$route.query.page
						? (this.currentPage = parseInt(this.$route.query.page))
						: (this.currentPage = 1);
					this.perPage = this.showGenders
						? this.perPageGender
						: this.perPageFilter;
				}
			},
		},
		methods: {
			getProducts() {
				this.$route.name == "SharedProducts" ? (this.isLoading = true) : "";
				let app_id =
					this.getAppSelected._id == "app"
						? null
						: this.getAppSelected._id;

				let _query = this.getQueryShared;
				let func = getAllProducts;

				if (app_id) {
					if (this.getAppSelectedType == "institution")
						_query.institution = app_id;
					else if (this.getAppSelectedType == "place") {
						_query.place = app_id;
						_query.showInstitution = true;
					} else if (this.getAppSelectedType == "partner") {
						_query.partner = app_id;
						func = getByMunicipality;
					}
				}

				_query.status = "approved";
				if (!_query.sort) _query.sort = "-1";
				_query._current_page = this.currentPage;
				_query._per_page = this.showGenders
					? this.limitGender
					: this.limitAll;
				if (this.showGenders) _query._show_genders = true;

				func("?" + queryString.stringify(_query))
					.then((response) => {
						let pages = [];
						if (this.showGenders) {
							let _products = [];
							for (let gender of response.data.body.products) {
								_products.push(...gender.products);
							}
							pages.push({
								page: response.data.body.page,
								products: _products,
							});
						} else pages.push(...[response.data.body]);

						this.addSharedPage(pages);

						if (this.showGenders) {
							this.total = Math.max(
								...response.data.body.products.map((gender) => {
									return gender.count;
								})
							);
						} else {
							this.total = response.data.body.count;
						}
						if (!this.total) this.total = 0;

						this.setSharedCount(this.total);

						this.isLoading = false;
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;
					});
			},
			changePage() {
				if (this.$route.name == "SharedProducts") {
					this.scrollToTop();
					if (this.$route.query != undefined) {
						let q = JSON.parse(JSON.stringify(this.$route.query));
						q.page = this.currentPage;
						this.$router.push({ query: q });
						this.setQueryShared(q);
					} else {
						this.$router.push({ query: { page: this.currentPage } });
						this.setQueryShared({ page: this.currentPage });
					}

					this.hasPageChanged = true;
				}
			},
		},
		computed: {
			filterMen() {
				return this.getSharedProductsByPage(this.currentPage)
					? this.getSharedProductsByPage(
							this.currentPage
					  ).products.filter(
							(product) => product.product.genre == "Homem"
					  )
					: [];
			},
			filterWomen() {
				return this.getSharedProductsByPage(this.currentPage)
					? this.getSharedProductsByPage(
							this.currentPage
					  ).products.filter(
							(product) => product.product.genre == "Mulher"
					  )
					: [];
			},
			filterChildren() {
				return this.getSharedProductsByPage(this.currentPage)
					? this.getSharedProductsByPage(
							this.currentPage
					  ).products.filter(
							(product) =>
								product.product.genre == "Crianca" ||
								product.product.genre == "Criança"
					  )
					: [];
			},
			getAllByPage() {
				return this.getSharedProducts.find(
					(page) => page.page == this.currentPage
				);
			},
			showGenders() {
				// if (this.$route.name == "SharedProducts")
				// 	if (Object.keys(this.$route.query).length == 0) return true;
				// 	else if (
				// 		Object.keys(this.$route.query).length == 1 &&
				// 		(this.$route.query.sort || this.$route.query.page)
				// 	)
				// 		return true;
				// 	else if (
				// 		Object.keys(this.$route.query).length == 2 &&
				// 		this.$route.query.sort &&
				// 		this.$route.query.page
				// 	)
				// 		return true;
				return false;
			},
		},
	};
</script>

<style lang="scss">
	@import "../../assets/sass/main";

	@media only screen and (max-width: 769px) {
		.marginauto-products {
			margin: auto;
		}
	}

	.product-image {
		object-fit: contain !important;
	}
</style>