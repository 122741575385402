<template>
	<div>
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>
		<br />
		<div v-if="paginate.length<1">
			<section class="section">
				<div class="content has-text-grey has-text-centered">
					<p>Não foram encontrados artigos !</p>
				</div>
			</section>
		</div>
		<div v-else>
			<div class="columns is-centered is-multiline is-mobile">
				<template v-for="(product,j) in paginate">
					<CardProduct
						:key="j"
						@editproduct="openEditProduct"
						@deleteproduct="deleteProduct"
						:showEdit="true"
						:showStatus="true"
						:product="product"
					/>
				</template>
			</div>
		</div>
		<div class="column marginauto products is-two-thirds">
			<b-pagination
				class="marginauto"
				:total="total"
				:current.sync="currentPage"
				order="is-centered"
				:per-page="perPageFilter"
				aria-next-label="Next page"
				aria-previous-label="Previous page"
				aria-page-label="Page"
				aria-current-label="Current page"
				@change="changePage"
			></b-pagination>
		</div>

		<EditProduct
			v-on:productupdate="productUpdate"
			:product_edit="product_edit"
			v-if="isEditProductOpen"
			:class="{ 'is-active': isEditProductOpen }"
		/>
	</div>
</template>

<script>
	import { getAllProducts, disableProduct } from "./../../api/products/products";
	import CardProduct from "../product/CardProduct.vue";

	import EditProduct from "../product/EditProduct";
	import { product } from "@/mixins/modules/product";
	const queryString = require("query-string");

	import { Object as _Object } from "@iotechpis/utils";

	export default {
		name: "MyProducts",
		mixins: [product],
		components: {
			CardProduct,
			EditProduct,
		},
		data() {
			return {
				isLoading: false,
				products: [],
				total: null,
				currentPage: 1,
				hasPageChanged: false,
				perPageFilter: 10,
				product_edit: null,
			};
		},
		created() {
			this.$route.query.page
				? (this.currentPage = parseInt(this.$route.query.page))
				: (this.currentPage = 1);

			this.setQueryMyProducts({});
			this.getProducts();
		},
		props: {
			status: String,
		},
		watch: {
			"$route.name"() {
				if (this.$route.name == "MyProducts") {
					this.$route.query.page
						? (this.currentPage = parseInt(this.$route.query.page))
						: (this.currentPage = 1);
				}
			},
			"$route.query"(to, from) {
				if (!_Object.isEqual(to, from))
					if (this.$route.name == "MyProducts") {
						if (!this.hasPageChanged) this.getProducts();
						else this.hasPageChanged = false;
					}
			},
		},
		methods: {
			openEditProduct(_product) {
				this.setEditProductOpen(true);
				this.product_edit = _product;
			},

			getProducts() {
				this.$route.name == "MyProducts" ? (this.isLoading = true) : "";
				let app_id =
					this.getAppSelected._id == "app"
						? null
						: this.getAppSelected._id;
				let _query = {
					user: this.getUserInfo._id,
				};

				if (app_id) {
					if (this.getAppSelectedType == "institution") {
						if (
							this.getUserInfo.type == "admin" ||
							this.getUserInfo.type == "glad"
						) {
							_query = {};
						}

						_query.institution = app_id;
					} else if (this.getAppSelectedType == "place")
						_query.place = app_id;
				}

				if (this.getQueryMyProducts.status)
					_query.status = this.getQueryMyProducts.status;

				getAllProducts("?" + queryString.stringify(_query))
					.then((response) => {
						this.setMyProducts(response.data.body);

						this.total = response.data.body.length;

						this.isLoading = false;
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;
					});
			},
			productUpdate(_product) {
				this.updateMyProduct(_product);
				this.$emit("return");
			},
			deleteProduct(product) {
				this.$buefy.dialog.confirm({
					title: "Remover artigo",
					message:
						"Tem a certeza que pretende remover este artigo? Esta ação não pode ser desfeita!",
					confirmText: "Sim",
					cancelText: "Não",
					type: "is-danger",
					hasIcon: true,
					onConfirm: () => {
						disableProduct(product._id)
							.then((response) => {
								this.removeProduct(product._id);
								this.$buefy.toast.open({
									message: "Artigo removido com sucesso!",
									type: "is-success",
									position: "is-bottom",
								});
								
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: "Erro ao remover. Tente mais tarde!",
									type: "is-danger",
									position: "is-bottom",
								});
							});
					},
				});
			},
			changePage() {
				if (this.$route.name == "MyProducts") {
					this.scrollToTop();
					if (this.$route.query != undefined) {
						let q = JSON.parse(JSON.stringify(this.$route.query));
						q.page = this.currentPage;
						this.$router.push({ query: q });
						this.setQueryMyProducts(q);
					} else {
						this.$router.push({ query: { page: this.currentPage } });
						this.setQueryMyProducts({ page: this.currentPage });
					}

					this.hasPageChanged = true;
				}
			},
		},
		computed: {
			paginate() {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.total = this.filterProducts.length;
				let currentPage = this.currentPage;
				var index = (currentPage - 1) * this.perPageFilter;
				return this.filterProducts.slice(index, index + this.perPageFilter);
			},
			filterProducts() {
				if (!this.status) return this.getMyProducts;
				return this.getMyProducts.filter((e) => e.status == this.status);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "../../assets/sass/main";
</style>