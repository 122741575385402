<template>
    <div class="login">
        <section class="hero is-fullheight">
            <div class="column is-3-desktop is-offset-8-desktop is-10-touch is-offset-1-touch">
                <div class="card">
                    <div class="card-content">
                        <h5 class="title is-5 has-text-primary-color">Ativar conta</h5>

                        <b-loading class="card" v-if="isLoading" :active.sync="isLoading" :can-cancel="false">
                        </b-loading>
                        <div v-else>
                            <div v-if="isActivated">
                                <p>
                                    Conta ativada com sucesso!
                                </p>
                            </div>
                            <div v-else>
                                <p>
                                    Ocorreu um erro ao ativar a sua conta!
                                </p>
                            </div>

                        </div>
                        <br>
                        <b-button @click="goToLogin()" class="is-outlined is-primary-color is-medium is-fullwidth">
                            Login</b-button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {
        activateUser
    } from '../api/user/user';

    export default {
        name: "ActivateAccount",
        data() {
            return {
                isLoading: true,
                isActivated: false
            }
        },
        created() {
            activateUser(this.$route.params.id)
                .then(response => {
                    this.isLoading = false;
                    this.isActivated = true;
                })
                .catch(error => {
                    console.log(error)
                    this.isLoading = false;
                    this.isActivated = false;
                });
        },
        methods: {
            goToLogin() {
                this.$router.push("/login");
            }
        }
    }
</script>

<style scoped>
    img {
        width: unset !important;
    }

    #nav {
        padding: 0px;
    }
</style>