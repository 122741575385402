<template>
	<div class="modal">
		<ValidationObserver v-slot="{}">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">Adicionar artigo</p>
					<button @click="modal()" class="delete" aria-label="close"></button>
				</header>
				<section class="modal-card-body" style="background: white;">
					<div class="card-content">
						<div v-if="step == 'fields'">
							<div v-if="selected.product && selected.product.product.imgs.length > 0" class="container">
								<b-carousel
									animated="fade"
									:value="activeImg"
									@change="updateActiveImg"
									:indicator-custom="true"
									:indicator-inside="false"
									:autoplay="false"
								>
									<b-carousel-item v-for="(img, i) in selected.product.product.imgs" :key="i">
										<figure class="image is-4by3">
											<img class="product-image" :src="img" />
										</figure>
									</b-carousel-item>
									<template slot="indicators" slot-scope="props">
										<figure class="image">
											<img
												class="product-image marginauto custom-indicator"
												:src="selected.product.product.imgs[props.i]"
												:title="props.i"
											/>
										</figure>
									</template>
								</b-carousel>
								<br />
								<br />
							</div>

							<b-field label="Género">
								<ValidationProvider name="Género" rules="required" v-slot="{ errors }">
									<b-select
										:disabled="disabled.genre"
										v-model="selected.genre"
										v-on:input="changeInput()"
										name="genre"
										expanded
										placeholder="Selecione o género"
									>
										<option
											:key="i"
											v-for="(option, i) in filters.genre"
											v-bind:value="option.value"
										>{{ option.text }}</option>
									</b-select>
									<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
								</ValidationProvider>
							</b-field>

							<b-field v-if="selected.genre=='Criança'" label="Género da criança">
								<b-select
									:disabled="disabled.genreChild"
									v-model="selected.genreChild"
									v-on:input="changeInput()"
									name="size"
									expanded
									placeholder="Selecione o tipo"
								>
									<option
										:key="i"
										v-for="(option, i) in filters.genreChild"
										v-bind:value="option.value"
									>{{ option.text }}</option>
								</b-select>
							</b-field>

							<b-field label="Tipo de Peça">
								<b-select
									:disabled="disabled.type"
									v-model="selected.type"
									v-on:input="changeInput()"
									name="typeProduct"
									expanded
									placeholder="Selecione o tipo de peça"
								>
									<option
										:key="i"
										v-for="(option,i ) in filters.type"
										v-bind:value="option.value"
									>{{ option.text }}</option>
								</b-select>
							</b-field>

							<b-field label="Artigo">
								<b-select
									:disabled="disabled.product"
									v-model="selected.product"
									name="product"
									expanded
									placeholder="Selecione o artigo"
								>
									<option
										:key="i"
										v-for="(option,i) in filteredProducts"
										v-bind:value="option"
									>{{ option.product.description }}</option>
								</b-select>
							</b-field>

							<b-field label="Tamanho">
								<b-select
									:disabled="disabled.size"
									v-model="selected.size"
									name="size"
									expanded
									placeholder="Selecione o tamanho"
								>
									<option :key="i" v-for="(option, i) in filters.size" v-bind:value="option.value">{{ option.text }}</option>
								</b-select>
							</b-field>

							<b-field v-if="getUserInfo.type != 'user'" label="Pontos">
								<b-input type="number" v-model="product.points" name="points" />
							</b-field>

							<b-field v-if="getUserInfo.type != 'user'" label="Preço">
								<b-input type="number" v-model="product.price" name="price" />
							</b-field>

							<b-field label="Quantidade">
								<b-input type="number" v-model="product.quantity" name="quantity" />
							</b-field>

							<b-field v-if="getUserInfo.type != 'user'" label="Peso (gramas)">
								<b-input type="number" v-model="product.product.weight" name="weight" />
							</b-field>
						</div>
						<div v-else>
							<p>Escolher local de entrega:</p>
							<br />
							<ChoosePlace @placeselected="changePlace" />
						</div>
					</div>
				</section>
				<footer class="modal-card-foot">
					<b-button
						v-if="step == 'fields'"
						@click="step = 'place'"
						class="is-primary-color is-medium is-fullwidth"
					>Próximo</b-button>
					<b-button
						v-if="step == 'place'"
						@click="step = 'fields'"
						class="is-secondary-color is-medium is-fullwidth"
						:loading="isLoading"
					>Anterior</b-button>
					<b-button
						v-if="step == 'place'"
						@click="add()"
						:disabled="!product.place"
						class="is-primary-color is-medium is-fullwidth"
						:loading="isLoading"
					>Adicionar</b-button>
				</footer>
			</div>
		</ValidationObserver>
	</div>
</template>

<script>
	//API
	import { addProduct } from "../../api/products/products";
	import { filters } from "../../mixins/modules/filters.js";
	import { product } from "../../mixins/modules/product.js";
	import ChoosePlace from "./ChoosePlace";

	import Districts from "@/config/Districts.json";
	import Municipalities from "@/config/Municipality.json";

	export default {
		name: "AddProduct",
		mixins: [filters, product],
		components: {
			ChoosePlace,
		},
		props: {},
		data() {
			return {
				maxImgs: 2,
				activeImg: 0,
				step: "fields",
				new_imgs: [],
				municipalityDisabled: true,
				districtDisabled: false,
				districts: Districts,
				districtSelected: null,
				municipalities: [],
				disabled: {
					genre: false,
					genreChild: false,
					type: true,
					size: true,
					district: true,
					municipality: true,
					product: true,
				},
				isLoading: false,
				user: null,
				filters: {
					genre: [],
					genreChild: [],
					type: [],
					size: [],
				},
				selected: {
					genre: null,
					genreChild: null,
					type: null,
					size: null,
					product: null,
				},
				selectType: null,
				product: {
					product: {
						genre: null,
						genreChild: null,
						description: null,
						size: null,
						color: null,
						imgs: [],
						type: null,
						weight: null,
					},
					prices: {
						regularized: null,
						not_regularized: null,
					},
					location: {
						address: null,
						municipality: null,
						district: null,
						country: null,
					},
					quantity: null,
					points: null,
					price: null,
					place: null,
				},
				products: [],
				filteredProducts: [],
			};
		},
		created() {
			this.user = this.getUserInfo;
			if (this.user.type == "glad") {
				this.municipalityDisabled = true;
				this.districtDisabled = true;
			}

			this.products = this.getStorageProducts;

			this.selected = {
				genre: this.product.product.genre,
				genreChild: this.product.product.genreChild,
				type: this.product.product.type,
				size: this.product.product.size,
			};

			this.changeInput();

			if (this.product.location.district) {
				for (let d of this.districts)
					if (d.district_name == this.product.location.district)
						this.districtSelected = d;
				this.getMunicipalities();
			}
		},
		computed: {},
		methods: {
			changePlace(m) {
				this.product.place = m ? m._id : null;
			},
			updateActiveImg(i) {
				this.activeImg = i;
			},
			modal() {
				this.$emit("modal");
			},
			previewFile() {
				if (
					this.new_imgs.length > this.maxImgs ||
					this.product.product.imgs.length + this.new_imgs.length >
						this.maxImgs
				) {
					this.new_imgs = [];
					return this.$buefy.toast.open({
						message:
							"Não são permitidas mais que " +
							this.maxImgs +
							" imagens!",
						type: "is-danger",
						position: "is-bottom",
					});
				}

				for (let i of this.new_imgs) {
					const reader = new FileReader();
					reader.readAsDataURL(i);
					reader.onload = (e) => {
						this.product.product.imgs.push(e.target.result);
					};
				}
				this.new_imgs = [];
			},
			removeImage(i) {
				this.updateActiveImg(0);
				this.new_imgs.splice(i, 1);
				this.product.product.imgs.splice(i, 1);
			},
			getMunicipalities() {
				this.municipalities = [];
				for (let m of Municipalities) {
					if (this.districtSelected.district_code == m.district_code)
						this.municipalities.push(m);
				}
				this.product.location.district = this.districtSelected.district_name;
				if (this.user.type != "glad") this.municipalityDisabled = false;
			},
			changeInput() {
				this.filters = {
					genre: [],
					genreChild: [],
					type: [],
					size: [],
				};

				this.filters.genre = this.getFilterByName("genres");
				this.filters.genreChild = this.getFilterByName("genreChild");

				if (this.selected.genre) {
					if (this.selected.genre == "Criança") {
						if (this.selected.genreChild) {
							let genre_child = this.filters.genreChild.filter(
								(e) => e.value == this.selected.genreChild
							)[0];
							if (!genre_child) return;
							this.filters.type = this.getFilterByName(
								genre_child.child
							);

							if (
								!this.filters.type.filter(
									(e) => e.value == this.selected.type
								)[0]
							) {
								this.disabled.type = false;
								this.disabled.size = true;
								this.disabled.product = true;
								this.selected.type = null;
							} else this.disabled.type = false;

							if (this.selected.type) {
								this.filters.size =
									this.selected.genreChild == "Bebe"
										? this.getFilterByName("sizesMonth")
										: this.getFilterByName("sizesAge");
								if (
									!this.filters.size.filter(
										(e) => e.value == this.selected.size
									)[0]
								) {
									this.disabled.size = false;
									this.disabled.product = false;
									this.selected.size = null;
								} else {
									this.disabled.product = false;
									this.disabled.size = false;
								}
							}
						}
					} else {
						this.selected.genreChild = null;
						let genre_child = this.filters.genre.filter(
							(e) => e.value == this.selected.genre
						)[0];
						if (!genre_child) return;

						this.filters.type = this.getFilterByName(genre_child.child);

						if (
							!this.filters.type.filter(
								(e) => e.value == this.selected.type
							)[0]
						) {
							this.disabled.type = false;
							this.disabled.size = true;
							this.selected.type = null;
						} else this.disabled.type = false;

						if (this.selected.type) {
							let genre_child = this.filters.type.filter(
								(e) => e.value == this.selected.type
							)[0];
							if (!genre_child) return;

							this.filters.size = this.getFilterByName(
								genre_child.child
							);
							if (
								!this.filters.size.filter(
									(e) => e.value == this.selected.size
								)[0]
							) {
								this.disabled.size = false;
								this.selected.size = null;
								this.disabled.product = false;
							} else {
								this.disabled.product = false;
								this.disabled.size = false;
							}
						}
					}
				}
				this.filterProducts();
			},
			filterProducts() {
				this.filteredProducts = this.products.filter(
					(e) =>
						e.product.genre == this.selected.genre &&
						e.product.type == this.selected.type &&
						e.product.genreChild == this.selected.genreChild
				);
			},
			add() {
				this.isLoading = true;

				let _p = JSON.parse(JSON.stringify(this.selected.product));

				delete _p._id;
				delete _p.status;
				delete _p.credited;

				_p.product.size = this.selected.size;
				_p.quantity = this.product.quantity;
				_p.place = this.product.place;

				let data = {
					products: [_p],
				};

				data.place = this.product.place;

				if (this.getAppSelected._id != "app") {
					if (this.getAppSelectedType == "institution")
						data.institution = this.getAppSelected._id;
					// if (this.getAppSelectedType == "place")
					//     data.place = this.getAppSelected._id;
				}

				addProduct(data)
					.then((resp) => {
						this.isLoading = false;
						this.$emit("addproduct", resp.data.body);
						this.$buefy.toast.open({
							message: "Artigo adicionado!",
							type: "is-success",
							position: "is-bottom",
						});
						this.modal();
					})
					.catch((error) => {
						console.log(error)
						this.isLoading = false;
						this.$buefy.toast.open({
							message: "Erro a adicionar o artigo!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../../assets/sass/main";

	.blank {
		border-color: transparent;
	}

	.modal {
		text-align: center !important;
	}
</style>