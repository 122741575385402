export const lastLocation = {
    computed: {
        getLastLocation() {
            return this.$store.getters['product/getLastLocation']
        }
    },
    methods: {
        saveLastLocation(obj) {
            this.$store.commit('product/saveLastLocation', obj)
        }
    }
}