<template>
	<div>
		<div class="dropdown is-up is-hoverable">
			<div class="dropdown-trigger">
				<b-button class="is-large is-primary-color" id="fixedbutton" aria-controls="dropdown-menu7">
					<b-icon id="plus" size="is-large" icon="plus" type="is-white"></b-icon>
				</b-button>
				<transition name="list">
					<p v-show="showMessage" class="add-message">Clique aqui para adicionar um artigo!</p>
				</transition>
			</div>
			<div class="dropdown-menu" id="dropdown-menu7" role="menu">
				<div class="dropdown-content">
					<div class="dropdown-item">
						<b-button @click="openImportCSV" class="dropdown-item">Importar CSV</b-button>
						<b-button @click="setAddProductOpen(true)" class="dropdown-item">Adicionar artigo</b-button>
					</div>
				</div>
			</div>
		</div>
		<div v-if="getAppSelected._id != 'app' && getUserInfo.type == 'user'">
			<AddProductInstitution
				v-on:addproduct="addProductToList"
				v-if="isAddProductOpen"
				:class="{ 'is-active': isAddProductOpen }"
			/>
		</div>
		<div v-else>
			<AddProduct
				v-on:addproduct="addProductToList"
				v-if="isAddProductOpen"
				:class="{ 'is-active': isAddProductOpen }"
			/>
		</div>

		<ImportCSV
			v-on:modal="closeImportCSV"
			v-if="modalImportCSVOpen"
			@addproduct="addProductToList"
			:class="{ 'is-active': modalImportCSVOpen }"
		/>
	</div>
</template>

<script>
	import AddProduct from "./AddProduct";
	import AddProductInstitution from "./AddProductInstitution";

	import { product } from "@/mixins/modules/product";

	import ImportCSV from "./ImportCSV";

	export default {
		name: "AddProductButton",
		mixins: [product],
		components: {
			AddProduct,
			AddProductInstitution,
			ImportCSV,
		},
		data() {
			return {
				modalImportCSVOpen: false,
				showMessage: false,
			};
		},
		created() {
			setTimeout(() => {
				this.showMessage = true;
				setTimeout(() => {
					this.showMessage = false;
				}, 3000);
			}, 2000);
		},
		methods: {
			openImportCSV() {
				this.modalImportCSVOpen = true;
			},
			closeImportCSV() {
				this.modalImportCSVOpen = false;
			},
			addProductToList(new_product) {
				this.joinMyProducts(new_product);
				this.setAddProductOpen(false);
				this.closeImportCSV();
				// this.$emit("return");
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "../../assets/sass/main";
	.dropdown-item {
		border: unset !important;
	}

	.add-message {
		position: fixed;
		bottom: 52px;
		right: 85px;
		background-color: $primary-color;
		color: white;
		padding: 5px;
		padding-right: 13px;
		border-radius: 6px;
		transition: all 0.3s;
	}

	#dropdown-menu7 {
		position: fixed !important;
		bottom: 61px !important;
		right: 65px !important;
		left: unset !important;
		width: 50px;
		/* display: block; */
		border-radius: 20px;
	}
	.dropdown.is-up.is-hoverable {
		z-index: 1;
	}

	#fixedbutton {
		border-radius: 100px;
		position: fixed !important;
		bottom: 40px !important;
		right: 40px !important;
	}

	#plus {
		margin-top: 10px;
	}
</style>