import Vue from 'vue'
// initial state
const state = {
    user: {},
    userID: "",
    token: "",
    isProfileOpen: false

}

// getters
const getters = {
    getUserID(state, getters) {
        return state.userID
    },
    getUserInfo(state, getters) {
        return state.user
    },
    getUserToken(state, getters) {
        return state.token
    },
}

// mutations
const mutations = {
    resetStore(s) {
        const initial = JSON.parse(JSON.stringify(state));
        Object.keys(initial).forEach(key => {
            s[key] = initial[key]
        });
    },
    addUser(state, user) {
        Vue.set(state, "user", user)
    },
    addUserID(state, user) {
        state.userID = user
    },
    addUserToken(state, token) {
        state.token = token
    },
    resetUser(state) {
        state.user = {};
        state.userID = "";
        state.token = "";
        state.isProfileOpen = false;
    },
    addUserInstitution(state, institution_id) {
        state.user.institution = {}
        state.user.institution.id = institution_id;
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}