import {
    get,
    post,
    remove
} from "../../utils/http"



const prefix = '/auth/'


export function login(data) {
    let uri = `${prefix}`
    return post(uri, data)
}

export function tokenAuth() {
    let uri = `${prefix}`
    return get(uri)
}