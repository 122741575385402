<template>
	<div>
		<p>
			<b>Imagens</b>
		</p>
		<br />
		<div v-if="value.product.imgs.length > 0" class="container">
			<b-carousel
				animated="fade"
				:value="activeImg"
				@change="updateActiveImg"
				:indicator-custom="true"
				:indicator-inside="false"
				:autoplay="false"
			>
				<b-carousel-item v-for="(img, i) in value.product.imgs" :key="i">
					<figure class="image is-4by3">
						<img class="product-image" :src="img" />
					</figure>
				</b-carousel-item>
				<template slot="indicators" slot-scope="props">
					<figure class="image">
						<img
							class="product-image marginauto custom-indicator"
							:src="value.product.imgs[props.i]"
							:title="props.i"
						/>
					</figure>
					<b-button class="blank" @click.stop="removeImage(props.i)">
						<b-icon class="remove-icon" type="is-danger" icon="close-circle"></b-icon>
					</b-button>
				</template>
			</b-carousel>
		</div>

		<div v-if="value.product.imgs.length < 2">
			<p>
				<b>Adicionar imagens</b>
			</p>
			<br />
			<div v-if="!isMobileDevice">
				<b-upload v-model="new_imgs" multiple drag-drop v-on:input="previewFile">
					<section class="section">
						<div class="content has-text-centered">
							<p>
								<b-icon icon="upload" size="is-large"></b-icon>
							</p>
							<p>Clique para carregar imagens ou arraste para aqui!</p>
						</div>
					</section>
				</b-upload>
			</div>
			<div v-else>
				<TakePicture @picture="previewFile"></TakePicture>
			</div>
		</div>

		<br />
		<br />
		<b-field label="Género">
			<ValidationProvider name="Género" rules="required" v-slot="{ errors }">
				<b-select
					:disabled="disabled.genre"
					v-model="value.product.genre"
					v-on:input="changeInput()"
					name="genre"
					expanded
					placeholder="Selecione o género"
				>
					<option
						:key="i"
						v-for="(option, i) in filters.genre"
						v-bind:value="option.value"
					>{{ option.text }}</option>
				</b-select>
				<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
			</ValidationProvider>
		</b-field>

		<b-field v-if="value.product.genre=='Criança'" label="Género da criança">
			<b-select
				:disabled="disabled.genreChild"
				v-model="value.product.genreChild"
				v-on:input="changeInput()"
				name="size"
				expanded
				placeholder="Selecione o tipo"
			>
				<option
					:key="i"
					v-for="(option, i) in filters.genreChild"
					v-bind:value="option.value"
				>{{ option.text }}</option>
			</b-select>
		</b-field>

		<b-field label="Tipo de Peça">
			<b-select
				:disabled="disabled.type"
				v-model="value.product.type"
				v-on:input="changeInput()"
				name="typeProduct"
				expanded
				placeholder="Selecione o tipo de peça"
			>
				<option
					:key="i"
					v-for="(option, i) in filters.type"
					v-bind:value="option.value"
				>{{ option.text }}</option>
			</b-select>
		</b-field>

		<b-field label="Tamanho">
			<b-select
				:disabled="disabled.size"
				v-model="value.product.size"
				name="size"
				expanded
				placeholder="Selecione o tamanho"
			>
				<option
					:key="i"
					v-for="(option,i) in filters.size"
					v-bind:value="option.value"
				>{{ option.text }}</option>
			</b-select>
		</b-field>

		<b-field label="Cor">
			<ColorSelector v-model="value.product.color" />
		</b-field>

		<b-field label="Descrição">
			<b-input v-model="value.product.description" maxlength="200" type="textarea"></b-input>
		</b-field>

		<b-field
			v-if="getUserInfo.type != 'user' && !(getUserInfo.type == 'glad' && getAppSelectedType == 'app')"
			label="Pontos"
		>
			<b-input type="number" v-model="value.points" name="points" />
		</b-field>

		<b-field
			v-if="getUserInfo.type != 'user' && !(getUserInfo.type == 'glad' && getAppSelectedType == 'app')"
			label="Preço"
		>
			<b-input type="number" v-model="value.price" name="price" />
		</b-field>

		<b-field label="Quantidade">
			<b-input type="number" v-model="value.quantity" name="quantity" />
		</b-field>

		<b-field
			v-if="getUserInfo.type != 'user' && !(getUserInfo.type == 'glad' && getAppSelectedType == 'app')"
			label="Peso (gramas)"
		>
			<b-input type="number" v-model="value.product.weight" name="weight" />
		</b-field>
	</div>
</template>

<script>
	import { filters } from "../../mixins/modules/filters.js";

	import TakePicture from "../Shared/TakePicture";

	import ColorSelector from "../Shared/ColorSelector";

	export default {
		name: "ProductInfo",
		mixins: [filters],
		components: {
			TakePicture,
			ColorSelector,
		},
		props: {
			value: Object,
		},
		created() {
			this.changeInput();
		},
		data() {
			return {
				maxImgs: 2,
				activeImg: 0,
				new_imgs: [],
				filters: {
					genre: [],
					genreChild: [],
					type: [],
					size: [],
				},
				disabled: {
					genre: false,
					genreChild: false,
					type: true,
					size: true,
				},
			};
		},
		methods: {
			updateActiveImg(i) {
				this.activeImg = i;
			},
			previewFile(picture) {
				if (picture && picture == "error") {
					return this.$buefy.toast.open({
						message: "Erro a adicionar imagem!",
						type: "is-danger",
						position: "is-bottom",
					});
				}

				if (
					this.new_imgs.length > this.maxImgs ||
					this.value.product.imgs.length + this.new_imgs.length >
						this.maxImgs
				) {
					this.new_imgs = [];
					return this.$buefy.toast.open({
						message:
							"Não são permitidas mais que " +
							this.maxImgs +
							" imagens!",
						type: "is-danger",
						position: "is-bottom",
					});
				}

				let isBase64 = false;

				try {
					window.atob(picture);
					isBase64 = true;
				} catch (e) {
					console.log(e);
					isBase64 = false;
				}

				if (picture && isBase64) {
					this.value.product.imgs.push(
						"data:image/jpeg;base64," + picture
					);
				} else {
					for (let i of this.new_imgs) {
						const reader = new FileReader();
						reader.readAsDataURL(i);
						reader.onload = (e) => {
							this.value.product.imgs.push(e.target.result);
						};
					}
					this.new_imgs = [];
				}
			},
			removeImage(i) {
				this.updateActiveImg(0);
				this.new_imgs.splice(i, 1);
				this.value.product.imgs.splice(i, 1);
			},
			changeInput() {
				this.filters = {
					genre: [],
					genreChild: [],
					type: [],
					size: [],
				};

				this.filters.genre = this.getFilterByName("genres");
				this.filters.genreChild = this.getFilterByName("genreChild");

				if (this.value.product.genre) {
					if (this.value.product.genre == "Criança") {
						if (this.value.product.genreChild) {
							let genre_child = this.filters.genreChild.filter(
								(e) => e.value == this.value.product.genreChild
							)[0];

							if (!genre_child) return;
							this.filters.type = this.getFilterByName(
								genre_child.child
							);

							if (
								!this.filters.type.filter(
									(e) => e.value == this.value.product.type
								)[0]
							) {
								this.disabled.type = false;
								this.disabled.size = true;
								this.value.product.type = null;
							} else this.disabled.type = false;

							if (this.value.product.type) {
								let genre_child = this.filters.type.filter(
									(e) => e.value == this.value.product.type
								)[0];
								if (!genre_child) return;

								if (typeof genre_child.child == "string")
									this.filters.size = this.getFilterByName(
										genre_child.child
									);
								else {
									for (let i of genre_child.child) {
										this.filters.size.push(
											...this.getFilterByName(i)
										);
									}
								}
								if (
									!this.filters.size.filter(
										(e) => e.value == this.value.product.size
									)[0]
								) {
									this.disabled.size = false;
									this.value.product.size = null;
								} else this.disabled.size = false;
							}
						}
					} else {
                        this.value.product.genreChild = null;
						let genre_child = this.filters.genre.filter(
							(e) => e.value == this.value.product.genre
						)[0];
						if (!genre_child) return;

						this.filters.type = this.getFilterByName(genre_child.child);

						if (
							!this.filters.type.filter(
								(e) => e.value == this.value.product.type
							)[0]
						) {
							this.disabled.type = false;
							this.disabled.size = true;
							this.value.product.type = null;
						} else this.disabled.type = false;

						if (this.value.product.type) {
							let genre_child = this.filters.type.filter(
								(e) => e.value == this.value.product.type
							)[0];
							if (!genre_child) return;

							if (typeof genre_child.child == "string")
								this.filters.size = this.getFilterByName(
									genre_child.child
								);
							else {
								for (let i of genre_child.child) {
									this.filters.size.push(
										...this.getFilterByName(i)
									);
								}
							}

							if (
								!this.filters.size.filter(
									(e) => e.value == this.value.product.size
								)[0]
							) {
								this.disabled.size = false;
								this.value.product.size = null;
							} else this.disabled.size = false;
						}
					}
				}
			},
		},
	};
</script>

<style>
</style>