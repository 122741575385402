<template>
	<b-sidebar
		v-if="$route.name=='SharedProducts' || $route.name=='MyProducts'"
		type="is-white"
		:fullheight="true"
		:open="isSidebarOpen"
		:can-cancel="false"
	>
		<div v-if="isMobile" class="div-close-sidebar">
			<b-button type="is-primary-color" @click="setSidebarOpen(false);">X</b-button>
		</div>

		<div v-show="$route.name=='SharedProducts'" class="margin-10">
			<p>Ordenar:</p>

			<b-select @input="setQuery" v-model="sortProducts" expanded>
				<option value="-1">Mais recente</option>
				<option value="1">Mais antigo</option>
			</b-select>
			<br />

			<div>
				<p>Filtros:</p>
				<div :key="name" v-for="(value, name) in $route.query">
					<div v-if="name != 'sort' && name != 'page'">{{value}}</div>
				</div>
			</div>
			<div>
				<a @click="cleanFilter()">Limpar filtros</a>
			</div>
			<br />
			<p>Descrição</p>
			<b-input
				v-model="filterDescription"
				id="search"
				icon-right="magnify"
				icon-right-clickable
				@icon-right-click="searchDescription"
				@keyup.native.enter="searchDescription"
			></b-input>
			<br />
			<p>Peça</p>
			<treeselect
				:openOnFocus="true"
				v-model="ff_product"
				@input="selectProduct"
				placeholder
				noResultsText="Opção não encontrada..."
				:alwaysOpen="true"
				:flat="true"
				:autoDeselectDescendants="true"
				:autoSelectAncestors="true"
				:options="getNavigationFilters"
			/>

			<div v-if="getAppSelectedType == 'app' || getAppSelectedType == null">
				<br />
				<br />
				<p>Localização</p>
				<treeselect
					v-model="ff_location"
					@input="selectLocation"
					placeholder
					noResultsText="Opção não encontrada..."
					:alwaysOpen="true"
					:flat="true"
					:autoDeselectDescendants="true"
					:autoSelectAncestors="true"
					:options="getLocationFilters"
				/>
			</div>
		</div>

		<div v-show="$route.name=='MyProducts'" class="margin-10">
			<div>
				<p>Estado:</p>
				<b-select
					@input="onStatusSelected"
					v-model="getQueryMyProducts.status"
					expanded
					size="is-small"
				>
					<option v-for="(f,i) in getFilterByName('productsStatus')" :key="i" :value="f.value">{{f.text}}</option>
				</b-select>
			</div>
			<div>
				<a @click="cleanStatus()">Limpar estado</a>
			</div>
		</div>
	</b-sidebar>
</template>

<script>
	import Treeselect from "../vue-treeselect/vue-treeselect.cjs";
	import { filters } from "../../mixins/modules/filters";
	export default {
		name: "Sidebar",
		mixins: [filters],
		components: { Treeselect },
		data() {
			return {
				sortProducts: -1,
				f_product: null,
				f_location: null,
				ff_product: null,
				ff_location: null,
				filterDescription: "",
				filterProduct: [],
				filterLocation: [],
			};
		},
		watch: {
			"$route.name"() {
				this.resetSidebar();
			},
		},
		created() {
			this.setSidebarOpen(true);
			this.resetSidebar();
		},
		methods: {
			cleanFilter() {
				this.ff_product = null;
				this.ff_location = null;
				this.f_product = null;
				this.filterDescription = "";
				this.f_location = null;
				this.setQueryShared({});
				this.$router.push({ name: "SharedProducts" });
			},
			cleanStatus() {
				this.setQueryMyProducts({});
				this.$router.push({ query: this.getQueryMyProducts });
			},
			selectProduct(val) {
				val ? (this.f_product = JSON.parse(val)) : (this.f_product = {});

				this.setQuery();
			},
			selectLocation(val) {
				val ? (this.f_location = JSON.parse(val)) : (this.f_location = {});
				this.setQuery();
			},
			searchDescription() {
				this.setQuery();
			},
			onStatusSelected() {
				let query = this.getQueryMyProducts;
				if (this.$route.query.page) {
					query["page"] = 1;
				}
				this.$router.push({ query });
			},
			setQuery() {
				let query = {};

				for (let i in this.f_product) {
					query[i] = this.f_product[i];
				}

				for (let i in this.f_location) {
					query[i] = this.f_location[i];
				}

				if (this.filterDescription != "") {
					query["description"] = this.filterDescription;
				}

				if (this.sortProducts) {
					query["sort"] = this.sortProducts;
				}

				if (this.$route.query.page) {
					query["page"] = 1;
				}

				this.setQueryShared(query);

				this.$router.push({
					query,
				});
			},
			checkFilter() {
				let p_found = false;
				for (let i in this.$route.query)
					if (i == this.f_product) p_found = true;
				this.filterLocation = this.getLocationFilters(p_found);

				let l_found = false;
				for (let i in this.$route.query)
					if (i == this.f_location) l_found = true;
				this.filterProduct = this.getNavigationFilters(l_found);

				if (this.$route.query.description)
					this.filterDescription = this.$route.query.description;

				if (this.$route.query.sort) {
					this.sortProducts = this.$route.query.sort;
				}
			},
			resetSidebar() {
				this.f_product = null;
				this.f_location = null;
				this.ff_product = null;
				this.ff_location = null;

				if (Object.keys(this.$route.query).length > 0) {
					try {
						this.$route.query.district
							? (this.f_location.district = this.$route.query.district)
							: (this.f_location.district = null);
						this.$route.query.municipality
							? (this.f_location.municipality = this.$route.query.municipality)
							: (this.f_location.municipality = null);

						this.$route.query.genre
							? (this.f_product.genre = this.$route.query.genre)
							: (this.f_product.genre = null);

						this.$route.query.genreChild
							? (this.f_product.genreChild = this.$route.query.genreChild)
							: (this.f_product.genreChild = null);

						this.$route.query.type
							? (this.f_product.type = this.$route.query.type)
							: (this.f_product.type = null);

						this.$route.query.size
							? (this.f_product.size = this.$route.query.size)
							: (this.f_product.size = null);

						this.ff_product = this.f_product
							? JSON.stringify(this.f_product)
							: null;
						this.ff_location = this.ff_location
							? JSON.stringify(this.f_location)
							: null;
					} catch (error) {
						// console.warn(error);
					}
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.div-close-sidebar {
		text-align: right;
		margin-bottom: 37px;
	}

	.div-close-sidebar > button {
		// margin-right: 10px;
		position: fixed;
		left: 205px;
		z-index: 5;
	}
</style>