<template>
	<div>
		<!-- NAVBAR MOBILE -->
		<nav v-click-outside="closeBurger" class="navbar is-fixed-top is-hidden-desktop">
			<div class="navbar-brand">
				<a
					role="button"
					class="navbar-burger burger appnavbar"
					aria-label="menu"
					aria-expanded="false"
					data-target="navbarmenu"
					:class="{ 'is-active': isBurgerOpen }"
					@click="toggleBurguerOpen()"
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
				<a class="navbar-item navbar-center" @click="goToCommercial()">
					<img class="img appnavbar" src="./../../assets/logo/logo_app.svg" alt="ToBeGreen" />
				</a>

				<a v-if="getUserToken" class="navbar-item" @click.stop="toggleNotificationsOpen()">
					<b-icon icon="bell"></b-icon>
					<span class="badge">{{ unreadNotificationsNumber }}</span>
				</a>

				<a
					v-if="getUserToken && user.type != 'admin' && user.type != 'glad'"
					class="navbar-item"
					@click.stop="toggleCartOpen()"
				>
					<b-icon icon="cart"></b-icon>
					<span class="badge">
						{{
						getCart(getAppSelected._id).products.length
						}}
					</span>
				</a>

				<a v-if="getUserToken" class="navbar-item" @click.stop="toggleProfileOpen()">
					<b-icon icon="account"></b-icon>
				</a>
			</div>

			<div id="navbarmenu" :class="{ 'is-active': isBurgerOpen }" class="navbar-menu">
				<b-select
					v-if="getUserToken"
					@input="changeApp"
					class="navbar-item"
					expanded
					v-model="currentApp"
				>
					<option value="app" default>Aplicação</option>
					<optgroup v-if="getUserInfo.institutions.length > 0" label="Instituições">
						<option v-for="i in getUserInfo.institutions" :value="i._id" :key="i._id">{{ i.name }}</option>
					</optgroup>

					<optgroup v-if="getUserInfo.partners.length > 0" label="Munícipios Parceiros">
						<option v-for="i in getUserInfo.partners" :value="i._id" :key="i._id">{{ i.name }}</option>
					</optgroup>

					<optgroup
						v-if="
							getUserInfo.places.length > 0 &&
							getUserInfo.type != 'user'
						"
						label="Locais"
					>
						<option v-for="i in getUserInfo.places" :value="i._id" :key="i._id">{{ i.name }}</option>
					</optgroup>
				</b-select>

				<div v-if="!getUserToken" class="navbar-item">
					<a @click="goToRegister()" class="navbar-item">Registar</a>
					<a
						@click="goToLogin()"
						class="button is-primary-color navbar-item"
						style="color: white !important"
					>Login</a>
				</div>

				<a
					v-if="getUserInfo.type == 'admin'"
					@click.stop="openBackoffice"
					class="navbar-item"
				>Backoffice</a>
				<a
					v-if="getUserInfo._id && getUserInfo.type != 'user'"
					@click.stop="openOrders"
					class="navbar-item"
				>Encomendas</a>
			</div>
		</nav>
		<!-- NAVBAR DESKTOP -->
		<nav class="navbar is-fixed-top is-hidden-touch">
			<div class="navbar-brand" :class="!getUserToken ? 'padding-r' : ''">
				<b-select v-if="getUserToken" @input="changeApp" class="navbar-item" v-model="currentApp">
					<option value="app" default>Aplicação</option>
					<optgroup v-if="user.institutions.length > 0" label="Instituições">
						<option v-for="i in user.institutions" :value="i._id" :key="i._id">{{i.name}}</option>
					</optgroup>

					<optgroup v-if="user.partners.length > 0" label="Munícipios Parceiros">
						<option v-for="i in user.partners" :value="i._id" :key="i._id">{{i.name}}</option>
					</optgroup>

					<optgroup v-if="user.places.length > 0 && user.type != 'user'" label="Locais">
						<option v-for="i in user.places" :value="i._id" :key="i._id">{{i.name}}</option>
					</optgroup>
				</b-select>
			</div>

			<div class="navbar-item navbar-center">
				<a class="navbar-item" @click="goToCommercial()">
					<img class="img appnavbar" src="./../../assets/logo/logo_app.svg" alt="ToBeGreen" />
				</a>
			</div>
			<div class="navbar-end">
				<div class="navbar-end">
					<a v-if="user.type=='admin'" @click.stop="openBackoffice" class="navbar-item">Backoffice</a>
					<a
						v-if="getUserInfo._id && user.type!='user'"
						@click.stop="openOrders"
						class="navbar-item"
					>Encomendas</a>

					<a v-if="getUserToken" class="navbar-item" @click.stop="toggleNotificationsOpen()">
						<b-icon icon="bell"></b-icon>
						<span class="badge">{{unreadNotificationsNumber}}</span>
					</a>

					<a
						v-if="getUserToken && user.type != 'admin' && user.type != 'glad'"
						class="navbar-item"
						@click.stop="toggleCartOpen()"
					>
						<b-icon icon="cart"></b-icon>
						<span class="badge">{{getCart(getAppSelected._id).products.length}}</span>
					</a>

					<a v-if="getUserToken" class="navbar-item" @click.stop="toggleProfileOpen()">
						<b-icon icon="account"></b-icon>
					</a>
					<div class="navbar-item">
						<a v-if="!getUserToken" @click="goToRegister()" style="margin-right: 20px;">Registar</a>
						<a v-if="!getUserToken" @click="goToLogin()" class="button is-primary-color">Login</a>
					</div>
				</div>
			</div>
		</nav>
		<b-loading :active.sync="isLoading"></b-loading>
		<CartModal
			@gotocart="goToCart"
			v-click-outside="outsideCart"
			v-if="isCartOpen"
			:cart="getCart(getAppSelected._id)"
		/>

		<ProfileModal
			@gotoprofile="goToProfile"
			@logout="logOut"
			v-click-outside="outsideProfile"
			v-if="isProfileOpen"
		/>

		<NotificationsModal v-click-outside="outsideNotifications" v-if="isNotificationsOpen" />
	</div>
</template>

<script>
	//Mixins
	import { cart } from "../../mixins/modules/cart";
	import { order } from "../../mixins/modules/order";

	import { notifications } from "../../mixins/modules/notifications.js";

	import CartModal from "./CartModal";
	import ProfileModal from "./ProfileModal";
	import NotificationsModal from "./NotificationsModal";

	export default {
		name: "AppNavBar",
		components: {
			CartModal,
			ProfileModal,
			NotificationsModal,
		},
		props: {},
		mixins: [cart, order, notifications],
		created() {
			this.user = this.getUserInfo;
			this.currentApp = this.getAppSelected._id;
			this.cart = this.getCart(this.getAppSelected._id);
		},

		watch: {
			getCart: function (oldCart, newCart) {
				this.cart = this.getCart(this.getAppSelected._id);
			},
			getAppSelected: function (oldValue, newValue) {
				this.cart = this.getCart(this.getAppSelected._id);
			},
		},
		mounted() {},
		data() {
			return {
				isLoading: false,
				currentApp: "app",
				user: {},
				cart: [],
			};
		},
		methods: {
			changeApp() {
				let current_app = this.getAppSelected;
				if (current_app._id == this.currentApp) return;

				let app = {
					_id: "app",
				};

				for (let i of this.user.institutions)
					if (this.currentApp == i._id) {
						app = i;
						this.changeAppSelectedType("institution");
					}

				for (let i of this.user.places)
					if (this.currentApp == i._id) {
						app = i;
						this.changeAppSelectedType("place");
					}

				for (let i of this.user.partners)
					if (this.currentApp == i._id) {
						app = i;
						this.changeAppSelectedType("partner");
					}

				if (app._id == "app") this.changeAppSelectedType("app");
				this.changeAppSelected(app);
				this.isLoading = true;
				this.$router.push({ name: "SharedProducts" });
				this.$router.go();
			},
			closeBurger() {
				this.setBurguerOpen(false);
			},
			outsideCart() {
				this.setCartOpen(false);
			},
			outsideProfile() {
				this.setProfileOpen(false);
			},
			outsideNotifications() {
				this.setNotificationsOpen(false);
			},
			goToCommercial() {
				this.$router.push({ name: "ComercialPage" });
			},
			goToHome() {
				this.$router.push({ name: "SharedProducts" });
			},
			goToCart() {
				this.toggleCartOpen();
				this.$router.push({ name: "Cart" });
			},
			goToProfile() {
				this.toggleProfileOpen();
				this.$router.push({ name: "Profile" });
			},
			openBackoffice() {
				this.$router.push({ name: "Backoffice-Institutions" });
			},
			openOrders() {
				this.$router.push({ name: "Orders" });
			},
			logOut() {
				this.resetStores();
				this.$router.push({ name: "ComercialPage" });
			},
			cleanFilter() {
				this.$router.push({ name: "SharedProducts" });
			},
			goToLogin() {
				// if (this.isBurgerOpen) this.toggleBurguerOpen();

				this.$router.push({ name: "Login" });
			},
			goToRegister() {
				this.$router.push({ name: "Register" });
			},
		},
		computed: {},
	};
</script>

<style scoped lang="scss">
	.navbar {
		/* z-index: 45 !important; */
	}

	.padding-r {
		padding-right: 155px;
	}

	.profile-picture {
		padding-right: 0px !important;
	}

	.current-app {
		border-color: unset !important;
		font-size: 2rem;
	}

	.badge {
		position: absolute;
		top: 4px;
		right: 0px;
	}
</style>