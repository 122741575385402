<template>
  <div class="login">
    <section class="hero is-fullheight">

      <FormLogin v-if="page === 'Login'" />
      <FormRegister v-else-if="page === 'Register'" />
      <FormRecovery v-else-if="page === 'Recovery'" />
      <FormRecoveryChange v-else />

    </section>
  </div>
</template>

<script>
  import FormLogin from "@/components/Forms/FormLogin.vue";
  import FormRegister from "@/components/Forms/FormRegister.vue";
  import FormRecovery from "@/components/Forms/FormRecovery.vue";
  import FormRecoveryChange from "@/components/Forms/FormRecoveryChange.vue";

  export default {
    name: "Login",
    components: {
      FormLogin,
      FormRegister,
      FormRecovery,
      FormRecoveryChange
    },
    data() {
      return {
        page: "Login"
      };
    },
    watch: {
      $route() {
        this.watchRoute();
      }
    },
    methods: {
      register() {
        this.$router.push("/register");
      },
      login() {
        this.$router.push("/login");
      },
      recovery() {
        this.$router.push("/recovery");
      },
      watchRoute() {
        if (this.$route.path == "/login") {
          this.page = "Login";
        } else if (this.$route.path == "/register") {
          this.page = "Register";
        } else if (this.$route.path == "/recovery") {
          this.page = "Recovery";
        } else if (this.$route.path == "/recover_password") {
          this.page = "RecoveryChange";
        }
      }
    },
    created() {
      this.watchRoute();
    }
  };
</script>

<style lang="css">


</style>