<template>
	<div>
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>

		<div class="columns">
			<div class="column">
				<a @click="$router.go(-1)" class="button is-text">
					<b-icon type="is-secondary-color" icon="chevron-left"></b-icon>
					<span>&nbsp; página anterior</span>
				</a>
			</div>
		</div>
		<div class="columns">
			<div class="column is-10 is-10-touch is-offset-1 is-offset-1-touch">
				<h5 class="title is-5 has-text-centered">Encomendas</h5>

				<b-select @input="changeApp" v-model="appSelected">
					<option :value="null" default>Todos</option>
					<option :value="{_id: 'app'}">Aplicação</option>
					<optgroup v-if="getUserInfo.institutions.length > 0" label="Instituições">
						<option
							:key="i"
							v-for="(option, i) in getUserInfo.institutions"
							:value="option"
						>{{option.name}}</option>
					</optgroup>

					<optgroup v-if="getUserInfo.places.length > 0" label="Locais">
						<option
							:key="i"
							v-for="(option, i) in getUserInfo.places"
							app_type="place"
							:value="option"
						>{{option.name}}</option>
					</optgroup>
				</b-select>
				<br />
				<!-- <div class="columns">
				<div class="column">-->
				<b-table
					:data="isEmpty ? [] : data"
					:mobile-cards="true"
					:paginated="true"
					:per-page="10"
					default-sort-direction="desc"
					default-sort="id"
					@page-change="changePage"
					:current-page.sync="currentPage"
					detailed
				>
					<b-table-column field="id" sortable label="ID" centered v-slot="props">{{ props.row.id }}</b-table-column>

					<b-table-column
						sortable
						field="order_date"
						label="Data"
						width="220"
						centered
						v-slot="props"
					>{{ moment(props.row.order_date).format('L')}} {{moment(props.row.order_date).format('LT')}}</b-table-column>

					<b-table-column
						field="status"
						sortable
						label="Estado"
						centered
						v-slot="props"
					>{{ getStatus(props.row.status) }}</b-table-column>

					<b-table-column
						field="payment_type"
						sortable
						label="Tipo Pagamento"
						centered
						v-slot="props"
					>{{ getPaymentType(props.row.payment_type) }}</b-table-column>

					<b-table-column field="price" label="Valor" v-slot="props">
						<ul class="menu-list">
							<li>
								<p>Pontos: {{ props.row.price && props.row.price.points ? props.row.price.points : 0 }}</p>
							</li>
							<li>
								<p>Dinheiro: {{ props.row.price && props.row.price.money ? props.row.money.points : 0 }}</p>
							</li>
						</ul>
					</b-table-column>
					<b-table-column centered v-slot="props">
						<b-button type="is-primary-color" @click="$router.push('/order/' + props.row._id)">
							<b-icon size="is-small" icon="eye"></b-icon>
						</b-button>
					</b-table-column>

					<b-table-column field="delivery" label centered v-slot="props">
						<b-button
							class="is-primary-color"
							:disabled="props.row.status !='waiting'"
							@click="change_status_shopping(props.row._id)"
						>Entregar</b-button>
					</b-table-column>

					<template slot="detail" slot-scope="props">
						<article v-for="(item) in props.row.products" v-bind:key="item._id" class="media">
							<figure class="media-left">
								<p class="image is-64x64">
									<img v-if="item.product" :src="checkImg(item.product.product.imgs[0])" />
									<img v-else :src="checkImg(null)" />
								</p>
							</figure>
							<div class="media-content">
								<div class="content">
									<div v-if="item.product">
										<p>
											<strong>ID:</strong>
											{{ item.product.id}}
										</p>
										<p>
											<strong>Género:</strong>
											{{ item.product.product.genre}}
											{{item.product.product.genreChild ? '- ' + item.product.product.genreChild : ''}}
										</p>
										<p>
											<strong>Tipo</strong>
											{{ item.product.product.type}}
										</p>
										<p>
											<strong>Tamanho:</strong>
											{{ item.product.product.size}}
										</p>
										<p>
											<strong>Cor:</strong>
											{{ item.product.product.color}}
										</p>
										<p>
											<strong>Descrição:</strong>
											{{ item.product.product.description}}
										</p>
									</div>
									<p v-else>
										<strong>Não foi possível obter os dados do artigo !</strong>
										<br />
									</p>
								</div>
							</div>
						</article>
					</template>

					<template slot="empty">
						<section class="section">
							<div class="content has-text-grey has-text-centered">
								<p>
									<!-- <b-icon icon="emoticon-sad" size="is-large"></b-icon> -->
								</p>
								<p>Ainda não adquiriu nenhum artigo</p>
							</div>
						</section>
					</template>
				</b-table>
				<!-- </div>
				</div>-->
			</div>
		</div>
	</div>
</template>


<script>
	// import { get_users ,complete_Data} from "./../api/user/user";
	import { getOrders, putOrders } from "./../api/order/order";

	//MIXINS
	import { filters } from "./../mixins/modules/filters.js";

	export default {
		name: "Shops",
		created() {
			this.shoppings();
			this.currentPage = this.$route.query.page
				? parseInt(this.$route.query.page)
				: 1;
		},
		components: {},
		mixins: [filters],
		data() {
			return {
				dropFiles: [],
				sortIcon: "arrow-up",
				sortIconSize: "is-small",
				defaultSortDirection: "asc",
				currentPage: 1,
				checkedRows: [],
				previewImage: null,
				color: null,
				isLoading: false,
				perPage: 10,
				isEmpty: false,
				description: null,
				selectedGenre: null,
				selectedSize: null,
				selectedOrigin: null,
				data: [],
				appSelected: null,
				appSelectedType: null,
			};
		},
		methods: {
			getStatus(status) {
				let name = this.getFilterByName("ordersStatus").filter(
					(e) => e.value.toLowerCase() == status.toLowerCase()
				);
				if (name.length <= 0) return status;
				return name[0].text;
			},
			getPaymentType(status) {
				let name = this.getFilterByName("paymentTypes").filter(
					(e) => e.value.toLowerCase() == status.toLowerCase()
				);
				if (name.length <= 0) return status;
				return name[0].text;
			},
			toggle(row) {
				this.$refs.table.toggleDetails(row);
			},
			changeApp() {
				if (this.appSelected) {
					for (let i of this.getUserInfo.institutions)
						if (this.appSelected._id == i._id) {
							this.appSelectedType = "institution";
						}

					for (let i of this.getUserInfo.places)
						if (this.appSelected._id == i._id) {
							this.appSelectedType = "place";
						}
				} else this.appSelectedType = null;

				this.shoppings();
			},
			shoppings() {
				this.isLoading = true;

				let query = null;

				if (this.appSelected) {
					query = `?`;
					if (this.appSelected._id == "app") {
						query = query + "institution=null";
					} else {
						if (this.appSelectedType == "institution")
							query = query + "institution=" + this.appSelected._id;
						else if (this.appSelectedType == "place")
							query = query + "place=" + this.appSelected._id;
					}
				}

				getOrders(query)
					.then((response) => {
						this.isLoading = false;
						this.data = JSON.parse(JSON.stringify(response.data.body));
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;
					});
			},
			change_status_shopping(shopping_id) {
				this.isLoading = true;
				putOrders(shopping_id, {
					status: "delivered",
				})
					.then((response) => {
						for (let i in this.data) {
							if (this.data[i]._id == response.data.body._id) {
								this.$set(this.data, i, response.data.body);
							}
						}

						this.isLoading = false;
						this.$buefy.toast.open({
							message: "Atualizado com sucesso!",
							type: "is-success",
							position: "is-bottom",
						});
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;
						this.$buefy.toast.open({
							message: "Não foi possível atualizar!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
			changePage(a) {
				this.scrollToTop();
				if (this.$route.query != undefined) {
					let q = JSON.parse(JSON.stringify(this.$route.query));
					q.page = a;
					this.$router.push({ query: q });
				} else {
					this.$router.push({ query: { page: a } });
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/sass/main";

	img.pending {
		max-height: 100px;
		max-width: 100px;
		height: auto;
		width: auto;
		margin: auto;
	}

	.table th {
		color: $primary-color !important;
	}
	// .media-content {
	// 	.content {
	// 		p {
	// 			margin-bottom: 0;
	// 		}
	// 	}
	// }
</style>