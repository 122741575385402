<template>
  <div>
    <h1 class="title is-4">Alterar os meus dados pessoais</h1>
    <ValidationObserver v-slot="{ handleSubmit }">
      <div class="columns">
        <div class="column">
          <b-field label="Nome">
            <ValidationProvider name="Nome" rules="required" v-slot="{ errors }">
              <b-input v-model="user.info.name" type="text" name="name" />
              <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
            </ValidationProvider>
          </b-field>
          <b-field label="Email">
            <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
              <b-input v-model="user.auth.email" type="text" name="email" />
              <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
            </ValidationProvider>
          </b-field>
          <b-field label="Nº Cliente Serviços Água/Resíduos">
            <b-input v-model="user.info.water_id" type="text" name="waterID" />
          </b-field>
          <b-field label="Contacto">
            <ValidationProvider name="Contacto" rules="required" v-slot="{ errors }">
              <b-input v-model="user.info.contact" type="text" name="contact" />
              <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
            </ValidationProvider>
          </b-field>

          <b-field label="Data de Nascimento">
            <ValidationProvider name="Género" rules="required" v-slot="{ errors }">
              <b-datepicker v-model="user.info.birthday" :show-week-number="false" placeholder="Clica para selecionar"
                :max-date="maxDate"></b-datepicker>
              <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
            </ValidationProvider>
          </b-field>
          <div class="block">
            <label class="label">Género</label>
            <b-radio type="is-dark" v-model="user.info.genre" name="genre" native-value="F">Feminino</b-radio>
            <b-radio type="is-dark" v-model="user.info.genre" name="genre" native-value="M">Masculino</b-radio>
          </div>
          <div class="block">
            <label class="label">Deseja receber as comunicações via:</label>
            <b-checkbox type="primary-color" v-model="user.communication">
              Email
            </b-checkbox>
          </div>
        </div>
        <div class="column">
          <b-field label="Morada">
            <ValidationProvider name="Morada" rules="required" v-slot="{ errors }">
              <b-input v-model="user.info.location.address" type="text" name="address" />
              <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
            </ValidationProvider>
          </b-field>

          <b-field label="País">
            <ValidationProvider name="País" rules="required" v-slot="{ errors }">
              <b-select :disabled="countryDisabled" @input="getDistricts()" v-model="countrySelected" name="origin"
                expanded>
                <option :key="i" v-for="(option, i) in countries" v-bind:value="option">
                  {{ option.country }}
                </option>
              </b-select>
              <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
            </ValidationProvider>
          </b-field>

          <b-field label="Distrito">
            <ValidationProvider name="Distrito" rules="required" v-slot="{ errors }">
              <b-select :disabled="districtDisabled" @input="getMunicipalities()" v-model="districtSelected"
                name="origin" expanded>
                <option :key="i" v-for="(option, i) in districts" v-bind:value="option">
                  {{ option.district_name }}
                </option>
              </b-select>
              <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
            </ValidationProvider>
          </b-field>

          <b-field label="Município">
            <ValidationProvider name="Município" rules="required" v-slot="{ errors }">
              <b-select :disabled="municipalityDisabled" v-model="municipalitySelected" name="origin" expanded>
                <option :key="i" v-for="(option, i) in municipalities" v-bind:value="option">
                  {{ option.municipality_name }}
                </option>
              </b-select>
              <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
            </ValidationProvider>
          </b-field>
        </div>
      </div>
      <b-button :loading="isLoading" @click="handleSubmit(add)" class="is-primary-color">
        Atualizar
      </b-button>
    </ValidationObserver>
  </div>
</template>

<script>

  import {
    complete_Data,
    updateProfile
  } from "./../../api/user/user";
  
  import Districts from '../../config/Districts';
  import Municipalities from '../../config/Municipality';
  import Countries from '../../config/Countries';


  export default {
    name: "profile",
    components: {},
    props: {},
    created() {
      this.user = this.getUserInfo;
      this.user.info.birthday = this.user.info.birthday ? new Date(this.user.info.birthday) : null;
      this.radioGenre = this.user.info.genre;
      this.radioCommunication = this.user.communication;

      if (this.user.info.location.country) {
        for (let c of this.countries)
          if (c.country == this.user.info.location.country)
            this.countrySelected = c;
        this.getDistricts();
        if (this.user.info.location.district) {
          for (let c of this.districts)
            if (c.district_name == this.user.info.location.district)
              this.districtSelected = c;
          this.getMunicipalities();
          if (this.user.info.location.municipality) {
            for (let c of this.municipalities)
              if (c.municipality_name == this.user.info.location.municipality)
                this.municipalitySelected = c;
          }
        }

      }

    },
    mounted() {},

    data() {
      return {
        isLoading: false,
        maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 5),
        selectedOrigin: null,
        user: null,
        districts: [],
        municipalities: [],
        municipalityDisabled: true,
        municipalitySelected: null,
        districtDisabled: true,
        districtSelected: null,
        countries: Countries,
        countrySelected: null,
        countryDisabled: false
      };
    },
    watch: {},
    methods: {
      getDistricts() {
        this.districts = [];
        this.districtSelected = null;
        for (let m of Districts) {
          if (this.countrySelected.country_id == m.country_id)
            this.districts.push(m);
        }
        this.districtDisabled = false;
      },
      getMunicipalities() {
        this.municipalities = [];
        this.municipalitySelected = null;
        for (let m of Municipalities) {
          if (this.districtSelected.district_code == m.district_code)
            this.municipalities.push(m);
        }
        this.municipalityDisabled = false;
      },
      add() {
        this.isLoading = true;
        this.user.info.location.country = this.countrySelected.country;
        this.user.info.location.district = this.districtSelected.district_name;
        this.user.info.location.municipality = this.municipalitySelected.municipality_name;

        let self = this;
        
        updateProfile(this.user)
          .then(response => {
            this.isLoading = false;
            self.addUser(response.data.body);
            this.$buefy.toast.open({
              message: "Atualização efetuada com sucesso!",
              type: "is-success",
              position: "is-bottom"
            });
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
            this.$buefy.toast.open({
              message: "Não foi possível atualizar!",
              type: "is-danger",
              position: "is-bottom"
            });
          });
      }
    }
  };
</script>