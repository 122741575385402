export const product = {
    computed: {
        getStorageProducts() {
            return this.$store.getters['product/getProducts']
        },
        getSharedProducts() {
            return this.$store.getters['product/getSharedProducts']
        },
        getPendingProducts() {
            return this.$store.getters['product/getPendingProducts']
        },
        getMyProducts() {
            return this.$store.getters['product/getMyProducts']
        },
        getRecycledProducts() {
            return this.$store.getters['product/getRecycledProducts']
        },
    },
    methods: {
        resetProductStoreByKey(key) {
            this.$store.commit('product/resetProductStoreByKey', key)
        },

        // SHARED
        getSharedProductsByPage(page) {
            return this.$store.getters['product/getSharedProductsByPage'](page)
        },
        addSharedPage(obj) {
            this.$store.commit('product/addSharedPage', obj)
        },
        setSharedCount(count) {
            this.$store.commit('product/setSharedCount', count)
        },

        //PENDING
        getPendingProductsByPage(page) {
            return this.$store.getters['product/getPendingProductsByPage'](page)
        },
        addPendingPage(obj) {
            this.$store.commit('product/addPendingPage', obj)
        },
        setPendingCount(count) {
            this.$store.commit('product/setPendingCount', count)
        },
        setPendingProducts(obj) {
            this.$store.commit('product/setPendingProducts', obj)
        },
        addPendingProduct(obj) {
            this.$store.commit('product/addPendingProduct', obj)
        },
        joinPendingProducts(obj) {
            this.$store.commit('product/joinPendingProduct', obj)
        },
        removePendingProduct(_id) {
            this.$store.commit('product/removePendingProduct', _id)
        },
        updatePendingProduct(obj) {
            this.$store.commit('product/updatePendingProduct', obj)
        },

        // MY PRODUCTS
        getMyProductsByPage(page) {
            return this.$store.getters['product/getMyProductsByPage'](page)
        },
        addMyProductsPage(obj) {
            this.$store.commit('product/addMyProductsPage', obj)
        },
        setMyProductsCount(count) {
            this.$store.commit('product/setMyProductsCount', count)
        },
        setMyProducts(obj) {
            this.$store.commit('product/setMyProducts', obj)
        },
        addMyProduct(obj) {
            this.$store.commit('product/addMyProduct', obj)
        },
        joinMyProducts(obj) {
            this.$store.commit('product/joinMyProduct', obj)
        },
        removeMyProduct(_id) {
            this.$store.commit('product/removeMyProduct', _id)
        },
        updateMyProduct(obj) {
            this.$store.commit('product/updateMyProduct', obj)
        },


        setRecycledProducts(obj) {
            this.$store.commit("product/setRecycledProducts", obj);
        },


        removeProduct(_id) {
            this.$store.commit('product/removeMyProduct', _id)
            this.$store.commit('product/removePendingProduct', _id)
        },


    }
}