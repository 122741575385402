<template>
  <div class="columns">
    <div class="column is-8-desktop is-offset-2-desktop is-10-touch is-offset-1-touch">
      <div class="control">
        <div class="columns">
          <div class="column">
            <label class="radio">
              <b-radio type="is-dark" @input="triggerPaymentType('points')" v-model="payment_type" name="payment_type" native-value="points">
                Compra por
                pontos</b-radio>
              <!-- <b-radio type="is-dark" @input="triggerPaymentType('money')" v-model="payment_type" name="payment_type" native-value="money">
                Compra
                por dinheiro</b-radio> -->
              <!-- <input type="radio" name="answer" checked />
              Compra por pontos -->
            </label>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-table :data="data" :columns="columns"></b-table>
          </div>
        </div>
      </div>
    </div>
    <div class="column"></div>
  </div>
</template>


<script>
  //MIXINS
  import {
    cart
  } from "../../mixins/modules/cart";


  export default {
    name: "Payment",
    mixins: [cart],
    created() {
      let items = this.getCart(this.getAppSelected._id).products

      let total_points = this.getUserInfo.points;
      let value = 0;
      let new_total_points = 0;
      let missed = 0;

      for (const i in items) {
        value -= items[i].points;
      }

      if (value * -1 >= total_points) {
        new_total_points = 0;
        missed = total_points + value;
      } else {
        new_total_points = total_points + value;
      }

      this.data = [{
        total_points,
        value,
        new_total_points,
        missed: missed * -1
      }];

      this.$emit("paymentvalues", this.data[0]);
      this.$emit("paymenttype", this.payment_type)
    },
    data() {
      return {
        payment_type: 'points',
        data: [{
          total_points: "20",
          value: "-5",
          new_total_points: "15",
          missed: 0
        }],
        columns: [{
            field: "total_points",
            label: "Pontos da sua conta",
            centered: true
          },
          {
            field: "value",
            label: "Valor dos artigos",
            centered: true
          },
          {
            field: "new_total_points",
            label: "Pontos totais da sua conta",
            centered: true
          },
          {
            field: "missed",
            label: "Pontos em falta",
            centered: true
          }
        ]
      };
    },
    methods: {
      triggerPaymentType(type) {
        this.changePaymentType(type);
        this.$emit("paymenttype", type)
      }
    }
  };
</script>