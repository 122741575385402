export default {


    // BASE_URL: 'http://localhost:8081',
    // BASE_URL: 'https://215c3f6d939c.ngrok.io',
    // BASE_URL: "https://tobegreen-dot-ioapi2.appspot.com",
    // GOOGLE_MAPS_ID: 'AIzaSyBHwxTUl448uCrtIZ29tiaIlStD603WDWc',

    // FACEBOOK_ID: '2257332401160921',
    // GOOGLE_ID: "910024553034-gcr37c45d0sb403lnkj53tqieuekd5r1.apps.googleusercontent.com",
    // REFRESH_CHAT_TIME: 15000, // milliseconds


}