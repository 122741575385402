import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import router from './router'
import i18n from './i18n'

import '@mdi/font/css/materialdesignicons.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import 'vue-swatches/dist/vue-swatches.css'

import VueSocketio from 'vue-socket.io'
Vue.use(new VueSocketio({
  debug: true,
  connection: process.env.VUE_APP_API_URL,
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  },
}));

import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

import moment from 'moment'
moment.locale('pt');
Vue.prototype.moment = moment

import * as vClickOutside from 'v-click-outside-x';
Vue.use(vClickOutside);

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.use(Buefy, {
  customIconPacks: {
    tbg: {
      iconPrefix: "tbg-",
      sizes: {
        'default': 'is-size-5',
        'is-small': 'is-size-5',
        'is-medium': 'is-size-4',
        'is-large': 'is-size-3'
      }
    }
  }
});

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_ID
  }
})
import {
  sendError
} from "./api/apiError";

if (process.env.NODE_ENV === "production")
  Vue.config.errorHandler = (err, vm, info) => {

    console.log({
      err,
      vm,
      info
    })
    sendError({
      fields: [{
        title: "User ID",
        value: vm.getUserID
      }],
      text: [{
        title: "Stack",
        code: err.stack
      }, {
        title: "App selected",
        code: JSON.stringify(vm.getAppSelected)
      }]
    });
    throw err;
  };


//Vee-Validate
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize
} from 'vee-validate';
import pt from 'vee-validate/dist/locale/pt_PT.json';
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend('password', {
  params: ['target'],
  validate(value, {
    target
  }) {
    return value === target;
  },
  message: 'As passwords têm de ser iguais, por favor repita!'
});

localize('pt', pt);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import "./assets/sass/main.scss"

import "./mixins/index";

Vue.config.productionTip = false

import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
  id: 'UA-190877158-1',
  debug: {
    enabled: true,
  },
  router
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')