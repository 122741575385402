<template>
	<div>
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>

		<div class="columns">
			<div class="column">
				<a @click="$router.go(-1)" class="button is-text">
					<b-icon type="is-secondary-color" icon="chevron-left"></b-icon>
					<span>&nbsp; página anterior</span>
				</a>
			</div>
		</div>

		<div v-if="order" class="column is-10 is-offset-1">
			<div class="columns">
				<div class="column is-one-third">
					<h5 class="title is-5">Informações da encomenda:</h5>

					<p v-if="order.institution">
						<b>Efetuado na instituição:</b>
						{{order.institution.name}}
					</p>
					<p v-else>Efetuado na aplicação</p>
					<p>
						<b>ID:</b>
						{{order.id}}
					</p>
					<p>
						<b>Estado:</b>
						{{ordersStatus[order.status]}}
					</p>
					<p>
						<b>Data de encomenda:</b>
						{{moment(order.order_date).format('L')}}
						{{moment(order.order_date).format('LT')}}
					</p>
					<p>
						<b>Tipo de pagamento:</b>
						{{paymentTypes[order.payment_type]}}
					</p>
					<p v-if="order.payment_type == 'points'">
						<b>Pontos usados:</b>
						{{order.price.points}}
					</p>
					<p v-else>
						<b>Preço:</b>
						{{order.price.money}}€
					</p>
				</div>
				<div class="column is-one-third">
					<h5 class="title is-5">Utilizador:</h5>
					<p>
						<b>Nome:</b>
						{{order.user.info.name}}
					</p>
					<p>
						<b>Contacto telemóvel:</b>
						{{order.user.info.contact}}
					</p>
					<p>
						<b>Email:</b>
						{{order.user.auth.email}}
					</p>
				</div>
				<div class="column is-one-third">
					<h5 class="title is-5">Local de recolha:</h5>
					<p>
						<b>Nome:</b>
						{{order.place.name}}
					</p>
					<p>
						<b>Morada:</b>
						{{order.place.address}}
					</p>
					<p>
						<b>Localidade:</b>
						{{order.place.municipality}}, {{order.place.district}},
						{{order.place.country}}
					</p>
					<p>
						<b>Código postal:</b>
						{{order.place.zip_code}}
					</p>
					<p>
						<b>Coordenadas:</b>
						{{order.place.coordinates.lat}}, {{order.place.coordinates.lng}}
					</p>
				</div>
			</div>

			<br />

			<b-table :data="order.products" :hoverable="true" :mobile-cards="true">
				<b-table-column field="img" label width="150" centered v-slot="props">
					<ImgZoom :imgsrc="props.row.product.product.imgs[0]" />
				</b-table-column>

				<b-table-column label="ID" centered v-slot="props">
					<a
						@click="$router.push('/product/' + props.row.product._id)"
						target="__blank"
					>{{ props.row.product.id }}</a>
				</b-table-column>

				<b-table-column
					label="Descrição"
					centered
					v-slot="props"
				>{{ props.row.product.product.description }}</b-table-column>

				<b-table-column label="Cor" centered v-slot="props">{{ props.row.product.product.color }}</b-table-column>

				<b-table-column label="Género" centered v-slot="props">{{ props.row.product.product.genre }}</b-table-column>
				<b-table-column label="Tamanho" centered v-slot="props">{{ props.row.product.product.size }}</b-table-column>
				<b-table-column label="Tipo" centered v-slot="props">{{ props.row.product.product.type }}</b-table-column>

				<b-table-column label="Quantidade" centered v-slot="props">{{ props.row.quantity }}</b-table-column>
			</b-table>
			<br />
			<div v-if="getUserInfo.type != 'user'" class="level">
				<div class="level-right level-item">
					<b-button
						:loading="isLoadingButton"
						v-if="order.status == 'waiting'"
						@click="updateStatus"
						class="is-primary-color"
					>Encomenda pronta</b-button>
					<b-button v-else disabled class="is-primary-color">Encomenda entregue</b-button>
				</div>
			</div>
		</div>
	</div>
	<!-- </div> -->
</template>


<script>
	import { getOrderById, putOrders } from "../api/order/order.js";

	import ImgZoom from "../components/Shared/ImgZoom";

	import OrderStatus from "@/config/OrdersStatus.json";
	import PaymentTypes from "@/config/PaymentTypes.json";

	export default {
		name: "Order",
		components: {
			ImgZoom,
		},
		data() {
			return {
				isLoading: false,
				isLoadingButton: false,
				fullPage: true,
				order: null,
				ordersStatus: OrderStatus,
				paymentTypes: PaymentTypes,
			};
		},
		created() {
			this.getOrder(this.$route.params.id);
		},
		watch: {
			"$route.params.id"() {
				this.getOrder(this.$route.params.id);
			},
		},
		methods: {
			getOrder(id) {
				this.isLoading = true;
				getOrderById(id)
					.then((order) => {
						console.log("got order");
						this.order = order.data.body;
						this.isLoading = false;
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;
					});
			},
			updateStatus() {
				this.isLoadingButton = true;
				putOrders(this.order._id, {
					status: "delivered",
				})
					.then((response) => {
						this.isLoadingButton = false;
						this.order.status = "delivered";
					})
					.catch((error) => {
						this.isLoadingButton = false;
						console.log(error);
					});
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../assets/sass/main";

	.btn {
		width: 85px;
	}

	.center {
		text-align: center;
	}

	.desc {
		margin-top: 3.5rem;
		margin-left: 2rem;
		margin-right: 2rem;
	}

	.m-image {
		max-height: 80vh;
	}

	.image > img {
		max-width: 100%;
		max-height: 80vh;
		height: auto;
		width: auto;
		margin: auto;
	}
</style>