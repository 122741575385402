import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import navbar from './modules/navbar'
import cart from './modules/cart'
import app from './modules/app'
import user from './modules/user'
import map from './modules/map'
import order from './modules/order'
import product from './modules/products'
import device from './modules/device';
import notifications from './modules/notifications';
import backoffice from './modules/backoffice'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    navbar,
    cart,
    app,
    user,
    map,
    order,
    product,
    device,
    notifications,
    backoffice
  },
  plugins: [vuexLocal.plugin]
})