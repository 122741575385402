export const order = {
    computed: {
        getSelectedPlace() {
            return this.$store.getters['order/getSelectedPlace']
        },
        getSelectedPlaceID() {
            return this.$store.getters['order/getSelectedPlaceID']
        }
    },
    methods: {
        setSelectedPlace: function (place) {
            this.$store.commit('order/setSelectedPlace', place)
        },
        resetProcess: function () {
            this.$store.commit('order/resetProcess')
        }
    }
}