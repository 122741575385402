<template>
	<div style="padding-bottom: 10vh;">
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>

		<h5 class="title is-5 has-text-centered">Peças em reciclagem</h5>

		<div class="columns is-multiline is-mobile is-centered">
			<b-table
				v-if="getRecycledProducts.length > 0"
				:data="getRecycledProducts.length == 0 ? [] : getRecycledProducts"
				:paginated="true"
				@page-change="scrollToTop()"
				:per-page="perPage"
				:current-page.sync="currentPage"
				:mobile-cards="true"
			>
				<b-table-column field="img" label width="150" centered v-slot="props">
					<ImgZoom :imgsrc="props.row.product.imgs[0]" />
				</b-table-column>

				<b-table-column field="id" label="ID" width="100" centered v-slot="props">{{props.row.id}}</b-table-column>

				<b-table-column
					field="description"
					label="Descrição"
					centered
					v-slot="props"
				>{{ props.row.product.description }}</b-table-column>

				<b-table-column
					v-if="getAppSelectedType == 'partner'"
					label="Instituição"
					centered
					v-slot="props"
				>{{ props.row.institution ? props.row.institution.name : "Aplicação" }}</b-table-column>

				<b-table-column
					v-if="getAppSelectedType == 'partner'"
					label="Local"
					centered
					v-slot="props"
				>{{ props.row.place ? props.row.place.name : "Local não encontrado!" }}</b-table-column>

				<b-table-column
					field="genre"
					label="Género"
					centered
					v-slot="props"
				>{{ props.row.product.genre }}</b-table-column>

				<b-table-column
					field="user"
					label="Utilizador"
					centered
					v-slot="props"
				>{{ props.row.user && props.row.user.info ? props.row.user.info.name : '' }}</b-table-column>

				<b-table-column
					field="size"
					label="Tamanho"
					centered
					v-slot="props"
				>{{ props.row.product.size }}</b-table-column>

				<b-table-column field="color" label="Cor" centered v-slot="props">{{props.row.product.color }}</b-table-column>

				<b-table-column
					field="points"
					width="60"
					label="Pontos"
					centered
					v-slot="props"
				>{{ props.row.points }}</b-table-column>

				<b-table-column label="Unidades" centered v-slot="props">{{props.row.quantity}}</b-table-column>
			</b-table>
			<div v-else>
				<section class="section">
					<div class="content has-text-grey has-text-centered">
						<p>Não existem artigos reciclados!</p>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getAllProducts,
		getByMunicipality
	} from "./../../api/products/products";

	import ImgZoom from "../Shared/ImgZoom";

	import { product } from "../../mixins/modules/product";

	const queryString = require("query-string");

	export default {
		name: "Recycled",
		components: {
			ImgZoom,
		},
		mixins: [product],
		created() {
			this.recycledProducts();
			this.currentPage = this.$route.query.page
				? parseInt(this.$route.query.page)
				: 1;
		},
		data() {
			return {
				currentPage: 1,
				perPage: 10,
				isLoading: false
			};
		},
		methods: {
			recycledProducts() {
				this.$route.name == "RecycledProducts"
					? (this.isLoading = true)
					: "";
				let query = {
					status: "recycled",
				};

				let func = getAllProducts;

				if (this.getAppSelected._id != "app") {
					if (this.getAppSelectedType == "institution")
						query.institution = this.getAppSelected._id;
					if (this.getAppSelectedType == "place")
						query.place = this.getAppSelected._id;
					if (this.getAppSelectedType == "partner") {
						query.partner = this.getAppSelected._id;
						func = getByMunicipality;
					}
				}

				func("?" + queryString.stringify(query))
					.then((result) => {
						this.setRecycledProducts(result.data.body);
						this.isLoading = false;
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
					});
			},
		},
	};
</script>

<style>
</style>