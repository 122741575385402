<template>
	<div>
		<v-swatches
			id="io_color_selector"
			v-model="color"
			:swatches="swatches"
			show-labels
			popover-x="left"
		></v-swatches>
	</div>
</template>

<script>
	import Colors from "../../config/colors";
	import VSwatches from "vue-swatches";

	export default {
		components: { VSwatches },
		props: {
			value: {
				type: String,
				default: "",
			},
		},
		data() {
			return {
				color: "",
				swatches: [],
				img_jeans: require("../../assets/colors/jeans.png"),
				img_multicolor: require("../../assets/colors/multicor.png"),
			};
		},
		created() {
			this.swatches = Colors;
			this.color = this.getColor(this.value);
		},
		watch: {
			color(_new) {
				let wrapper = document.querySelector(
					"#io_color_selector > div.vue-swatches__trigger__wrapper > div"
				);
				if (_new == "Ganga") {
					wrapper.style.backgroundImage = `url(${this.img_jeans})`;
					wrapper.style.backgroundColor = null;
				} else if (_new == "Multicor") {
					wrapper.style.backgroundImage = `url(${this.img_multicolor})`;
					wrapper.style.backgroundColor = null;
				} else {
					wrapper.style.backgroundImage = null;
					wrapper.style.backgroundColor = _new;
				}
				this.emitInput();
			},
		},
		methods: {
			emitInput() {
				this.$emit("input", this.getLabel(this.color));
			},
			getLabel(color) {
				try {
					return this.swatches.find((swatch) => swatch.color == color)
						.label;
				} catch (error) {
					return null;
				}
			},
			getColor(label) {
				try {
					return this.swatches.find((swatch) => swatch.label == label)
						.color;
				} catch (error) {
					return '';
				}
			},
		},
		mounted() {
			let swatches = document.getElementsByClassName("vue-swatches__swatch");
			for (let swatch of swatches) {
				if (swatch.ariaLabel == "Ganga") {
					swatch.style.backgroundImage = `url(${this.img_jeans})`;
					swatch.style.backgroundColor = null;
				}

				if (swatch.ariaLabel == "Multicor") {
					swatch.style.backgroundImage = `url(${this.img_multicolor})`;
					swatch.style.backgroundColor = null;
				}
			}
		},
	};
</script>

<style lang="scss">
	#io_color_selector > div.vue-swatches__trigger__wrapper > div {
		background-size: contain;
	}

	#io_color_selector .vue-swatches__swatch {
		&[aria-label="Ganga"],
		&[aria-label="Multicor"] {
			background-size: contain;
		}
	}

	#io_color_selector > div.vue-swatches__trigger__wrapper > div {
		border: 1px solid gray;
	}

	.vue-swatches__container:not(.vue-swatches--inline) {
		// position: unset !important;
		top: -225% !important;
		left: -225% !important;
		width: fit-content !important;
		height: fit-content !important;
		overflow: unset !important;
		margin-top: 10px;
	}
</style>