<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <h1 class="title is-4">Alterar a minha password</h1>
      <div class="columns">
        <div class="column is-4-desktop is-7-tablet is-12-mobile">
          <b-field label="Password atual">
            <ValidationProvider name="Password atual" rules="required" v-slot="{ errors }">
              <b-input class="is-primary-color" v-model="password" password-reveal type="password" name="old_password" />
              <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
            </ValidationProvider>
          </b-field>
          <b-field label="Nova Password">
            <ValidationProvider name="Insira a nova password" rules="required|password:@confirm_password"
              v-slot="{ errors }">
              <b-input v-model="new_password" password-reveal type="password" name="new_password" />
              <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
            </ValidationProvider>
          </b-field>
          <b-field label="Repita a Password">
            <ValidationProvider name="Repita a nova password" vid="confirm_password" rules="required"
              v-slot="{ errors }">
              <b-input v-model="new_password_repeated" password-reveal type="password" name="new_password_repeated" />
              <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
            </ValidationProvider>
          </b-field>
        </div>

      </div>
      <b-button :loading="isLoading" @click="handleSubmit(add)" class="is-primary-color">Atualizar</b-button>
    </ValidationObserver>
  </div>
</template>

<script>
  import {
    change_password
  } from "./../../api/user/user";

  export default {
    name: "Password",
    components: {},
    props: {},
    created() {},
    mounted() {},

    data() {
      return {
        isLoading: false,
        password: "",
        new_password: "",
        new_password_repeated: "",
        invalidPasswords: false
      };
    },
    watch: {},
    methods: {
      add() {
        this.isLoading = true;
        change_password({
            password: this.password,
            new_password: this.new_password
          })
          .then(response => {
            this.isLoading = false;
            if (response.status == 200) {
              this.$buefy.toast.open({
                message: "Password alterada com sucesso!",
                type: "is-success",
                position: "is-bottom"
              });

              this.password = "";
              this.new_password = "";
              this.new_password_repeated = "";
              this.$refs.observer.reset();


            } else {
              this.$buefy.toast.open({
                message: "Erro na alteração da password!",
                type: "is-danger",
                position: "is-bottom"
              });
            }
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
            this.$buefy.toast.open({
              message: "Password atual inválida!",
              type: "is-danger",
              position: "is-bottom"
            });
          })

      }
    }
  };
</script>