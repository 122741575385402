export const notifications = {
    computed: {
        getNotifications() {
            return this.$store.getters['notifications/getNotifications']
        },
        // isNotificationsOpen() {
        //     return this.$store.getters['notifications/getisNotificationsOpen']
        // },
        unreadNotificationsNumber() {
            let notifications =  this.$store.getters['notifications/getNotifications'];

            return notifications.filter(n => n.read == false).length;

        }
    },
    methods: {
        saveNotifications(notifications) {
            this.$store.commit('notifications/saveNotifications', notifications)
        },
        pushNotification(notification) {

            let notifications = this.$store.getters['notifications/getNotifications'];

            notifications.unshift(notification);
            this.$store.commit('notifications/saveNotifications', notifications)

        },
        readNotification(_id) {

            let notifications = this.$store.getters['notifications/getNotifications'];

            for (let notification of notifications) {

                if (notification._id == _id) {
                    notification.read = true;
                }

            }
            this.$store.commit('notifications/saveNotifications', notifications)


        }
    }
}