var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-4-desktop is-offset-7-desktop is-10-touch is-offset-1-touch"},[_c('div',{staticClass:"card"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"card-content"},[_c('h5',{staticClass:"title is-5 has-text-primary-color"},[_vm._v("Registar")]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Nome")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Email")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Password")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|password:@confirm_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"password","password-reveal":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Confirmação da Password")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Confirmação da Password","vid":"confirm_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"password","password-reveal":""},model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Data de Nascimento")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Data de Nascimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-datepicker',{attrs:{"show-week-number":false,"max-date":_vm.maxDate,"placeholder":"Clica para selecionar","required":""},model:{value:(_vm.form.birthday),callback:function ($$v) {_vm.$set(_vm.form, "birthday", $$v)},expression:"form.birthday"}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)]),(_vm.getAge < 18 && _vm.form.birthday)?_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Email do tutor legal")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Email do tutor legal","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.guardian_email),expression:"form.guardian_email"}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(_vm.form.guardian_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "guardian_email", $event.target.value)}}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)]):_vm._e(),_c('br'),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Termos, Condições e Políticas")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Termos, Condições e Políticas","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-checkbox',{attrs:{"value":true},model:{value:(_vm.form.acceptsTerms),callback:function ($$v) {_vm.$set(_vm.form, "acceptsTerms", $$v)},expression:"form.acceptsTerms"}}),_vm._v("Ao clicar em \"Registar\", concorda com nossos "),_c('b',[_vm._v("Termos")]),_vm._v(". Saiba como guardamos e usamos os seus dados na nossa "),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.openPolicy('PrivacyPolicy')}}},[_vm._v("Política de Privacidade")]),_vm._v(", como usamos cookies e tecnologias semelhantes na nossa "),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.openPolicy('CookiesPolicy')}}},[_vm._v("Política de Cookies")]),_vm._v(" e os seus "),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.openPolicy('MyRights')}}},[_vm._v("Direitos")]),_c('br'),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)]),_c('b-button',{staticClass:"is-primary-color is-medium is-fullwidth",attrs:{"loading":_vm.isLoading},on:{"click":function($event){return handleSubmit(_vm.register)}}},[_vm._v("Registar")]),_c('hr'),_c('h6',{staticClass:"subtitle is-6"},[_vm._v("Já é membro?")]),_c('b-button',{staticClass:"is-outlined is-primary-color is-medium is-fullwidth",on:{"click":function($event){return _vm.login()}}},[_vm._v("Login")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }