<template>
	<div class="navbar-modal">
		<br />
		<ul style="display: flex; justify-content: center;">
			<figure class="image is-64x64">
				<img class="is-rounded" src="../../assets/img/default-img.png" />
			</figure>
		</ul>
		<br />
		<p>{{user.info.name}}</p>
		<p>{{user.auth.email}}</p>
		<div v-if="user.type == 'user'">
			<br />
			<p>
				Encontra-se com
				<span>{{user.points}} Pontos</span> na sua conta
			</p>
		</div>
		<br />
		<b-button @click="goToProfile" class="is-secondary-color is-outlined">Aceder ao perfil</b-button>
		<b-button @click="logOut" class="is-danger is-outlined">Terminar sessão</b-button>
	</div>
</template>

<script>
	export default {
		name: "ProfileModal",
		props: {},
		created() {
			this.user = this.getUserInfo;
		},
		data() {
			return {
				user: null,
			};
		},
		methods: {
			goToProfile() {
				this.$emit("gotoprofile");
			},
			logOut() {
				this.$emit("logout");
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../../assets/sass/main";

	span {
		color: $primary-color;
	}
</style>