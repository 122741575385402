var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('h1',{staticClass:"title is-4"},[_vm._v("Alterar a minha password")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4-desktop is-7-tablet is-12-mobile"},[_c('b-field',{attrs:{"label":"Password atual"}},[_c('ValidationProvider',{attrs:{"name":"Password atual","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{staticClass:"is-primary-color",attrs:{"password-reveal":"","type":"password","name":"old_password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Nova Password"}},[_c('ValidationProvider',{attrs:{"name":"Insira a nova password","rules":"required|password:@confirm_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"password-reveal":"","type":"password","name":"new_password"},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Repita a Password"}},[_c('ValidationProvider',{attrs:{"name":"Repita a nova password","vid":"confirm_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"password-reveal":"","type":"password","name":"new_password_repeated"},model:{value:(_vm.new_password_repeated),callback:function ($$v) {_vm.new_password_repeated=$$v},expression:"new_password_repeated"}}),(errors.length>0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1)],1)]),_c('b-button',{staticClass:"is-primary-color",attrs:{"loading":_vm.isLoading},on:{"click":function($event){return handleSubmit(_vm.add)}}},[_vm._v("Atualizar")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }