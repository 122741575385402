<template>
  <div class="column is-3-desktop is-offset-8-desktop is-10-touch is-offset-1-touch">
    <div class="card">
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="card-content login">
          <h5 class="title is-5 has-text-primary-color">Recuperar password</h5>
          <div class="field">
            <label class="label">Insira a nova password</label>
            <div class="control">
              <ValidationProvider name="Insira a nova password" rules="required|password:@confirm_password" v-slot="{ errors }">
                <!-- <input v-model="form.password" class="input" type="password" /> -->
                <b-input type="password" password-reveal v-model="form.password" />
                <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="field">
            <label class="label">Repita a nova password</label>
            <div class="control">
              <ValidationProvider name="Repita a nova password" vid="confirm_password" rules="required"
                v-slot="{ errors }">
                <b-input type="password" password-reveal v-model="form.confirm_password"
                >
              </b-input>
                <!-- <input v-model="form.confirm_password" class="input" type="password" /> -->
                <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
              </ValidationProvider>
            </div>
          </div>
          <b-button :loading="isLoading" @click="handleSubmit(recover)" class="is-secondary-color is-medium is-fullwidth">Recuperar
          </b-button>
          <hr>
          <h6 class="subtitle is-6">Tem a sua password?</h6>
          <b-button @click="login()" class="is-outlined is-primary-color is-medium is-fullwidth">Login</b-button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import {
    recover_password_key
  } from "@/api/user/user";

  export default {
    data() {
      return {
        isLoading: false,
        form: {
          confirm_password: "",
          password: ""
        },
        access_code: ""
      };
    },
    components: {},
    mixins: [],
    methods: {
      recover() {
        this.isLoading = true;
        recover_password_key(this.access_code, this.form.password)
          .then(response => {
            if (response.status == 200) {
              this.isLoading = false;
              this.$buefy.toast.open({
                message: "Password alterada com sucesso!",
                type: "is-success",
                position: "is-bottom"
              });
              this.$router.push("/login");
            } else {
              this.isLoading = false;
              this.$buefy.toast.open({
                message: "Erro a recuperar a password!",
                type: "is-danger",
                position: "is-bottom"
              });
              this.$router.push("/login");
            }
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
            this.$buefy.toast.open({
              message: "Erro a recuperar a password!",
              type: "is-danger",
              position: "is-bottom"
            });
          });

      },
      login() {
        this.$router.push("/login");
      }

    },
    created: function () {
      this.access_code = this.$route.query.code
      if (typeof this.access_code === 'undefined') {
        this.$router.push("/login");
      }
    }
  };
</script>