<template>
	<div>
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>
		<h1 class="title is-4">Histórico de compras</h1>
		<b-select @input="changeApp" v-model="appSelected">
			<option :value="null" default>Todos</option>
			<option :value="{_id: 'app'}">Aplicação</option>
			<optgroup v-if="getUserInfo.institutions.length > 0" label="Instituições">
				<option :key="i" v-for="(option, i) in getUserInfo.institutions" :value="option">{{option.name}}</option>
			</optgroup>

			<optgroup v-if="getUserInfo.places.length > 0" label="Locais">
				<option
					:key="i"
					v-for="(option, i) in getUserInfo.places"
					app_type="place"
					:value="option"
				>{{option.name}}</option>
			</optgroup>
		</b-select>
		<br>
		<b-table
			:data="isEmpty ? [] : shoppingHistory"
			:mobile-cards="true"
			:paginated="true"
			:per-page="10"
			:default-sort-direction="defaultSortDirection"
			:sort-icon="sortIcon"
			:sort-icon-size="sortIconSize"
			default-sort="id"
			:show-detail-icon="true"
			detail-key="_id"
			detailed
		>
			<b-table-column field="id" label="ID Encomenda" sortable centered v-slot="props">
				<a @click="$router.push('/order/' + props.row._id)">{{ props.row.id }}</a>
			</b-table-column>

			<b-table-column
				field="order_date"
				label="Data"
				width="220"
				sortable
				centered
				v-slot="props"
			>{{ moment(props.row.order_date).format('L')}} {{moment(props.row.order_date).format('LT')}}</b-table-column>

			<b-table-column
				field="status"
				label="Estado"
				sortable
				centered
				v-slot="props"
			>{{ getStatus(props.row.status) }}</b-table-column>

			<b-table-column
				field="payment_type"
				label="Tipo Pagamento"
				sortable
				centered
				v-slot="props"
			>{{ getPaymentType(props.row.payment_type) }}</b-table-column>

			<b-table-column field="place" label="Local" centered v-slot="props">{{props.row.place.name}}</b-table-column>

			<template slot="detail" slot-scope="props">
				<article v-for="(item) in props.row.products" v-bind:key="item._id" class="media">
					<figure class="media-left">
						<p class="image is-64x64">
							<img v-if="item.product" :src="checkImg(item.product.product.imgs[0])" />
							<img v-else :src="checkImg(null)" />
						</p>
					</figure>
					<div class="media-content">
						<div class="content">
							<div v-if="item.product">
								<p>
									<strong>Género:</strong>
									{{ item.product.product.genre}}
									{{item.product.product.genreChild ? '- ' + item.product.product.genreChild : ''}}
								</p>
								<p>
									<strong>Tipo</strong>
									{{ item.product.product.type}}
								</p>
								<p>
									<strong>Tamanho:</strong>
									{{ item.product.product.size}}
								</p>
								<p>
									<strong>Cor:</strong>
									{{ item.product.product.color}}
								</p>
								<p>
									<strong>Descrição:</strong>
									{{ item.product.product.description}}
								</p>
							</div>
							<p v-else>
								<strong>Não foi possível obter os dados do artigo !</strong>
								<br />
							</p>
						</div>
					</div>
				</article>
			</template>
			<template slot="empty">
				<section class="section">
					<div class="content has-text-grey has-text-centered">
						<p>Ainda não adquiriu nenhum artigo</p>
					</div>
				</section>
			</template>
		</b-table>
	</div>
</template>

<script>
	//API
	import { getOrders } from "./../../api/order/order";
	import { filters } from "../../mixins/modules/filters.js";

	export default {
		name: "shoppingHistory",
		mixins: [filters],
		components: {},
		data() {
			return {
				shoppingHistory: [],
				isLoading: false,
				isEmpty: false,
				sortIcon: "arrow-up",
				sortIconSize: "is-small",
				defaultSortDirection: "desc",
				currentPage: 1,
				appSelected: null,
				appSelectedType: null,
			};
		},
		watch: {},
		computed: {},
		methods: {
			getStatus(status) {
				let name = this.getFilterByName("ordersStatus").filter(
					(e) => e.value.toLowerCase() == status.toLowerCase()
				);
				if (name.length <= 0) return status;
				return name[0].text;
			},
			getPaymentType(status) {
				let name = this.getFilterByName("paymentTypes").filter(
					(e) => e.value.toLowerCase() == status.toLowerCase()
				);
				if (name.length <= 0) return status;
				return name[0].text;
			},
			changeApp() {
				if (this.appSelected) {
					for (let i of this.getUserInfo.institutions)
						if (this.appSelected._id == i._id) {
							this.appSelectedType = "institution";
						}

					for (let i of this.getUserInfo.places)
						if (this.appSelected._id == i._id) {
							this.appSelectedType = "place";
						}
				} else this.appSelectedType = null;

				this.getUserShoppings();
			},
			getUserShoppings() {
				this.isLoading = true;
				let query = "?";

				if (this.appSelected) {
					if (this.appSelected._id == "app") {
						query = query + "institution=null";
					} else {
						if (this.appSelectedType == "institution")
							query = query + "institution=" + this.appSelected._id;
						else if (this.appSelectedType == "place")
							query = query + "place=" + this.appSelected._id;
					}
				}

				if (this.getUserInfo.type == "user")
					query = query + "&user=" + this.getUserInfo._id;

				getOrders(query)
					.then((response) => {
						this.shoppingHistory = response.data.body;
						this.isLoading = false;
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;
					});
			},
		},
		created: function () {
			// this.appSelected = this.getAppSelected;
			// this.appSelectedType = this.getAppSelectedType;
			this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
			this.getUserShoppings();
		},
	};
</script>