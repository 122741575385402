<template>
	<div>
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>

		<h5 class="title is-5 has-text-centered">Peças a aguardar aprovação</h5>

		<div class="columns is-multiline is-mobile is-centered">
			<b-table
				:data="products"
				:paginated="true"
				:per-page="10"
				:current-page.sync="currentPage"
				:mobile-cards="true"
			>
				<b-table-column field="img" label width="150" centered v-slot="props">
					<ImgZoom :imgsrc="props.row.product.imgs[0]" />
				</b-table-column>

				<b-table-column field="id" label="ID" width="100" centered v-slot="props">{{props.row.id}}</b-table-column>

				<b-table-column
					field="description"
					label="Descrição"
					centered
					v-slot="props"
				>{{ props.row.product.description }}</b-table-column>

				<b-table-column
					field="genre"
					label="Género"
					centered
					v-slot="props"
				>{{ props.row.product.genre }}</b-table-column>

				<b-table-column
					field="size"
					label="Tamanho"
					centered
					v-slot="props"
				>{{ props.row.product.size }}</b-table-column>

				<b-table-column field="color" label="Cor" centered v-slot="props">{{props.row.product.color }}</b-table-column>

				<!-- <b-table-column field="points" width="60" label="Pontos" centered>
                        {{ props.row.points }}
				</b-table-column>-->

				<b-table-column label="Unidades" centered v-slot="props">{{props.row.quantity}}</b-table-column>

				<b-table-column label="Editar" centered v-slot="props">
					<b-button type="is-secondary-color" @click="modal(JSON.parse(JSON.stringify(props.row)))">
						<b-icon icon="border-color" size="is-small"></b-icon>
					</b-button>
				</b-table-column>

				<template slot="empty">
					<section class="section">
						<div class="content has-text-grey has-text-centered">
							<p>Não existem artigos pendentes!</p>
						</div>
					</section>
				</template>
			</b-table>
		</div>
		<EditProduct
			v-on:productupdate="productUpdate"
			:product_edit="product_edit"
			v-if="isEditProductOpen"
			:class="{ 'is-active': isEditProductOpen }"
		/>
	</div>
</template>




<script>
	//API
	import {
		getAllProducts,
		postApproveProducts,
	} from "./../../api/products/products";
	import EditProduct from "../product/EditProduct";

	import ImgZoom from "../Shared/ImgZoom";

	export default {
		name: "PendingUser",
		components: {
			EditProduct,
			ImgZoom,
		},
		props: {
			user: Object,
			currentTab: String,
		},
		created() {
			this.pendingProducts();
		},
		data() {
			return {
				checkedRows: [],
				isEmpty: false,
				currentPage: 1,
				products: [],
				institutionID: null,
				isLoading: false,
				product_edit: null,
				defaultImg: require("@/assets/img/default-img.png"),
				zoomed: {
					active: false,
					_id: 0,
				},
			};
		},
		methods: {
			pendingProducts() {
				this.currentTab == "Pending" ? (this.isLoading = true) : "";
				let query =
					"?status=pending&user=" +
					this.getUserInfo._id +
					"&credited=false&";

				if (this.getAppSelected._id != "app") {
					if (this.getAppSelectedType == "institution")
						query += "institution=" + this.getAppSelected._id;
					if (this.getAppSelectedType == "place")
						query += "place=" + this.getAppSelected._id;
				}

				getAllProducts(query)
					.then((result) => {
						this.products = result.data.body;
						this.isLoading = false;
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
					});
			},
			modal(_product) {
				
				this.product_edit = _product;
				this.setEditProductOpen(true);
			},
			approveProducts() {
				this.isLoading = true;
				let data = [];
				this.checkedRows.forEach((element) => {
					data.push(element._id);
				});
				let self = this;

				postApproveProducts({
					products: data,
				})
					.then((result) => {
						this.pendingProducts();
						this.isLoading = false;
						this.removeFromPending();
						this.$emit("return");
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
					});
			},
			removeFromPending() {
				this.checkedRows.forEach((element) => {
					this.removePendingProduct(element._id);
				});
				this.checkedRows = [];
				this.$emit("return");
			},
			productUpdate(_product) {
				this.updatePendingProduct(_product);
				this.$emit("return");
			},
			setZoom(_id) {
				if (_id != this.zoomed._id) {
					this.zoomed = {
						_id,
						active: true,
					};
				} else {
					let z = this.zoomed.active
						? (this.zoomed.active = false)
						: (this.zoomed.active = true);

					this.zoomed = {
						_id,
						active: z,
					};
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../../assets/sass/main";

	img.pending {
		max-height: 100px;
		max-width: 100px;
		height: auto;
		width: auto;
		margin: auto;
	}

	.m-image {
		max-height: 80vh;
	}

	.image > img {
		max-width: 100%;
		max-height: 80vh;
		height: auto;
		width: auto;
		margin: auto;
	}

	.table th {
		color: $primary-color !important;
	}
</style>