<template>
  <nav class="comercial navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="navbar-brand comercial">
      <a class="navbar-item" href="/">
        <img class="img appnavbar" src="./../../assets/logo/logo.svg" />
      </a>

      <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false"
        data-target="navbarBasicExample" :class="{'is-active': isBurgerOpen}" @click="toggleBurguerOpen()">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-end comercial">
      <div id="navbarBasicExample" :class="{'is-active': isBurgerOpen}" class="navbar-menu">
        <div class="navbar-start">
          <a @click="changeButtonVisibility('homeD')" class="navbar-item is-hidden-mobile">Home</a>
          <a @click="changeButtonVisibility('homeM')" class="navbar-item is-hidden-tablet">Home</a>
          <a @click="changeButtonVisibility('about')" class="navbar-item">Sobre</a>
          <a @click="changeButtonVisibility('services')" class="navbar-item">Serviços</a>
          <a @click="changeButtonVisibility('partners')" class="navbar-item">Parceiros</a>
          <!-- <a @click="goToRegister()" class="navbar-item">Registar</a> -->
        </div>

        <div class="navbar-item">
          <div class="buttons">
            <a @click="goToApp()" class="button is-primary-color">
              <strong>Loja</strong>
            </a>
            <!-- <a @click="goToLogin()" class="button is-primary-color">
              <strong>Login</strong>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

  export default {
    name: "Navbar",
    props: {},
    data() {
      return {
        open: true
      };
    },
    methods: {
      goToLogin() {
        if (this.isBurgerOpen) this.toggleBurguerOpen();

        this.$router.push("/login");
      },
      goToRegister() {
        this.$router.push("/register");
      },
      goToApp() {
        this.$router.push({name: 'SharedProducts'});
      },
      changeButtonVisibility: function (tag) {
        this.toggleBurguerOpen();
        document.getElementById(tag).scrollIntoView(true);
      }
    },
    computed: {}
  };
</script>

<style scoped>
  .profile-picture {
    padding-right: 0px !important;
  }
</style>