import {
    post, get
} from "./../../utils/http"


export function registerInstall() {
    let uri = '/info'
    return post(uri)
}

export function getInfo() {
    let uri = '/info'
    return get(uri)
}