<template>
	<div class="modal">
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>
		<div class="modal-background"></div>
		<div class="modal-card" :class="{ 'big-modal' : step == 'fields' && !showFields}">
			<header class="modal-card-head">
				<p class="modal-card-title">Importar CSV</p>
				<button @click="modal()" class="delete" aria-label="close"></button>
			</header>
			<section class="modal-card-body" style="background: white;">
				<div class="card-content">
					<b-upload
						v-if="step == 'upload'"
						v-model="dropFiles"
						@input="readFile"
						type="is-danger"
						single
						drag-drop
						accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
					>
						<section class="section">
							<div class="content has-text-centered">
								<p>
									<b-icon icon="upload" size="is-large"></b-icon>
								</p>
								<p>Carregar ficheiro</p>
							</div>
						</section>
					</b-upload>
					<div v-else-if="step == 'fields'">
						<b-tag type="is-primary-color" closable @close="removeFile">{{dropFiles.name}}</b-tag>
						<br />
						<br />
						<div v-if="showFields">
							<table class="table">
								<thead class="head is-uppercase">
									<tr>
										<th>Campo</th>
										<th>Coluna</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(field, key) in fields" :key="key">
										<td>{{ field.label }}</td>
										<td>
											<select class="form-control" type="is-primary-color" v-model="map[field.key]">
												<option v-for="(column, key) in firstRow" :key="key" :value="key">{{ column }}</option>
											</select>
										</td>
									</tr>
								</tbody>
							</table>
							<b-button class="is-primary-color" @click="nextStep">Próximo</b-button>
						</div>
						<div v-else>
							<b-table :data="importedProducts" ref="table" paginated per-page="10" detailed>
								<b-table-column field="img" label width="150" centered v-slot="props">
									<img
										v-if="props.row && props.row.product && props.row.product.imgs && props.row.product.imgs[0]"
										:src="checkImg(props.row.product.imgs[0])"
									/>
									<img v-else :src="checkImg(null)" />
								</b-table-column>

								<!-- <b-table-column field="id" label="ID" width="100" centered>
                                        {{props.row._id}}
								</b-table-column>-->

								<b-table-column
									field="description"
									label="Descrição"
									centered
									v-slot="props"
								>{{ props.row.product.description }}</b-table-column>

								<!-- <b-table-column field="genre" label="Género" centered>
                                        {{ props.row.product.genre }}
                                    </b-table-column>

                                    <b-table-column field="size" label="Tamanho" centered>
                                        {{ props.row.product.size }}
                                    </b-table-column>

                                    <b-table-column field="color" label="Cor" centered>
                                        {{props.row.product.color }}
								</b-table-column>-->

								<b-table-column label="Unidades" width="60" centered v-slot="props">{{props.row.quantity}}</b-table-column>

								<b-table-column
									v-if="getUserInfo.type != 'user' && !(getUserInfo.type == 'glad' && getAppSelectedType == 'app')"
									width="60"
									field="points"
									label="Pontos"
									centered
									v-slot="props"
								>{{ props.row.points }}</b-table-column>

								<b-table-column
									v-if="getUserInfo.type != 'user' && !(getUserInfo.type == 'glad' && getAppSelectedType == 'app')"
									width="60"
									field="price"
									label="Preço"
									centered
									v-slot="props"
								>{{ props.row.price }}</b-table-column>

								<b-table-column
									v-if="getUserInfo.type != 'user' && !(getUserInfo.type == 'glad' && getAppSelectedType == 'app')"
									width="60"
									field="product.weight"
									label="Peso"
									centered
									v-slot="props"
								>{{ props.row.product.weight }}</b-table-column>

								<b-table-column field="actions" label="Ações" centered v-slot="props">
									<b-button
										style="margin-right: 5px;"
										type="is-secondary-color is-small"
										@click="openEditProduct(JSON.parse(JSON.stringify(props.row)))"
									>
										<b-icon icon="border-color" size="is-small"></b-icon>
									</b-button>
									<b-button
										type="is-danger is-small"
										@click="spliceProduct(JSON.parse(JSON.stringify(props.row)))"
									>
										<b-icon icon="delete" size="is-small"></b-icon>
									</b-button>
								</b-table-column>

								<template slot="detail" slot-scope="props">
									<article class="media">
										<div class="media-content">
											<div class="content">
												<div v-if="props.row">
													<p>
														<strong>Género:</strong>
														{{ props.row.product.genre}}
														{{ props.row.product.genreChild ? '- ' + props.row.product.genreChild : ''}}
													</p>
													<p>
														<strong>Tipo</strong>
														{{ props.row.product.type}}
													</p>
													<p>
														<strong>Tamanho:</strong>
														{{ props.row.product.size}}
													</p>
													<p>
														<strong>Cor:</strong>
														{{ props.row.product.color}}
													</p>
												</div>
												<p v-else>
													<strong>Não foi possível obter os dados do artigo !</strong>
													<br />
												</p>
											</div>
										</div>
									</article>
								</template>
								<template slot="empty">
									<section class="section">
										<div class="content has-text-grey has-text-centered">
											<p>Artigos não encontrados!</p>
										</div>
									</section>
								</template>
							</b-table>
							<div class="columns is-desktop">
								<div class="column is-half is-offset-one-quarter">
									<b-button @click="showFields = true" class="is-secondary-color is-pulled-left">Anterior</b-button>
									<b-button @click="step = 'place'" class="is-primary-color is-pulled-right">Próximo</b-button>
								</div>
							</div>
						</div>
					</div>
					<div v-else-if="step == 'place'">
						<p>Escolher local de entrega:</p>
						<br />
						<ChoosePlace @placeselected="changePlace" />
						<br />
						<div class="columns is-desktop">
							<div class="column is-half is-offset-one-quarter">
								<b-button @click="step = 'fields'" class="is-secondary-color is-pulled-left">Anterior</b-button>
								<b-button
									:disabled="!placeSelected"
									@click="add"
									class="is-primary-color is-pulled-right"
								>Adicionar artigos</b-button>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		<EditProduct
			@productupdate="productUpdate"
			:fromImport="true"
			:product_edit="product_edit"
			v-if="isEditProductOpen"
			:class="{ 'is-active': isEditProductOpen }"
		/>
	</div>
</template>

<script>
	import Papa from "papaparse";
	import CSVFields from "../../config/CSVFields";
	import { addProduct } from "../../api/products/products";
	import _ from "lodash";

	import { Object as _Object } from "@iotechpis/utils";

	import EditProduct from "../product/EditProduct";
	import { filters } from "../../mixins/modules/filters.js";

	import ChoosePlace from "../product/ChoosePlace";

	export default {
		name: "ImportCSV",
		components: {
			EditProduct,
			ChoosePlace,
		},
		mixins: [filters],
		data() {
			return {
				fields: [],
				map: {},
				csv: null,
				step: "upload",
				editProductOpen: false,
				placeSelected: null,
				dropFiles: null,
				showFields: true,
				isLoading: false,
				product_edit: null,
				importedProducts: [],
			};
		},
		created() {
			for (let i in CSVFields.user) {
				this.fields.push({
					label: CSVFields.user[i],
					key: i,
				});
			}
			if (this.getUserInfo.type != "user")
				for (let i in CSVFields.admin) {
					this.fields.push({
						label: CSVFields.admin[i],
						key: i,
					});
				}
		},
		methods: {
			changePlace(_p) {
				this.placeSelected = _p;
			},
			modal() {
				this.$emit("modal");
			},
			spliceProduct(_p) {
				this.importedProducts.splice(_p._id, 1);
			},
			openEditProduct(_p) {
				this.setEditProductOpen(true);
				this.product_edit = _p;
			},
			productUpdate(_p) {
				this.importedProducts[_p._id] = _p;
			},
			removeFile() {
				this.step = "upload";
				this.dropFiles = null;
				this.showFields = true;
			},
			nextStep() {
				this.importedProducts = [];
				for (let i = 1; i < this.csv.length; i++) {
					let p = {};

					for (let k in this.map) {
						if (this.map[k] != 0) p[k] = this.csv[i][this.map[k] - 1];
					}

					this.importedProducts.push(p);
				}

				for (let i = 0; i < this.importedProducts.length; i++) {
					let count = 0;
					for (let k in this.importedProducts[i]) {
						if (this.importedProducts[i][k]) {
							if (this.importedProducts[i][k].trim() != "") count++;
							else this.importedProducts[i][k] = null;
						}
					}

					if (count == 0) {
						this.importedProducts.splice(i, 1);
						// i--;
					}

					if (
						this.importedProducts[i] &&
						this.importedProducts[i]["product.imgs"]
					) {
						this.importedProducts[i]["product.imgs"] = [
							this.importedProducts[i]["product.imgs"],
						];

						if (
							count == 1 &&
							this.importedProducts[i]["product.imgs"][0]
						) {
							this.importedProducts[i - 1]["product.imgs"].push(
								this.importedProducts[i]["product.imgs"][0]
							);
							this.importedProducts.splice(i, 1);
							i--;
						}
					}
				}

				for (let i = 0; i < this.importedProducts.length; i++) {
					this.importedProducts[i]._id = i;
					_Object.dotToJson(this.importedProducts[i]);
					this.importedProducts[i] = this.parseFilters(
						this.importedProducts[i]
					);
					if (!this.importedProducts[i].product)
						this.importedProducts[i].product = {};
					if (!this.importedProducts[i].product.imgs)
						this.importedProducts[i].product.imgs = [];
				}
				this.showFields = false;
			},
			readFile() {
				this.isLoading = true;
				let file = this.dropFiles;
				const _this = this;
				if (file) {
					let reader = new FileReader();
					reader.readAsText(file, "UTF8");
					reader.onload = function (evt) {
						_this.csv = Papa.parse(evt.target.result).data;
						_this.step = "fields";
						_this.isLoading = false;
					};
					reader.onerror = function (error) {
						_this.isLoading = false;
						_this.$buefy.toast.open({
							message: "Erro a ler o ficheiro!",
							type: "is-danger",
							position: "is-bottom",
						});
					};
				} else _this.isLoading = false;
			},
			parseFilters(_p) {
				let selected = {
					genre: _p.product && _p.product.genre ? _p.product.genre : null,
					genreChild:
						_p.product && _p.product.genreChild
							? _p.product.genreChild
							: null,
					type: _p.product && _p.product.type ? _p.product.type : null,
					size: _p.product && _p.product.size ? _p.product.size : null,
				};

				let filters = {
					genre: [],
					genreChild: [],
					type: [],
					size: [],
				};

				filters.genre = this.getFilterByName("genres");
				filters.genreChild = this.getFilterByName("genreChild");

				if (selected.genre) {
					if (selected.genre.toLowerCase() == "Criança".toLowerCase()) {
						selected.genre = "Criança";

						if (selected.genreChild) {
							let genreChild_obj = filters.genreChild.filter(
								(e) =>
									e.value.toLowerCase() ==
									selected.genreChild.toLowerCase()
							)[0];

							if (genreChild_obj) {
								selected.genreChild = genreChild_obj.value;

								if (selected.type) {
									filters.type = this.getFilterByName(
										genreChild_obj.child
									);

									let type_obj = filters.type.filter(
										(e) =>
											e.value.toLowerCase() ==
											selected.type.toLowerCase()
									)[0];

									if (type_obj) {
										selected.type = type_obj.value;
										if (selected.size) {
											filters.size =
												selected.genreChild == "Bebe"
													? this.getFilterByName(
															"sizesMonth"
													)
													: this.getFilterByName(
															"sizesAge"
													);

											let size_obj = filters.size.filter(
												(e) =>
													e.value.toLowerCase() ==
													selected.size.toLowerCase()
											)[0];

											if (size_obj) {
												selected.size = size_obj.value;
											} else {
												selected.size = null;
											}
										}
									} else {
										selected.type = null;
										selected.size = null;
									}
								}
							} else {
								selected.genreChild = null;
								selected.type = null;
								selected.size = null;
							}
						}
					} else {
						let genre_obj = filters.genre.filter(
							(e) =>
								e.value.toLowerCase() ==
								selected.genre.toLowerCase()
						)[0];

						if (genre_obj) {
							selected.genre = genre_obj.value;
							if (selected.type) {
								filters.type = this.getFilterByName(
									genre_obj.child
								);

								let type_obj = filters.type.filter(
									(e) =>
										e.value.toLowerCase() ==
										selected.type.toLowerCase()
								)[0];

								if (type_obj) {
									selected.type = type_obj.value;
									if (selected.size) {
										filters.size = this.getFilterByName(
											type_obj.child
										);

										let size_obj = filters.size.filter(
											(e) =>
												e.value.toLowerCase() ==
												selected.size.toLowerCase()
										)[0];

										if (size_obj) {
											selected.size = size_obj.value;
										} else {
											selected.size = null;
										}
									}
								} else {
									selected.type = null;
									selected.size = null;
								}
							}
						} else {
							selected.type = null;
							selected.size = null;
							selected.genre = null;
						}
					}
				}

				if (_p.product) {
					_p.product.genre = selected.genre;
					_p.product.genreChild = selected.genreChild;
					_p.product.type = selected.type;
					_p.product.size = selected.size;
				}

				return _p;
			},
			add() {
				this.isLoading = true;

				let data = {
					products: this.importedProducts,
				};

				data.place = this.placeSelected._id;

				if (this.getAppSelected._id != "app") {
					if (this.getAppSelectedType == "institution")
						data.institution = this.getAppSelected._id;
					// if (this.getAppSelectedType == "place")
					//     data.place = this.getAppSelected._id;
				}

				addProduct(data)
					.then((resp) => {
						this.isLoading = false;
						this.$emit("addproduct", resp.data.body);
						this.$buefy.toast.open({
							message: "Artigos adicionados!",
							type: "is-success",
							position: "is-bottom",
						});
						this.modal();
					})
					.catch((error) => {
						console.log(error)
						this.isLoading = false;
						this.$buefy.toast.open({
							message: "Erro a adicionar o artigo!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
		},
		computed: {
			firstRow() {
				let filtered = [];
				for (let i of this.csv[0]) filtered.push(i);
				filtered.unshift("");
				return filtered;
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../../assets/sass/main";

	.big-modal {
		width: 90%;
		max-height: 100vh !important;
		height: 100vh;
	}

	.blank {
		border-color: transparent;
	}

	.modal {
		text-align: center !important;
	}
</style>